import { FabricDataDTO, PalletDataDTO } from "Dashboard/types"
import { apiClient } from "../../Services/Networking/apiClient"

type paramsObject = {
  articleNumber?: string,
  dateOfManufacture?: string,
  rackNumber?: string
}

type faricparamsObject = {
  itemCode?: string
}

export const getPalletInformation = async (params: paramsObject): Promise<PalletDataDTO[]> => {
  const response = apiClient({
    method: 'get',
    url: `/analytics/warehouse/pallets`,
    params
  }) as unknown as PalletDataDTO[]
  return response
}

export const getOverallWarehouseInformation = async (): Promise<PalletDataDTO[]> => {
  const response = apiClient({
    method: 'get',
    url: `analytics/warehouse/pallets`
  }) as unknown as PalletDataDTO[]
  return response
}

export const getOverallWarehouseFabricInformation = async (): Promise<FabricDataDTO[]> => {
  const response = apiClient({
    method: 'get',
    url: `analytics/warehouse/fabric`
  }) as unknown as FabricDataDTO[]
  return response
}

export const getSearchWarehouseFabricInformation = async (params: faricparamsObject): Promise<FabricDataDTO[]> => {
  const response = apiClient({
    method: 'get',
    url: `analytics/warehouse/fabric`,
    params
  }) as unknown as FabricDataDTO[]
  return response
}
