import React, { ChangeEvent, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'

import { AddButton, Grid, Loader, SubmitButtonUpdated, Input } from 'UI/Components'
import { postOrderSizeAndQuantities } from '../api/apiFunctions'

type SizeQuantityList = {
  size: string,
  quantity: number,
  id: string
}

const OrderSizeAndQuantitiesGrid = () => {
  const history = useHistory()

  const queryString = new URLSearchParams(useLocation().search)
  const paramsObject: any = {}
  queryString.forEach((value, key) => {
    paramsObject[key] = value
  })
  const { OC, location, item, itemDescription, fitType, consumption } = paramsObject

  const [sizeQuantityList, setSizeQuantityList] = useState<SizeQuantityList[]>([{
    size: '',
    quantity: 0,
    id: nanoid(4)
  }])
  const [isLoading, setIsLoading] = useState(false)
  const [_, setError] = useState('')

  const handleQuantityChange = (value: string, indexToChange: number) => {
    const duplicatedSizeQuantityList = [...sizeQuantityList]
    const updatedSizeQuantityList = duplicatedSizeQuantityList.map(({ size, quantity, id }, index) => {
      if (index === indexToChange) {
        return { size, quantity: parseInt(value), id }
      }
      return { size, quantity, id }
    })
    setSizeQuantityList(updatedSizeQuantityList)
  }

  const handleSizeChange = (value: string, indexToChange: number) => {
    const duplicatedSizeQuantityList = [...sizeQuantityList]
    const updatedSizeQuantityList = duplicatedSizeQuantityList.map(({ size, quantity, id }, index) => {
      if (index === indexToChange) {
        return { size: value.toUpperCase(), quantity, id }
      }
      return { size, quantity, id }
    })
    setSizeQuantityList(updatedSizeQuantityList)
  }

  const addSizeQuantityRow = () => {
    setSizeQuantityList([...sizeQuantityList, { size: '', quantity: 0, id: nanoid(4) }])
  }

  const removeSizeQuantityRow = (index: number) => {
    if (sizeQuantityList.length === 1) {
      return
    }
    const duplicatedSizeQuantityList = [...sizeQuantityList]
    duplicatedSizeQuantityList.splice(index, 1)
    setSizeQuantityList(duplicatedSizeQuantityList)
  }

  const isSizeQuantityListValid = (sizeQuantityList: SizeQuantityList[]): boolean => {
    return sizeQuantityList.map(sizeQuantityObject => {
      const { size, quantity } = sizeQuantityObject
      if (size === "" || quantity < 0) {
        return false
      }
      if (isSizeRepeated(size, sizeQuantityList)) {
        return false
      }
      return true
    }).reduce((acc, curr) => {
      return acc && curr
    }, true)
  }

  const isSizeRepeated = (compareToSize: string, sizeListArray: SizeQuantityList[]): boolean => {
    let sizeRepeatCount = 0
    sizeListArray.map(sizeQuantityObject => {
      const { size } = sizeQuantityObject
      if (size.toUpperCase() === compareToSize.toUpperCase()) {
        sizeRepeatCount++
      }
      return null
    })
    if (sizeRepeatCount > 1) {
      return true
    }
    return false
  }

  const submitOrderQuantities = async () => {
    setIsLoading(true)
    if (isSizeQuantityListValid(sizeQuantityList) === false) {
      window.alert('Please check the data you have entered')
      return
    }
    const sizeQuantityListWithIdRemoved = sizeQuantityList.map(({ size, quantity }) => {
      return { size, quantity }
    })
    try {
      await postOrderSizeAndQuantities(OC, location, item, itemDescription, fitType, consumption, sizeQuantityListWithIdRemoved)
      setIsLoading(false)
      window.alert('Your details were submitted successfully')
      history.goBack()
    } catch (err: any) {
      setIsLoading(false)
      setError(() => {
        throw Error(err)
      })
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Grid columns={'45% 45%'}>
        <p>Size</p>
        <p>Quantity</p>
      </Grid>
      <Grid columns={"45% 45% 10%"} gap={'5%'} rowGap={'1em'}>
        {
          sizeQuantityList.map(({ size, quantity, id }, index) => {
            return (
              <React.Fragment key={`${id}`}>
                <Input value={size} type='string'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleSizeChange(event.target.value, index)} />
                <Input value={quantity} type='number'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleQuantityChange(event.target.value, index)} />
                <img src={process.env.PUBLIC_URL + "/delete.svg"}
                  alt="delete"
                  tabIndex={0}
                  onClick={() => removeSizeQuantityRow(index)}
                  style={{ alignSelf: 'center', cursor: 'pointer' }} />
              </React.Fragment>
            )
          })
        }
      </Grid>
      <AddButton onClick={addSizeQuantityRow}
        style={{ alignSelf: 'flex-start', marginTop: '2%' }}>
        <p>Add</p>
      </AddButton>
      <SubmitButtonUpdated style={{ width: '75%', marginTop: '5%' }} onClick={submitOrderQuantities}>
        <p>Submit</p>
      </SubmitButtonUpdated>
    </>
  )
}

export default OrderSizeAndQuantitiesGrid