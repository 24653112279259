import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import StyleProvider from 'Providers/StyleProvider'

import './index.css'
import ErrorBoundary from './Components/ErrorBoundary'
import App from './App'
import { Auth0Provider } from './Services/auth0-wrapper'


// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

ReactDOM.render(
  <Router>
    <ErrorBoundary>
      <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        onRedirectCallback={onRedirectCallback}>
        <StyleProvider>
          <App />
        </StyleProvider>
      </Auth0Provider>
    </ErrorBoundary>
  </Router>, document.getElementById('root'))