import React, { useState, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router'
import { SearchBar } from 'UI/Components'
import WarehouseOverallPalletsResults from './Components/WarehouseOverallPalletsResults'
import WarehouseSearchPalletsResults from './Components/WarehouseSearchPalletsResults'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { WarehouseOverallFabricResults } from './Components/WarehouseOverallFabricResults'
import { WarehouseSearchFabricResults } from './Components/WarehouseSearchFabricResults'
import { FilterTypeEnum } from './types'

const WarehouseDashboard = () => {
  const history = useHistory()
  const { path } = useRouteMatch()

  // Defining regexes used for warehouse input
  const articleNumberRegex = /\d{3}.\d{3}.\d{2}$/
  const dateOfManufactureRegex = /\d{4}$/
  const rackNumberRegex = /[a-zA-Z]{1}\d{1}-\d{1,2}$/
  const itemCodeRegex = /IDIFK\d{2}$/

  const location = useLocation()

  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermValid, setSearchTermValid] = useState(false)
  const [searchFieldTouched, setSearchFieldTouched] = useState(false)
  const [searchInputError, setSearchInputError] = useState(false)
  const [searchInputErrorMessage, setSearchInputErrorMessage] = useState('')
  const [fabricFilterColor, setFabricFilterColor] = useState<string>("")
  const [palletsFilterColor, setPalletsFilterColor] = useState<string>('brand.100')
  const [filterType, setFilterType] = useState<FilterTypeEnum>(FilterTypeEnum.PALLETS)

  useEffect(() => {
    history.push({
      pathname: `${path}/pallets`
    })
  }, [])

  //  This effect is to reset the search field touched state variable
  //  every time the URL changes. This is done so that every time the 
  //  search term changes, the second effect runs because that depends
  //  on searchFieldTouched
  useEffect(function resetSearchValidityVariables() {
    setSearchTermValid(false)
    setSearchFieldTouched(false)
  }, [location.search, location.pathname])

  useEffect(function checkIfSearchTermIsValid() {
    let params = new URLSearchParams()
    //  do not run this effect if a search term has never been entered
    if (searchFieldTouched === false && searchTermValid === false) {
      return
    }

    //  do not run this effect if a search term entered is invalid
    if (searchFieldTouched === true && searchTermValid === false) {
      setSearchInputError(true)
      return
    }

    if (filterType === FilterTypeEnum.PALLETS){
      if (articleNumberRegex.test(searchTerm) === true) {
        params.append('articleNumber', searchTerm)
      }
  
      if (dateOfManufactureRegex.test(searchTerm) === true) {
        params.append('dateOfManufacture', searchTerm)
      }
  
      if (rackNumberRegex.test(searchTerm) === true) {
        params.append('rackNumber', searchTerm)
      }
      setSearchInputError(false)
      history.push({
        pathname: `${path}/pallets/results`,
        search: params.toString()
      })
    }
    else if (filterType === FilterTypeEnum.FABRIC){
      if (itemCodeRegex.test(searchTerm) === true) {
        params.append('itemCode', searchTerm)
      }
      setSearchInputError(false)
      history.push({
        pathname: `${path}/fabric/results`,
        search: params.toString()
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFieldTouched, searchTermValid, searchTerm])

  const checkSearchInput = () => {
    setSearchFieldTouched(true)

    // if filter is set to pallets, we will check the validity of pallet's search terms
    // else we will check the validity of fabric's search term i.e. Item Code
    if (filterType === FilterTypeEnum.PALLETS){
      if (articleNumberRegex.test(searchTerm) === true) {
        setSearchTermValid(true)
        return
      }
  
      if (dateOfManufactureRegex.test(searchTerm) === true) {
        setSearchTermValid(true)
        return
      }
  
      if (rackNumberRegex.test(searchTerm) === true) {
        setSearchTermValid(true)
        return
      }
    }
    else if (filterType === FilterTypeEnum.FABRIC){
      if (itemCodeRegex.test(searchTerm) === true) {
        setSearchTermValid(true)
        return
      }
    }

    //  if the search terms are not valid
    setSearchTermValid(false)
    setSearchInputErrorMessage(filterType === FilterTypeEnum.PALLETS ? 'Please enter a valid article number, date of manufacturing or rack number' : "Please enter a valid Item Code")
  }

  // this function is to change the color of the filter buttons as well 
  // as redirect to the target component based on the filter
  const handleFilterChange = (filterType:string) => {
    setSearchInputError(false)
    setSearchTerm('')
    setSearchTermValid(false)
    setSearchFieldTouched(false)
    if (filterType === FilterTypeEnum.PALLETS) {
      setPalletsFilterColor('brand.100')
      setFabricFilterColor('')
      setFilterType(FilterTypeEnum.PALLETS)
      history.push({
        pathname: `${path}/pallets`
      })
    }
    if (filterType === FilterTypeEnum.FABRIC) {
      setFabricFilterColor('brand.100')
      setPalletsFilterColor('')
      setFilterType(FilterTypeEnum.FABRIC)
      history.push({
        pathname: `${path}/fabric`
      })
    }
  }


  return (
    <React.Fragment>
      <SearchBar
        value={searchTerm}
        error={searchInputErrorMessage}
        isInvalid={searchInputError}
        onChange={(searchInput: string) => setSearchTerm(searchInput)}
        handleSubmit={checkSearchInput} />
      <div style={{paddingTop: '20px'}} className="warehouse-filter-buttons">
        <ButtonGroup key='warehouse-fabric-filters-group' isAttached variant="outline">
          <Button  color={palletsFilterColor} onClick={() => handleFilterChange(FilterTypeEnum.PALLETS)}>Pallets</Button>
          <Button  color={fabricFilterColor} onClick={() => handleFilterChange(FilterTypeEnum.FABRIC)}>Fabric</Button>
        </ButtonGroup >
      </div>
      <Switch>
        <Route exact path={`${path}/pallets`}>
          <WarehouseOverallPalletsResults />
        </Route>
        <Route exact path={`${path}/pallets/results`}>
          <WarehouseSearchPalletsResults />
        </Route>
        <Route exact path={`${path}/fabric`}>
          <WarehouseOverallFabricResults />
        </Route>
        <Route exact path={`${path}/fabric/results`}>
          <WarehouseSearchFabricResults />
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default WarehouseDashboard