import styled from 'styled-components'

const TableStyles = styled.div`
  table {
    border-spacing: 0;
    border-top: 0px;
    border-right: 1px solid black;
    border-bottom: 0px;
    border-left: 1px solid black;
    margin-top: 10%;
    border-color: #334bff;

    th {
      padding: 1.5rem;
      color: #334bff;
    }
    
    th,
    td {
      border-right: 1px solid #334bff;

      :last-child {
        border-right: 0;
      }

      text-align: center;
    }

    td {
      padding: 0.5rem;
      font-style: normal;
      font-weight: normal;
    }
  }

  span[aria-expanded='true'] img[src*="/down-chevron.svg"] {
    transform: rotate(-180deg);
  }
`

export default TableStyles