import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { Column } from 'react-table'
import { PalletDataDTO } from '../types'
import { getPalletInformation } from '../api/getWarehouseData'
import { Loader } from 'UI/Components'
import WarehouseDataTable from './WarehouseDataTable'

function WarehouseSearchPalletsResults() {
  const { search } = useLocation()

  const [_, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<PalletDataDTO[]>([])

  type ParamsObject = {
    [key: string]: string
  }

  const params = new URLSearchParams(search)
  //  the below useMemo function has search as a dependency because
  //  it needs to be re-computed every time the URL location changes
  const paramsObject: ParamsObject = useMemo(() => ({}), [search])

  for (const [key, value] of params.entries()) {
    paramsObject[key] = value
  }

  /**
   * Utility function to convert a number to the locale string version
   * @param {Number} number 
   */
  const convertToLocaleString = (number: number) => {
    return number.toLocaleString('en-IN')
  }

  useEffect(function runEffectToGetData() {
    const makeRequest = async () => {
      try {
        setIsLoading(true)
        const response = await getPalletInformation(paramsObject)
        setIsLoading(false)
        setData(response)
      } catch (err) {
        setIsLoading(false)
        setError(() => {
          throw err
        })
      }
    }

    makeRequest()
  }, [paramsObject, search])

  const tableData = useMemo(() => data, [data])

  const columnsForArticleNumber = [
    {
      Header: "",
      id: "row",
      Cell: (props: any) => {
        return <div style={{textAlign: 'right'}} >{props.row.index + 1}.</div>
      },
      disableSortBy: true,
      width: 30
    },
    {
      Header: 'Rack Number',
      accessor: 'rackBarcode'
    },
    {
      Header: 'Article Number',
      accessor: 'articleNumber'
    },
    {
      Header: 'Number Of Pieces',
      accessor: 'numberOfPieces',
      Cell: (props: any) => {  //  providing commas for this number
        return convertToLocaleString(props?.cell?.value)
      }
    },
    {
      Header: 'Date Of Manufacturing',
      accessor: 'dateOfManufacture'
    },
    {
      Header: 'Warehouse Exit Date',
      accessor: 'warehouseExitDate'
    }
  ] as Column<PalletDataDTO>[]

  const columnsForEverythingElse = [
    {
      Header: "",
      id: "row",
      Cell: (props: any) => {
        return <div style={{textAlign: 'right'}} >{props.row.index + 1}.</div>
      },
      disableSortBy: true,
      width: 30
    },
    {
      Header: 'Rack Number',
      accessor: 'rackBarcode'
    },
    {
      Header: 'Article Number',
      accessor: 'articleNumber'
    },
    {
      Header: 'Number Of Pieces',
      accessor: 'numberOfPieces',
      Cell: (props: any) => {  //  providing commas for this number
        return convertToLocaleString(props?.cell?.value)
      }
    },
    {
      Header: 'Date Of Manufacturing',
      accessor: 'dateOfManufacture'
    }
  ] as Column<PalletDataDTO>[]

  let columns = null

  if ('articleNumber' in paramsObject) {
    columns = columnsForArticleNumber
  } else {
    columns = columnsForEverythingElse
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div style={{width: '90%'}}>
      <WarehouseDataTable data={tableData} columns={columns} />
    </div>
  )
}

export default WarehouseSearchPalletsResults