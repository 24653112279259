import { useEffect, useState } from "react"
import { DefectData, DHUDataDTO } from "Dashboard/types/sewing"
import { ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts"

type DHUDateRangeChartProps = {
  data: DHUDataDTO[]
}

type ChartDataType = {
  defect: string,
  quantity: number,
  cumulativePercentage: number
}

/** This is a pareto chart for defects frequency over a date range */
export const DHUDateRangeChart = (props: DHUDateRangeChartProps) => {
  const { data } = props
  const [chartData, setChartData] = useState<ChartDataType[]>([])

  useEffect(() => {
    const defectsAndQuantitiesList = getDefectsAndQuantitiesList(data)
    const uniqueDefectAndQuantitiesList = aggregateUniqueDefectsInList(defectsAndQuantitiesList)
    const sortedDefectsAndQuantitiesList = sortListBasedOnNumberOfDefects(uniqueDefectAndQuantitiesList)
    const defectsListWithCumulativePercentage = calculateCumulativePercentageForEachDefect(sortedDefectsAndQuantitiesList)
    setChartData(defectsListWithCumulativePercentage)
  }, [data])

  const getDefectsAndQuantitiesList = (data: DHUDataDTO[]) => {
    const defectsAndQuantities: DefectData[] = []
    data.forEach(each => {
      const { hourlyDetails } = each
      hourlyDetails.forEach(hourDetail => {
        const { defectDetails } = hourDetail
        defectDetails.forEach(defectDetail => {
          const { defect, quantity } = defectDetail
          defectsAndQuantities.push({ defect, quantity })
        })
      })
    })
    return defectsAndQuantities
  }

  const aggregateUniqueDefectsInList = (data: DefectData[]) => {
    return data.reduce((acc: DefectData[], curr) => {
      const { defect, quantity } = curr
      const defectIndexInAcc = acc.findIndex((item: DefectData) => item.defect === defect)
      //  This defect does not exist in the list
      if (defectIndexInAcc === -1) {
        acc.push({ defect, quantity })
        return acc
      }
      const currentDefectQuantity = acc[defectIndexInAcc].quantity
      acc[defectIndexInAcc] = Object.assign(
        {},
        {
          defect,
          quantity: currentDefectQuantity + quantity
        }
      )
      return acc
    }, [])
  }

  const sortListBasedOnNumberOfDefects = (data: DefectData[]) => {
    return data.sort((a: DefectData, b: DefectData) => {
      return b.quantity - a.quantity
    })
  }

  const calculateCumulativePercentageForEachDefect = (data: DefectData[]) => {
    const totalDefects = data.reduce((acc, curr) => {
      const { quantity } = curr
      return acc + quantity
    }, 0)

    let cumulative = 0
    return data.map(({ defect, quantity }) => {
      cumulative += quantity
      const cumulativePercentage = Math.round(cumulative * 100 / totalDefects)
      return { defect, quantity, cumulativePercentage }
    })
  }

  if (data.length === 0) {
    return (
      <div className="dhu-dashboard-error">
        No results found
      </div>
    )
  }

  return (
    <div>
      <div className="dhu-dashboard-header">Date Range Defects Chart</div>
      <div className="dhu-dashboard-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh', margin: '0 0 0 7%' }}>
        <ResponsiveContainer width='100%' height='100%'>
          <ComposedChart
            width={400}
            height={800}
            data={chartData}
            margin={{
              top: 5,
              right: 40,
              left: 5,
              bottom: 5,
            }}
          >

            <XAxis dataKey="defect" stroke="#334bff" label={{ value: "Defects", dy: 25, fill: "#334bff", fontWeight: "bold" }} />
            <YAxis stroke="#334bff" label={{ value: "Frequency Of Occurrences", dx: -18, angle: -90, fill: "#334bff", fontWeight: "bold" }} />
            <YAxis yAxisId="right" orientation="right"
              tickFormatter={(tick: any) => `${tick}%`}
              stroke="#334bff" />
            <Tooltip contentStyle={{ 'color': "#334bff", borderRadius: '10px' }} />
            <Bar dataKey="quantity" barSize={50} fill="#334bff" />
            <Line yAxisId="right" type="monotone" dataKey="cumulativePercentage" stroke="#334bff" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

    </div>
  )
}