import React from 'react'

import { Input, SubmitButtonUpdated, ErrorLabel, Grid, AddButton } from '../UI/Components'
import './BarcodeTable.css'

const BarcodeTable = (props) => {
  const { tableData, ordQty, newCurrQty, sessionQty, handleTableDataInput, addTableRow, removeTableRow, handleTableSubmit } = props
  const percDiff = ((newCurrQty - ordQty) / ordQty) * 100
  return (
    <React.Fragment>
      <Grid className='barcode-table-po-quantity' columns={'30% 5% 30% 5% 30%'} width={'50%'} style={{ border: '2px solid #344cfe' }}>
        <div>
          <p style={{ fontWeight: 'bold' }}>Order Quantity</p>
          <p style={{ fontWeight: 'bold' }}>{ordQty}</p>
        </div>
        <p>-</p>
        <div>
          <p style={{ fontWeight: 'bold' }}>Current Quantity</p>
          <p style={{ fontWeight: 'bold' }}>{newCurrQty}</p>
        </div>
        <p>=</p>
        <div>
          <p style={{ fontWeight: 'bold' }}>Remaining</p>
          <p style={{ color: percDiff <= 0 ? '#35b724' : percDiff >= 0 && percDiff < 2 ? '#ffa500' : '#fd324b' }}>{ordQty - newCurrQty}</p>
        </div>
      </Grid>
      <Grid className='barcode-table-header' columns={'45% 45%'}>
        <p>Roll Number</p>
        <p>Length(m)</p>
      </Grid>
      <Grid columns={'5% 40% 40% 5%'} gap={'1em'}>
        {
          tableData.map((row, index) => {
            return (
              <React.Fragment key={row.id}>
                <div className='barcode-table-container-element'>
                  <p style={{ fontWeight: 'bold' }}>{index + 1}</p>
                </div>
                <div className='barcode-table-container-element'>
                  <Input className={`roll-num-entry`}
                    isInvalid={row.error && row.error.roll}
                    value={row.roll}
                    onChange={(e) => handleTableDataInput(e.target.value, index, 'roll')} />
                  {
                    (row.error && row.error.roll) ? (<ErrorLabel text={row.error.roll} />) : (null)
                  }
                </div>
                <div className='barcode-table-container-element'>
                  <Input
                    isInvalid={row.error && row.error.length}
                    type='number'
                    value={row.length}
                    onChange={(e) => handleTableDataInput(e.target.value, index, 'length')} />
                  {
                    (row.error && row.error.length) ? (<ErrorLabel text={row.error.length} />) : (null)
                  }
                </div>
                <img src="delete.svg" tabIndex={0} onClick={(e) => removeTableRow(index)} className='delete-icon' alt='delete' />
              </React.Fragment>
            )
          })
        }
      </Grid>
      <Grid className='session-total-container-grid' columns={'40% 40%'} gap={'10%'}>
        <div></div>
        <div className='session-total-container'>
          <p>Total: </p>
          <p>{sessionQty}</p>
        </div>
      </Grid>
      <AddButton onClick={addTableRow}
        style={{ alignSelf: 'flex-start', marginTop: '2em' }}>
        <p>Add Row</p>
      </AddButton>
      <SubmitButtonUpdated onClick={handleTableSubmit}
        style={{ width: '50%', marginTop: '2em' }}>Submit</SubmitButtonUpdated>
    </React.Fragment>
  )
}

export default BarcodeTable