const axios = require('axios')

// @ts-ignore
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  validateStatus: function (status) {
    return status >= 200 && status < 400
  }
})

/**
 * Wrapper function that will call the relevant function based on the HTTP verb
 * @param {String} method 
 * @param {String} url 
 * @param {Object} body 
 * @param {String} token 
 * @returns 
 */
export const networkRequest = async (method, url, body, token) => {
  switch (method) {
    case 'GET':
      return makeGetRequest(url, body, token)
    case 'POST':
      return makePostRequest(url, body, token)
    default:
      throw new Error('A request method must be specified')
  }
}

/**
 * Function to make an HTTP get request
 * @param {String} url 
 * @param {Object} params 
 * @param {String} token 
 * @returns 
 */
const makeGetRequest = async (url, params, token) => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url,
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        Version: '7.0'
      }
    })
    return data
  } catch (err) {
    throw err
  }
}

/**
 * Function to make make an HTTP post request
 * @param {String} url 
 * @param {Object} body 
 * @param {String} token 
 * @returns 
 */
const makePostRequest = async (url, body, token) => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        Version: '7.0'
      }
    })
    return data
  } catch (err) {
    throw err
  }
}