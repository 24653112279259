import { Table, TableCaption, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { DHUDataDTO } from "Dashboard/types/sewing"
import { useEffect, useState } from "react"
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'

export type DHUSingleDateChartProps = {
  data: DHUDataDTO[]
}

export const DHUSingleDateChart = (props: DHUSingleDateChartProps) => {
  const { data } = props
  type BarDataType = {
    first: string | number,
    second: string | number,
    third: string | number,
    batchNumber: string | number
  }

  type GroupedDefectsType = {
    batchNumber: string,
    ocNumber: string,
    totalPassedQuantity: number,
    totalReworkedQuantity: number,
    totalRejectedQuantity: number,
    defectsAndQuantities: { defect: string, quantity: number, DHU?: number }[]
  }

  const [barData, setBarData] = useState<BarDataType[]>([])
  const [referenceData, setReferenceData] = useState<GroupedDefectsType[]>([])

  useEffect(() => {
    const flattenedData = flattenData(data)
    const groupedData = groupData(flattenedData)
    const aggregatedData = aggregateUniqueDefects(groupedData)
    const sortedData = sortDefectsBasedOnNumberOfDefects(aggregatedData)
    const topThreeDefects = getTopNDefectsPerBatch(sortedData, 3)
    const topThreeDefectsWithDHU = calculateDHUPerDefect(topThreeDefects)
    setReferenceData(topThreeDefectsWithDHU)
    const flattenedDefectAndQuantitiesData = flattenDefectAndQuantitiesList(topThreeDefectsWithDHU)
    const dataToDisplay = prepareDataForDisplay(flattenedDefectAndQuantitiesData)
    setBarData(dataToDisplay)
  }, [data])

  const flattenData = (dataToFlatten: DHUDataDTO[]) => {
    return dataToFlatten.map(({ batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, hourlyDetails }) => {
      return hourlyDetails.map(({ defectDetails }) => {
        return defectDetails.map(({ defect, quantity }) => {
          return { batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defect, quantity }
        })
      }).flat()
    }).flat()
  }

  const groupData = (data: { batchNumber: string, ocNumber: string, totalPassedQuantity: number, totalReworkedQuantity: number, totalRejectedQuantity: number, defect: string, quantity: number }[]) => {
    const result = data.reduce((acc: GroupedDefectsType[], curr) => {
      const { batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defect, quantity } = curr
      const batchNumberKeyIndex = acc.findIndex((item: any) => item.batchNumber === batchNumber)
      if (batchNumberKeyIndex === -1) {
        acc.push({
          batchNumber,
          ocNumber,
          totalPassedQuantity,
          totalReworkedQuantity,
          totalRejectedQuantity,
          defectsAndQuantities: [
            {
              defect,
              quantity
            }
          ]
        })
        return acc
      }
      const { defectsAndQuantities } = acc[batchNumberKeyIndex]
      acc[batchNumberKeyIndex] = Object.assign({
        ...acc[batchNumberKeyIndex],
        defectsAndQuantities: [...defectsAndQuantities, { defect, quantity }]
      })
      return acc
    }, [])
    return result
  }

  const aggregateUniqueDefects = (data: GroupedDefectsType[]) => {
    return data.map(({ batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defectsAndQuantities }) => {
      return {
        batchNumber,
        ocNumber,
        totalPassedQuantity,
        totalReworkedQuantity,
        totalRejectedQuantity,
        defectsAndQuantities: defectsAndQuantities.reduce((acc: { defect: string, quantity: number }[], curr) => {
          const { defect, quantity } = curr
          const defectIndex = acc.findIndex((item: { defect: string, quantity: number }) => item.defect === defect)
          if (defectIndex === -1) {
            acc.push({ defect, quantity })
            return acc
          }
          acc[defectIndex] = Object.assign({
            defect,
            quantity: acc[defectIndex].quantity + quantity
          })
          return acc
        }, [])
      }
    })
  }

  const sortDefectsBasedOnNumberOfDefects = (aggregatedData: GroupedDefectsType[]) => {
    return aggregatedData.map(({ batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defectsAndQuantities }) => {
      return {
        batchNumber,
        ocNumber,
        totalPassedQuantity,
        totalReworkedQuantity,
        totalRejectedQuantity,
        defectsAndQuantities: defectsAndQuantities.sort((a, b) => {
          if (a.quantity > b.quantity) {
            return -1
          }
          if (a.quantity < b.quantity) {
            return 1
          }
          return 0
        })
      }
    })
  }

  const getTopNDefectsPerBatch = (data: GroupedDefectsType[], numberOfDefects: number) => {
    return data.map(({ batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defectsAndQuantities }) => {
      return {
        batchNumber,
        ocNumber,
        totalPassedQuantity,
        totalReworkedQuantity,
        totalRejectedQuantity,
        defectsAndQuantities: [...defectsAndQuantities.slice(0, numberOfDefects)]
      }
    })
  }

  const calculateDHUPerDefect = (data: GroupedDefectsType[]): GroupedDefectsType[] => {
    const result = data.map(({ defectsAndQuantities, batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity }) => {
      const totalInspected = totalPassedQuantity + totalReworkedQuantity + totalRejectedQuantity
      return Object.assign(
        {},
        {
          batchNumber,
          ocNumber,
          totalPassedQuantity,
          totalReworkedQuantity,
          totalRejectedQuantity,
          defectsAndQuantities: defectsAndQuantities.map(({ defect, quantity }) => {
            return {
              defect,
              quantity,
              DHU: Math.round(((quantity / totalInspected) * 100) * 1e2) / 1e2
            }
          })
        }
      )
    })
    return result
  }

  const flattenDefectAndQuantitiesList = (data: GroupedDefectsType[]) => {
    return data.reduce((acc: Map<string, string | number>[], curr) => {
      const { batchNumber, defectsAndQuantities } = curr
      const defectsAndQuantitiesObject = defectsAndQuantities.reduce((acc: { [x: string]: string | number }, curr) => {
        const { defect, DHU } = curr
        return Object.assign({}, acc, { [defect]: DHU })
      }, {})
      const map = new Map(Object.entries(defectsAndQuantitiesObject))
      map.set('batchNumber', batchNumber)
      acc.push(map)
      return acc
    }, [])
  }

  const prepareDataForDisplay = (data: Map<string, string | number>[]) => {
    const objectFromMap = data.map(item => Object.fromEntries(item))
    return objectFromMap.map(object => {
      let i = 0
      let objectToReturn: BarDataType = { first: 0, second: 0, third: 0, batchNumber: '' }
      for (const key in object) {
        if (key === 'batchNumber') {
          objectToReturn['batchNumber'] = object[key]
          break
        }
        if (i === 0) {
          objectToReturn['first'] = object[key]
        } else if (i === 1) {
          objectToReturn['second'] = object[key]
        } else if (i === 2) {
          objectToReturn['third'] = object[key]
        }
        i++
      }
      return objectToReturn
    })
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      const batchNumberData = referenceData.map(({ batchNumber, ocNumber, defectsAndQuantities }) => {
        if (batchNumber === label) {
          return { ocNumber, defectsAndQuantities }
        }
        return null
      }).filter(e => e)
        .flat()
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
          <p style={{ alignSelf: 'center', marginTop: '1.5%', fontWeight: 'bold', color: '#334BFF' }}>OC Number: {batchNumberData[0]?.ocNumber}</p>
          <Table variant='simple' backgroundColor={'white'} borderRadius={'5px'} color={'#334BFF'}>
            <TableCaption>Top 3 Defects Per Batch</TableCaption>
            <Thead>
              <Tr>
                <Th style={{ color: '#334BFF' }}>Defect</Th>
                <Th style={{ color: '#334BFF' }}>DHU%</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                batchNumberData?.map(item => {
                  return item?.defectsAndQuantities.map(({ defect, DHU }) => {
                    return (
                      <Tr key={defect}>
                        <Td>{defect}</Td>
                        <Td>{DHU}%</Td>
                      </Tr>
                    )
                  })
                })
              }
            </Tbody>
          </Table>
        </div >
      )
    }
    return null
  }


  if (data.length === 0 || barData.length === 0) {
    return (
      <div className="dhu-dashboard-error">
        No results found
      </div>
    )
  }
  return (
    <div>
      <div className="dhu-dashboard-header">DHU Chart</div>
      <div className="dhu-dashboard-content" style={{ height: '80vh', margin: '2% 0 0 7%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={400}
            height={800}
            margin={{
              top: 15,
              bottom: 15
            }}
            data={barData}>
            <XAxis dataKey="batchNumber" label={{ value: "Batch", dy: 15, fill: "#334bff" }} />
            <YAxis stroke="#334bff" label={{ value: "DHU %", dx: -15, angle: -90, fill: "#334bff" }} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="first" stroke="#334bff" strokeWidth={'2.5px'} fill="none" />
            <Bar dataKey="second" stroke="#334bff" strokeWidth={'2.5px'} fill="none" />
            <Bar dataKey="third" stroke="#334bff" strokeWidth={'2.5px'} fill="none" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}