import { apiClient } from '../../Services/Networking/apiClient'
import { OcNumberConsolidatedData } from '../types/cutting'

type CuttingRegisterItems = {
  itemCode: string,
  itemDescription: string,
  location: string,
  markerDetail: string
}

type paramsObject = {
  ocNum: string
}
export const getCuttingData = async (params: paramsObject): Promise<CuttingRegisterItems[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/cutting-register`,
    params
  })
  return response as unknown as CuttingRegisterItems[]
}

type sizeQuantity = {
  size: string,
  quantity: string
}

type job = {
  unique: number,
  date: Date,
  layLength: number,
  table: string,
  size: string,
  quantity: number,
  numOfPiecesPerPly: number
}

type CuttingRegisterDetails = {
  itemCode: string,
  itemDescription: string,
  customerName: string,
  styleNumber: string,
  fitType: string,
  approvedConsumption: number,
  sizeQuantites: sizeQuantity[],
  jobs: job[]
}

export const getMinusingRegisterDetails = async (OC: string, itemCode: string, location: string, fitType: string): Promise<CuttingRegisterDetails[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/cutting-register`,
    params: { OC, itemCode, location, fitType }
  })
  return response as unknown as CuttingRegisterDetails[]
}



export const getOcNumberConsolidatedData = async (params: paramsObject): Promise<OcNumberConsolidatedData[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/cutting-register/ocNumber/${params.ocNum}`
  })
  return response as unknown as OcNumberConsolidatedData[]
}