/**
 * An enum object to depict the possible statuses
 * of what the user is searching for
 * @name SearchForEnum
 * @readonly
 * @enum {string}
 */
const SearchForEnum = Object.freeze({
  FABRIC: 'fabric',
  CUTTING: 'cutting',
  WAREHOUSE: 'warehouse',
  SEWING: 'sewing'
})

module.exports = SearchForEnum
