import React from 'react'
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'
import { rfidProductDetailsDataType } from '../types/index'
import FileUploadContainer from '../../UI/Components/FileUploadContainer'

type excelFileUploadProps = {
  onChange: (extractedData: rfidProductDetailsDataType[]) => void
}

const ExcelFileUpload = (props: excelFileUploadProps) => {
  const { onChange } = props

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDropAccepted: (files) => handleUpload(files),
    onDropRejected: () => onChange([]),
    multiple: false
  })

  function handleUpload(files: File[]) {
    const file = files[0]
    const reader = new FileReader()

    reader.onload = (evt: Event) => {
      // Parse data 
      const binaryString = reader.result
      const workBook = XLSX.read(binaryString, { type: 'binary' })

      // Get the first worksheet 
      const workSheetName = workBook.SheetNames[0]
      const workSheet = workBook.Sheets[workSheetName]

      // Convert data in sheets to JSON format
      const data: any = XLSX.utils.sheet_to_json(workSheet)

      const formattedData: rfidProductDetailsDataType[] = data.map((each: any) => {
        const {
          'Order': orderNumber,
          'Season': season,
          'Product': product,
          'Variant': variant,
          'GTIN': gtin,
          'Article Number': articleNumber,
          'SDSCode': sdsCode,
          'Description': description } = each
        return { orderNumber, season, product, variant, gtin, articleNumber, sdsCode, description }
      })

      // Update data in main page
      onChange(formattedData)
    }

    reader.readAsBinaryString(file)
  }

  return (

    <div className="upload-container" style={{ "cursor": "pointer", "fontSize": "1.3vw" }}>
      <FileUploadContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag 'n' Drop or Click to upload a file</p>
        <p>Only .xlsx files are supported</p>
      </FileUploadContainer>
    </div>

  )
}

export default ExcelFileUpload