import React from 'react'
import { As, Button, ButtonProps, OmitCommonProps } from '@chakra-ui/react'

const AddIcon = () => {
  return (
    <img src={process.env.PUBLIC_URL + "/plus.svg"} alt="add" />
  )
}

const AddButton = (props: JSX.IntrinsicAttributes & OmitCommonProps<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, keyof ButtonProps> & ButtonProps & OmitCommonProps<any, keyof ButtonProps> & { as?: As<any> | undefined }) => {
  return (
    <Button bg="brand.100" color="#ffffff" rightIcon={<AddIcon />} {...props}>
      {props.children}
    </Button>
  )
}

export default AddButton