import React from 'react'
import styled from 'styled-components'

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10em;
  color: #0059FA;
`

const LoaderComponent = (props) => {
  return (
    <Loader>
      <i className='fa fa-spinner fa-spin' />
    </Loader>
  )
}

export default LoaderComponent