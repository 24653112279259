import { SewingDashboardFilter, SewingDataDTO } from 'Dashboard/types/sewing'
import React, { useEffect, useState } from 'react'
import { getSewingData } from 'Dashboard/api/getSewingData'
import { Loader } from 'UI/Components'
import { ButtonGroup, Button } from '@chakra-ui/react'
import { DatePicker } from 'UI/Components'
import { SewingSingleDateChart } from './SewingSingleDateChart'
import SewingSingleDateTable from './SewingSingleDateTable'
import SewingDateRangeChart from './SewingDateRangeChart'
import SewingDateRangeTable from './SewingDateRangeTable'

import './SewingDashboard.css'

export const SewingDashboard = (props: { location: string }) => {
  const { location } = props

  const [sewingData, setSewingData] = useState<SewingDataDTO[]>([])

  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(null)

  const [dashboardFilter, setDashboardFilter] = useState<SewingDashboardFilter>(SewingDashboardFilter.CHART)
  const [chartFilterColor, setChartFilterColor] = useState<string>('brand.100')
  const [tableFilterColor, setTableFilterColor] = useState<string>('')

  const [err, setError] = useState<Error>(new Error())
  const [isLoading, setIsLoading] = useState(false)

  // This method returns the date in the string format YYYYMMDD
  const convertDateToString = (date: Date) => {
    return date.toLocaleDateString('en-GB').split('/').reverse().join('')
  }

  useEffect(() => {
    const getDashboardData = async () => {
      const startDateInString = convertDateToString(startDate)
      let endDateInString = 'null'
      if (endDate !== null) {
        endDateInString = convertDateToString(endDate)
      }
      try {
        setIsLoading(true)
        const response = await getSewingData({ location, startDate: startDateInString, endDate: endDateInString })
        setIsLoading(false)
        setSewingData(response)
      } catch (err: any) {
        setSewingData([])
        setIsLoading(false)
        setError(err)
      }
    }

    if (location) {
      getDashboardData()
    }

    return (() => {
      setSewingData([])
    })
  }, [location, startDate, endDate, dashboardFilter])

  const handleFilterChange = (filterType: SewingDashboardFilter) => {
    if (filterType === SewingDashboardFilter.CHART) {
      setChartFilterColor('brand.100')
      setTableFilterColor('')
      setDashboardFilter(filterType)
    }
    if (filterType === SewingDashboardFilter.TABLE) {
      setChartFilterColor('')
      setTableFilterColor('brand.100')
      setDashboardFilter(filterType)
    }
  }

  const handleDateRange = (dateRange: [Date, Date]) => {
    const [start, end] = dateRange
    setStartDate(start)
    setEndDate(end)
    if (start?.getTime() === end?.getTime()) {
      setEndDate(null)
    }
  }

  const handleSingleDate = (date: Date) => {
    if (date.getTime() < startDate.getTime()) {
      setStartDate(date)
      setEndDate(null)
      return
    }
    setEndDate(date)
  }

  let dynamicComponent = null
  switch (dashboardFilter) {
    case SewingDashboardFilter.TABLE:
      if (endDate) {
        dynamicComponent = (<SewingDateRangeTable data={sewingData} />)
        break
      }
      dynamicComponent = (<SewingSingleDateTable data={sewingData} />)
      break
    case SewingDashboardFilter.CHART:
      if (endDate) {
        dynamicComponent = (<SewingDateRangeChart data={sewingData} />)
        break
      }
      dynamicComponent = (<SewingSingleDateChart data={sewingData} />)
      break
    default:
      throw new Error('Oops! Invalid dashboard filter')
  }

  if (isLoading) {
    dynamicComponent = (<Loader />)
  }

  return (
    <React.Fragment>
      <div className="filter-buttons">
        <ButtonGroup key='stitching-filters-group' isAttached variant="outline">
          <Button color={chartFilterColor} onClick={() => handleFilterChange(SewingDashboardFilter.CHART)}>Chart</Button>
          <Button color={tableFilterColor} onClick={() => handleFilterChange(SewingDashboardFilter.TABLE)}>Table</Button>
        </ButtonGroup >
      </div>
      <div className="sewing-dashboard-date-picker">
        {
          endDate ? (
            <DatePicker
              maxDate={new Date()}
              selectsRange
              selectsStart
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRange}
              placeholderText="Select Date"
              dateFormat='dd MMM yyyy' />
          ) : (
            <DatePicker
              maxDate={new Date()}
              selected={startDate}
              selectsStart
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={handleSingleDate}
              placeholderText="Select Date"
              dateFormat='dd MMM yyyy' />
          )
        }
      </div>
      <div className="sewing-dynamic-dashboard-component">
        {dynamicComponent}
      </div>
    </React.Fragment>
  )
}