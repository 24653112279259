import React from 'react'
import styled from 'styled-components'

import Button from './Button'

const AccordionButton = styled(Button)`
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 5% 0 5%;
  background-color: white;
  color: unset;
  border: 1px solid gainsboro;
`

const AccordionButtonComponent = (props) => {
  return (
    <AccordionButton {...props}>
      {props.children}
    </AccordionButton>
  )
}

export default AccordionButtonComponent