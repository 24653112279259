import React, { useState, useEffect } from "react"
import { Input, Loader } from "UI/Components"
import SearchButton from "UI/Components/Button/SearchButton"
import { getScannedCartonDetails } from "Rfid/api/getCartonDetails"
import { ScannedCartonDetails } from "Rfid/types"
import DownloadRfidResults from "./DownloadRfidResults"

import "./DownloadRfidData.css"
import { FilterDatePicker } from "UI/Components/FilterDatePicker"

const DownloadRfidData = () => {
  const [location, setLocation] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [_, setError] = useState('')
  const [data, setData] = useState<ScannedCartonDetails[]>([])
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  // This method returns the date in the string format YYYY-MM-DD
  const convertDateToString = (date: Date) => {
    return date ? date.toLocaleDateString('en-GB').split('/').reverse().join('-') : null
  }

  const handleSearchClick = async () => {
    if (location === '') {
      return
    }

    setIsLoading(true)
    const fromScanDateInString = convertDateToString(startDate)
    const toScanDateInString = convertDateToString(endDate)
    
    try {
      const response = await getScannedCartonDetails(location, fromScanDateInString, toScanDateInString)
      setData(response)
      setIsLoading(false)
      if (response.length === 0) {
        setShowMessage(true)
      } else {
        setShowMessage(false)
      }
    } catch (err: any) {
      setError(() => {
        throw Error(err)
      })
    }
  }
  
  useEffect(() => {
    if (endDate){
      handleSearchClick()
    }
  }, [startDate, endDate])

  const handleKeyUpEvent = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleSearchClick()
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="rfid_download_container">
      <div className="search_container">
        <div className="location_search_container">
          <Input
            placeholder="Enter location"
            onChange={e => setLocation(e.target.value)}
            onKeyUp={e => handleKeyUpEvent(e)}
            value={location}
          />
          <div className="filter-date-picker">
            <FilterDatePicker 
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(date: [Date, Date]) => {
                return (setStartDate(date[0]), setEndDate(date[1]))
              }}
              dateFormat='dd MMM yyyy' />
          </div>
          <SearchButton marginLeft='2%' onClick={() => handleSearchClick()}>
            Search
          </SearchButton>
        </div>
      </div>

      {showMessage === false ?
        (
          <div className="rfid_download_result_table">
            <DownloadRfidResults
              data={data}
              handleSearchClick={handleSearchClick}
              location={location}
              startDate={convertDateToString(startDate)}
              endDate={convertDateToString(endDate)} />
          </div>
        ) :
        (
          <div style={{ margin: '10% 42%', color: '#334bff' }}>
            <h2>No results found</h2>
          </div>
        )
      }
    </div>

  )
}

export default DownloadRfidData