import React, { useState, useEffect } from 'react'
import { SewingDataDTO } from 'Dashboard/types/sewing'
import { Box, Center, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'

import './SewingSingleDateTable.css'

type SewingSingleDateTableProps = {
  data: SewingDataDTO[]
}

type TableDataType = {
  timeInterval: string,
  total: number,
  efficiency: number,
  count: number,
  [batchNumber: string]: string | number,
  samProduced: number,
  machineMinutes:number
}

const SewingSingleDateTable = (props: SewingSingleDateTableProps) => {
  const { data } = props

  const [tableData, setTableData] = useState<TableDataType[]>([])
  const [uniqueListOfBatches, setUniqueListOfBatches] = useState<string[]>([])

  useEffect(() => {
    const formattedData = formatDataForTable(data)
    setTableData(formattedData)
    const uniqueListOfBatches = getUniqueListOfBatches(data)
    setUniqueListOfBatches(uniqueListOfBatches)
  }, [data])

  const formatDataForTable = (data: SewingDataDTO[]) => {
    const formattedData: TableDataType[] = []
    data.map(({ date, batchNumber, hourlyDetails }) => {
      
      const presentDateTime = new Date(new Date().toLocaleString('en-Us', { hour12: false, timeZone: 'Asia/Kolkata' }))
      const workPresentDateTimeToCalculate = new Date(Date.UTC(presentDateTime.getFullYear(), presentDateTime.getMonth(), presentDateTime.getDate(), presentDateTime.getHours(), presentDateTime.getMinutes(), 0)).toISOString()
      const newDate = new Date(date)

      hourlyDetails.map(({ startTime, endTime, hourEfficiency, outputQuantity, sewingSamProduced, sewingMachineMinutes }) => {
        const formattedDataTimeIndex = formattedData.findIndex(({ timeInterval }) => timeInterval === `${startTime}-${endTime}`)

        const workEndTimeSplit = endTime.split(':')
        const workEndDateTimeToCalculate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), workEndTimeSplit[0] as unknown as number, workEndTimeSplit[1] as unknown as number, 0)).toISOString()
        /** Validate present date time comparison with API response date time 
         * to get actual machine and same produced minutes
         */
        let actualMachineMinutes = 0
        let actualSamProducedMinutes = 0

        if (sewingSamProduced > 0 || workEndDateTimeToCalculate <= workPresentDateTimeToCalculate){
          actualMachineMinutes = sewingMachineMinutes
          actualSamProducedMinutes = sewingSamProduced
        }

        //  if this time interval has not been inserted
        if (formattedDataTimeIndex === -1) {
          formattedData.push({
            timeInterval: `${startTime}-${endTime}`,
            [batchNumber]: outputQuantity,
            efficiency: hourEfficiency,
            total: outputQuantity,
            count: 1,
            samProduced: actualSamProducedMinutes,
            machineMinutes: actualMachineMinutes
          })
          return
        }

        //  if this interval has already been inserted
        let { total, efficiency, count, samProduced,  machineMinutes} = formattedData[formattedDataTimeIndex]
        const averageEfficiency = (efficiency * count + hourEfficiency) / ++count

        //  This calculation is to account for multiple OC#'s running in one batch
        const batchTotalForTimeIndex = formattedData[formattedDataTimeIndex][batchNumber]
        let batchTotal = 0
        if (typeof batchTotalForTimeIndex === 'number') {
          batchTotal = batchTotalForTimeIndex + outputQuantity
        } else {
          batchTotal = outputQuantity
        }

        formattedData[formattedDataTimeIndex] = Object.assign(
          {},
          { ...formattedData[formattedDataTimeIndex] },
          {
            [batchNumber]: batchTotal,
            total: total + outputQuantity,
            efficiency: Math.round(averageEfficiency * 1e2) / 1e2,
            count,
            samProduced: samProduced + actualSamProducedMinutes,
            machineMinutes: machineMinutes + actualMachineMinutes
          }
        )
      })
    })
    
    return formattedData
  }

  const getUniqueListOfBatches = (data: SewingDataDTO[]) => {
    const set = new Set<string>()
    data.map(({ batchNumber }) => {
      set.add(batchNumber)
    })
    const listOfBatches = Array.from(set)
    return listOfBatches
  }

  if (data.length === 0) {
    return (
      <div className="sewing-dashboard-error">
        No results found
      </div>
    )
  }

  const roundNumberToSignificantDigits = (number: number, numberOfDigits: number) => {
    return Math.round(number * Math.pow(10, numberOfDigits)) / Math.pow(10, numberOfDigits)
  }

  const getAverageEfficiency = (data: TableDataType[]) => {
    const overAllSamProduced = data.reduce((acc, curr) => {
      const { samProduced } = curr
      return acc + samProduced
    }, 0)

    const overAllMachineMinutes = data.reduce((acc, curr) => {
      const { machineMinutes } = curr
      return acc + machineMinutes
    }, 0)
   
    if (overAllSamProduced > 0){
      return roundNumberToSignificantDigits(((overAllSamProduced / overAllMachineMinutes) * 100), 2)
    }
    return 0
  }

  const getRowEfficiency = (samProduced: number, machineMinutes:number) => {
    if (samProduced > 0){
      return roundNumberToSignificantDigits(((samProduced / machineMinutes) * 100), 2)
    }
    return 0
  }

  return (
    <div style={{ marginTop: '3%', marginLeft: '3%' }}>
      <Center><h2 className='single-day-efficiency-batch-header' style={{ color: '#334BFF', fontWeight: 'bold' }}>Batch Efficiency Table</h2></Center>
      <Box overflowX={'auto'} margin={'2%'} display={'flex'} flexDirection={'column'}>
        <Table className='single-date-table'>
          <Thead>
            <Tr>
              <Th color={'brand.100'}><Center>Time</Center></Th>
              {
                uniqueListOfBatches.map((batchNumber) => {
                  return (
                    <Th color='brand.100' key={batchNumber}><Center>{batchNumber}</Center></Th>
                  )
                })
              }
              <Th color={'brand.100'}><Center>Total</Center></Th>
              <Th color={'brand.100'}><Center>Efficiency</Center></Th>
            </Tr>
          </Thead>
          <Tbody color={'brand.100'}>
            {
              tableData.map(({ timeInterval, total, efficiency, samProduced, machineMinutes, ...batchData }) => {
                return (
                  <Tr key={timeInterval}>
                    <Td><Center>{timeInterval}</Center></Td>
                    {
                      uniqueListOfBatches.map((batchNumber) => {
                        return <Td key={batchNumber}><Center>{batchData[batchNumber] || 0}</Center></Td>
                      })
                    }
                    <Td><Center>{total}</Center></Td>
                    <Td><Center>{getRowEfficiency(samProduced, machineMinutes).toFixed(2)}%</Center></Td>
                  </Tr>
                )
              })
            }
            <Tr key={'final-row'}>
              <Td><Center>{null}</Center></Td>
              {
                uniqueListOfBatches.map((batchNumber, index) => {
                  if (index + 1 === uniqueListOfBatches.length) {
                    return null
                  }
                  return <Td><Center>{null}</Center></Td>
                })
              }
              <Td colSpan={2} className='average-efficiency-row-item first-item'>Average Efficiency</Td>
              <Td className='average-efficiency-row-item'><Center>{getAverageEfficiency(tableData).toFixed(2)}%</Center></Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </div>
  )
}

export default SewingSingleDateTable