import { Box, VStack } from "@chakra-ui/react"

const ItemBox = (props: { data: string, color: string, handleClick: Function }) => {
  const { data, color, handleClick } = props
  return (
    <Box color={color} cursor='pointer' display='flex' onClick={() => handleClick(data)}>
      {data}
    </Box>
  )
}


const SidePanel = (props: { itemList: string[], selected: string, onSelect: Function }) => {
  const { itemList, onSelect, selected } = props

  return (
    <VStack bg='gray.50' spacing={'15%'}>
      {
        itemList.map((each, i) => {
          if (each === selected) {
            return (<ItemBox key={each} data={each} color={'blue'} handleClick={onSelect} />)
          }
          return (<ItemBox key={each} data={each} color={''} handleClick={onSelect} />)
        })
      }
    </VStack >
  )
}

export default SidePanel