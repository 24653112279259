/**
 * An enum object to depict the possible filters on the cutting dahsboard
 * @name CuttingFilterEnum
 * @readonly
 * @enum {String}
 */
const CuttingFilterEnum = Object.freeze({
  OVERVIEW: 'overview',
  ITEMS: 'items'
})

module.exports = CuttingFilterEnum
