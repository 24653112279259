import React from 'react'
import { Input as InputComponent, InputProps } from '@chakra-ui/react'

const Input = (props: JSX.IntrinsicAttributes & InputProps) => {
  return (
    <InputComponent colorScheme="brand.100"
      textAlign={props.textAlign || 'center'}
      {...props} />
  )
}

export default Input
