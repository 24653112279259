import React, { useMemo } from 'react'
import { Td, Tr, Th, Table, Tbody, Thead } from '@chakra-ui/table'
import { chakra } from '@chakra-ui/system'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import styled from '@emotion/styled'
import { Column, useFlexLayout, useSortBy, useTable } from 'react-table'
import { FabricDataDTO, PalletDataDTO, WarehouseFabricOverallDataOrganized, WarehouseOverallDataOrganized } from 'Dashboard/types'

type Data = PalletDataDTO | WarehouseOverallDataOrganized | FabricDataDTO | WarehouseFabricOverallDataOrganized

type ColumnType = PalletDataDTO | WarehouseOverallDataOrganized | FabricDataDTO | WarehouseFabricOverallDataOrganized

function WarehouseDataTable(props: { data: Data[]; columns: any }) {

  const tableData = useMemo(() => props.data, [props.data])

  const columns = useMemo(() => props.columns as Column<ColumnType>[], [props.columns])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: tableData }, useSortBy, useFlexLayout)

  const WrappedTableRow = styled(Tr)`
    td {
      :last-child, :first-of-type{
        border-right: 0;
      }
    }
  `

  const WrappedTableData = styled(Td)`
    border: none;
    border-right: 1px solid #334bff;
    text-align: center;
  `

  return (
    <Table {...getTableProps()} marginTop='3em'>
      <Thead>
        {headerGroups.map(headerGroup => {
          return <Tr {...headerGroup.getHeaderGroupProps()} marginBottom='1.5em'>
            {headerGroup.headers.map((column: any) => {
              const shouldSortingArrowShow = column.canSort
              const triangleComponent = column.isSortedDesc ?
                (<TriangleDownIcon aria-label='sorted descending' />)
                : (<TriangleUpIcon aria-label='sorted ascending' />)
              return <Th {...column.getHeaderProps(column.getSortByToggleProps())} color="brand.100" fontSize='1.2em' textAlign='center' border='none'>
                {column.render("Header")}
                <chakra.span>
                  {
                    shouldSortingArrowShow ? (
                      triangleComponent
                    ) : null
                  }
                </chakra.span>
              </Th>
            })}
          </Tr>
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <WrappedTableRow {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <WrappedTableData {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </WrappedTableData>
              })}
            </WrappedTableRow>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default WarehouseDataTable