export type PalletDataDTO = {
  barcode: string,
  articleNumber: string,
  numberOfPieces: number,
  dateOfManufacture: number,
  rackDissociationDate: number
}

export type GroupedDataByArticleNumber = {
  [key: string]: [
    {
      articleNumber: string,
      barcode: string,
      dateOfManufacture: number,
      numberOfPieces: number,
      rackDissociationDate: number
    }
  ]
}

export type WarehouseOverallDataOrganized = {
  articleNumber: string,
  numberOfPallets: number
}

export type FabricDataDTO = {
  rackNumber: string,
  itemCode: string,
  itemDescription: string,
  fabricLength: number,
  mirDate: number
}

export type WarehouseFabricOverallDataOrganized = {
  itemCode: string,
  numberOfRolls: number
}

export enum FilterTypeEnum {
  PALLETS = 'pallets',
  FABRIC = 'fabric'
}