import React from 'react'
import { Select } from 'UI/Components'

type SelectProductProps = {
  products: string[],
  product: string,
  defaultOption: string,
  onChange: (product: string) => void
}

export const SelectProduct = (props: SelectProductProps) => {
  const { products, product, defaultOption, onChange } = props

  return (
    <Select
      defaultOption={defaultOption}
      value={product}
      onChange={(event: Event) => {
        onChange((event.target as HTMLInputElement).value)
      }}
      valuesToMap={products}
      mapperFn={(product: string) => {
        return (
          <option key={product} value={product}>{`${product}`}</option>
        )
      }} />
  )
}