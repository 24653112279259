import React from 'react'
import BarcodeTable from './BarcodeTable'
import { BackArrow } from '../UI/Components'

/**
 * Container class for the table where the user enters the roll information
 * which includes roll length and roll number provided by the supplier
 */
class BarcodeTableContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: this.createInitialTableData(),
      currQty: props.currQty, // the quantity that already has barcodes generated for it
      ordQty: props.ordQty, // the order quantity placed with the supplier according to Sage
      newCurrQty: props.currQty, // the updated current quantity every time the user enters a roll length,
      sessionQty: 0 // the quantity entered by the user in the current session
    }
  }

  render() {
    const { tableData, ordQty, newCurrQty, sessionQty } = this.state
    return (
      <React.Fragment>
        <BackArrow onClick={this.props.resetBarcodeForm} alt={`Back Button`} />
        <BarcodeTable
          tableData={tableData}
          ordQty={ordQty}
          newCurrQty={newCurrQty}
          sessionQty={sessionQty}
          addTableRow={this.addTableRow}
          removeTableRow={this.removeTableRow}
          handleTableDataInput={this.handleTableDataInput}
          handleTableSubmit={this.handleTableSubmit} />
      </React.Fragment>
    )
  }

  /**
   * This function is called once by the constructor upon mounting the component
   * The main purpose of this function is to check if the state variable tableData
   * needs to be initialized again (happens if empty array received from parent) or
   * if storedTableData can be used (if non empty array is received from parent).
   * This is primarily to store the table data the user has entered in case of an error
   * so the user does not have to re-enter all the data again 
   */
  createInitialTableData = () => {
  	const { storedTableData } = this.props
  	if (storedTableData.length > 0) {
  		return storedTableData
  	} 
  	return [{ roll: 0, length: 0, id: new Date().getTime() }]
  }

  /**
   * This function will round the received number to three significant digits
   * @function
   * @param {Number} number
   * @return {Number}
   */
  roundSignificantDigits = (number) => {
  	return Math.round(number * 1e3) / 1e3
  }

  /**
   * Function that is called when adding a table row to the table where roll data is entered
   * Only updates the table if the current PO's ordQty > currQty
   */
  addTableRow = () => {
  	const { ordQty, newCurrQty } = this.state
  	const allowedPerc = this.roundSignificantDigits(2 + (50 / ordQty) * 100)
  	const percDiff = this.roundSignificantDigits(((newCurrQty - ordQty) / ordQty) * 100)

  	if (percDiff >= allowedPerc) {
  		window.alert('You cannot add any more rolls to this PO')
  		return
  	}

  	const { tableData } = this.state
  	const updatedTableData = [...tableData, { roll: 0, length: 0, id: new Date().getTime() }]
  	this.setState({
  		tableData: updatedTableData
  	}, () => {
  		const rollNumInputsArray = [...document.getElementsByClassName('roll-num-entry')]
  		const lastElem = rollNumInputsArray[rollNumInputsArray.length - 1]
  		lastElem.focus()
  		lastElem.value = ''
  	})
  }

  /**
   * Function that is called to remove a row from the table where roll details are entered
   * Updates the table data and updates the currQty
   */
  removeTableRow = (index) => {
  	let { tableData, currQty } = this.state
  	let sessionQtyCounter = 0
  	const updatedTableData = tableData.slice(0, index).concat(tableData.slice(index + 1))
  	updatedTableData.map(indRow => {
  		currQty += (Math.round(parseFloat(indRow.length) * 1e3) / 1e3) || 0
  		sessionQtyCounter += (Math.round(parseFloat(indRow.length) * 1e3) / 1e3) || 0
  		return indRow
  	})
  	this.setState({
  		tableData: updatedTableData,
  		newCurrQty: currQty,
  		sessionQty: sessionQtyCounter
  	})
  }

  /**
   * Function that is called whenever a new data point is entered in the barcode table
   * It will update the tableData state variable and will also update the currQty state variable
   */
  handleTableDataInput = (value, row, col) => {
  	const valueFloat = parseFloat(value)

  	//  Early exit if the length value entered is negative
  	if (col === "length" && valueFloat < 0) {
  		return
  	}

  	let { tableData, currQty } = this.state
  	let sessionQtyCounter = 0
  	const modifiedTableData = tableData.map((indRow, index) => {
  		if (row === index) {
  			return Object.assign({}, { ...indRow }, { [col]: value })
  		} 
  		return indRow
  	})
  	modifiedTableData.map(indRow => {
  		//  keep a precision of upto 3 decimal numbers
  		currQty += (Math.round(parseFloat(indRow.length) * 1e3) / 1e3) || 0
  		sessionQtyCounter += (Math.round(parseFloat(indRow.length) * 1e3) / 1e3) || 0
  		return indRow
  	})
  	this.setState({
  		tableData: modifiedTableData,
  		newCurrQty: Math.round(currQty * 1e3) / 1e3, //  keep a precision of upto 3 decimal numbers
  		sessionQty: Math.round(sessionQtyCounter * 1e3) / 1e3 // keep a precision of upto 3 decimal numbers
  	})
  }

  handleTableSubmit = (event) => {
  	event.preventDefault()
  	const { ordQty, newCurrQty } = this.state
  	const allowedPerc = this.roundSignificantDigits(2 + (50 / ordQty) * 100)
  	const percDiff = this.roundSignificantDigits(((newCurrQty - ordQty) / ordQty) * 100)
  	if (percDiff >= allowedPerc) {
  		window.alert('You have crossed the limit for this PO. Please check the total length of rolls you have entered')
  		return
  	}
  	const userInput = window.confirm('Are you sure want to submit this data?')
  	if (userInput === true) {
  		this.validateTableData()
  	}
  }

  removeTableErrors = (tableData) => {
  	return tableData.map((row) => {
  		delete row.error
  		return Object.assign({}, {...row})
  	})
  }

  checkForLengthErrors = (tableData) => {
  	return tableData.map((row) => {
  		if (row.length <= 0) {
  			return Object.assign({}, {...row}, { error: { length: 'This is an invalid length' } })
  		}
  		return row
  		
  	})
  }

  checkForRollNoErrors = (tableData) => {
  	const arr = []
  	return tableData.map((row) => {
  		if (arr.includes(row.roll)) {
  			return Object.assign({}, { ...row }, { error: Object.assign({}, { ...row.error }, { roll: 'You have repeated this roll number' }) })
  		}
  		arr.push(row.roll)
  		return row
  		
  	})
  }

  checkIfTableIsValidAndUpdateState = (tableData) => {
  	const isTableValid = tableData.reduce((acc, currRow) => {
  		return acc && !currRow.hasOwnProperty('error')
  	}, true)
  	if (isTableValid) {
  		this.props.tableValidatedDisplayBarcodes(tableData)
  	} else {
  		this.setState({
  			tableData
  		})
  	}
  }

  /**
   * This function is called whenever the submit button is clicked on a table
   * It runs a pipe function that contains all the smaller validation functions
   * The last function will check if table data is valid and update state or call props
   */
  validateTableData = () => {
  	const { tableData } = this.state

  	const tableFnsPipe = pipe(this.removeTableErrors, this.checkForLengthErrors, this.checkForRollNoErrors, this.checkIfTableIsValidAndUpdateState)
  	tableFnsPipe(tableData)
  }
}

const pipe = (...fns) => (result) => {
  const list = [...fns]

  while (list.length > 0) {
    result = list.shift()(result)
  }
  return result
}

export default BarcodeTableContainer