import {getSearchWarehouseFabricInformation } from 'Dashboard/api/getWarehouseData'
import { FabricDataDTO } from 'Dashboard/types'
import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Loader } from 'UI/Components'
import WarehouseDataTable from './WarehouseDataTable'

export const WarehouseSearchFabricResults = () => {

  const { search } = useLocation()

  const [_, setError] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<FabricDataDTO[]>([])

  type ParamsObject = {
    [key: string]: string
  }

  const params = new URLSearchParams(search)
  //  the below useMemo function has search as a dependency because
  //  it needs to be re-computed every time the URL location changes
  const paramsObject: ParamsObject = useMemo(() => ({}), [search])
  
  for (const [key, value] of params.entries()) {
    paramsObject[key] = value
  }

  // run on first render to fetch data from the api 
  useEffect(() => {
    const makeRequestToGetOverallWarehouseFabricData = async () => {
      setIsLoading(true)
      try {
        const response: FabricDataDTO[] = await getSearchWarehouseFabricInformation(paramsObject)
        setIsLoading(false)
        setData(response)
      } catch (err) {
        setIsLoading(false)
        setError(() => {
          throw err
        })
      }
    }

    makeRequestToGetOverallWarehouseFabricData()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  const tableData:FabricDataDTO[] = (data)

  const columns = [
    {
      Header: "",
      id: "row",
      Cell: (props: any) => {
        return <div style={{textAlign: 'right'}} >{props.row.index + 1}.</div>
      },
      disableSortBy: true,
      width: 30
    },
    {
      Header: "Rack Number",
      accessor: "rackNumber"
    },
    {
      Header: "Item Code",
      accessor: "itemCode"
    },
    {
      Header: "Description",
      accessor: "itemDescription"
    },
    {
      Header: "Length",
      accessor: "fabricLength"
    },
    {
      Header: "MIR",
      accessor: "mirDate"
    }
  ]

  return (
    <div style={{ width: '90%' }}>
      <WarehouseDataTable data={tableData} columns={columns} />
    </div>
  )
}

