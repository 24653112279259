import React from 'react'
import styled from 'styled-components'

const ErrorDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20vh auto 0 auto;
  width: 70vh;
  height: 30vh;
  border: 1px solid #334bff;
  color #334bff;
`
const ErrorDisplayComponent = (props) => {
  return (
    <ErrorDisplay>
      {props.children}
    </ErrorDisplay>
  )
}

export default ErrorDisplayComponent