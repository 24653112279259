import { productCodeAndDescription } from 'TechPack/types'
import { apiClient } from '../../Services/Networking/apiClient'


export const getLocations = async (): Promise<string[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/service/locations`
  })
  return response as unknown as string[]
}

export const validateOCNumberAndFetchProducts = async (ocNumber: string): Promise<productCodeAndDescription[]> => {
  const response = await apiClient({
    method: 'get',
    'url': `/fabstitch/ocNumber/${ocNumber}/products`
  })
  return response as unknown as productCodeAndDescription[]
}

type uploadTechPackParams = {
  ocNumber: string,
  productCode: string,
  productDescription: string,
  location: string,
  productTechPack: string
}

export const uploadTechPackDetails = async (params: uploadTechPackParams): Promise<boolean> => {
  const { ocNumber, productCode, productDescription, productTechPack, location } = params
  const response = await apiClient({
    method: 'post',
    url: `/fabstitch/defect/product/${productCode}/techPack`,
    data: { ocNumber, location, productDescription, productTechPack }
  })

  return response as unknown as boolean
}