import { Table, Thead, Tbody, Tr, Th, Td, Center, TableCaption, Button } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { deleteCartonId } from "Rfid/api/deleteCartonId"
import { ScannedEPCDetails } from "Rfid/types"
import { DeleteButtonWithConfirmation } from "UI/Components/Button/DeleteButtonWithConfirmation"

type CartonTableProps = {
  data: ScannedEPCDetails[],
  handleSearchClick: any,
  location: string,
  startDate: string | null,
  endDate: string | null,
  shipmentId: string
}

type ColumnsType = {
  header: string,
  accessor: keyof ScannedEPCDetails
}

type RowDataType = {
  [header: string]: string | number
}

type CartonTableType = {
  cartonId: string,
  cartonNumber: number,
  cartonCode: string,
  cartonWeight: number,
  packType: string,
  count: number
}

const CartonDataTable = (props: CartonTableProps) => {
  const { data, handleSearchClick, location, startDate, endDate, shipmentId } = props
  const [tableData, setTableData] = useState<CartonTableType[]>([])
  const [_, setError] = useState('')

  // The column headers for the csv file to be downloaded
  const columns: ColumnsType[] = [
    {
      header: 'Ctn',
      accessor: 'cartonCode'
    },
    {
      header: 'Type Of Pack',
      accessor: 'packType'
    },
    {
      header: 'Ctr No.',
      accessor: 'cartonNumber'
    },
    {
      header: 'Gross Weight',
      accessor: 'cartonWeight'
    },
    {
      header: 'electronic_product_code',
      accessor: 'epc'
    }
  ]

  // This method returns the list of rows required for the csv file
  const getTableDataForExport = (data: ScannedEPCDetails[], columns: ColumnsType[]) => data.map((record: ScannedEPCDetails) => columns
    .reduce((recordToDownload, column) => (
      { ...recordToDownload, [column.header]: record[column.accessor] }
    ), {}))

  const downloadCSVFile = async (rows: RowDataType[], filename: string) => {
    const separator: string = ','
    const headers: string[] = columns.map(each => each.header) // The column headers

    const csvContent = `${headers.join(separator)}\n${rows.map((row) => headers.map((each) => {
      let cell = row[each] === null || row[each] === undefined ? '' : row[each]
      return cell
    }).join(separator)).join('\n')}`

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDownloadClick = () => {
    const fileName = `${location}_${startDate}_${endDate}_${shipmentId}.csv`
    return downloadCSVFile(getTableDataForExport(data, columns), fileName)
  }

  const handledeleteCartonId = async(cartonId: string) => {
    try {
      const response = await deleteCartonId(cartonId)

      handleSearchClick()
    }
    catch (err: any) {
      setError(() => {
        throw Error(err)
      })
    }
  }

  useEffect(() => {
    const groupData = () => {
      const groupedData: CartonTableType[] = []
      data.forEach(each => {
        const { cartonId, cartonCode, packType, cartonNumber, cartonWeight } = each
        const cartonObject = groupedData.find(each => each.cartonNumber === cartonNumber)
        if (cartonObject) {
          cartonObject.count += 1
        } else {
          groupedData.push({ cartonId, cartonCode, cartonNumber, cartonWeight, packType, count: 1 })
        }
      })
      setTableData(groupedData)
    }
    groupData()
  }, [data])

  return (
    <div>
      <Table>
        <TableCaption placement="top">
          <Button onClick={() => handleDownloadClick()}>Download</Button>
        </TableCaption>
        <Thead style={{ position: "sticky", top: "0", zIndex: 1 }} backgroundColor="lightblue">
          <Tr>
            <Th><Center>Carton Code</Center></Th>
            <Th><Center>Type Of Pack</Center></Th>
            <Th><Center>Carton No.</Center></Th>
            <Th><Center>Gross Weight</Center></Th>
            <Th><Center>EPC Count</Center></Th>
            <Th><Center></Center></Th>
          </Tr>
        </Thead>
        <Tbody color='brand.100'>
          {
            tableData.map(each => {
              const { cartonId, cartonCode, packType, cartonNumber, cartonWeight, count } = each
              return (
                <Tr key={cartonId} >
                  <Td><Center>{cartonCode}</Center></Td>
                  <Td><Center>{packType}</Center></Td>
                  <Td><Center>{cartonNumber}</Center></Td>
                  <Td><Center>{cartonWeight}</Center></Td>
                  <Td><Center>{count}</Center></Td>
                  <Td><Center><DeleteButtonWithConfirmation handleDelete={handledeleteCartonId} idToDelete={cartonId}/></Center></Td>
                </Tr>
              )
            })
          }
        </Tbody>
      </Table>
    </div >
  )
}

export default CartonDataTable