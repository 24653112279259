import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import SearchBar from './SearchBar'
import DashboardBase from './DashboardBase'
import MinusingRegister from './MinusingRegister'
import WarehouseDashboard from './WarehouseDashboard'
import { SewingDashboardBase } from './Components/SewingDashboardBase'

const Dashboard = (props) => {
  const { token } = props
  const { path } = useRouteMatch()
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${path}`}>
          <DashboardBase />
        </Route>
        <Route path={`${path}/fabric`}>
          <SearchBar searchFor='fabric' token={token} />
        </Route>
        <Route path={`${path}/cutting`}>
          <SearchBar searchFor='cutting' token={token} />
        </Route>
        <Route path={`${path}/cutting/results/:markerUnique`}>
          <MinusingRegister token={token} />
        </Route>
        <Route path={`${path}/warehouse`}>
          <WarehouseDashboard />
        </Route>
        <Route path={`${path}/sewing`}>
          <SewingDashboardBase />
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default Dashboard