import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'

type deleteButtonProps = {
  handleDelete : Function, 
  idToDelete: string
}

export const DeleteButtonWithConfirmation = (props : deleteButtonProps) => {
  const { handleDelete, idToDelete } = props
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px)'
    />
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  return (
    <>
      <Button style={{ backgroundColor: 'white' }}
        onClick={() => {
          setOverlay(<OverlayOne />)
          onOpen()
        }}
      >
        <DeleteIcon color="brand.100" />
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure? You can't undo this action afterwards</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={async () => {
              await handleDelete(idToDelete)
              onClose()
            }} colorScheme='red' ml={3}>Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
