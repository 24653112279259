import React from 'react'
import { useDropzone } from 'react-dropzone'
import FileUploadContainer from '../../UI/Components/FileUploadContainer'

type imageFileUploadProps = {
  onChange: (file: string) => void
}

const ImageFileUpload = (props: imageFileUploadProps) => {
  const { onChange } = props

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDropAccepted: (files) => handleUpload(files),
    onDropRejected: () => onChange(""),
    multiple: false
  })

  function handleUpload(files: File[]) {
    const file = files[0]
    // convert image to base64
    var reader = new FileReader()
    let base64String: string = ''
    reader.onloadend = function () {
      base64String = reader.result?.toString() || ''
      base64String = base64String.split(',')[1]
      onChange(base64String)
    }
    reader.readAsDataURL(file)
  }

  return (

    <div className="upload-container" style={{ "cursor": "pointer", "fontSize": "1.3vw" }}>
      <FileUploadContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop or click to upload a file</p>
        <p>Only image files are supported</p>
      </FileUploadContainer>
    </div>

  )
}

export default ImageFileUpload