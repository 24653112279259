import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'

import { networkRequest } from '../Services/NetworkRequest'
import { Loader, AccordionButton, Grid } from '../UI/Components'

import './FabricLocationInfo.css'

function FabricLocationInfo(props) {
  const { itemCode } = useParams()

  const { token } = props
  const [isLoading, setIsLoading] = useState(false)
  const [_, setError] = useState()  //  eslint-disable-line no-unused-vars
  const [ordered, setOrdered] = useState(0)

  const [stock, setStock] = useState([])
  const [stockTotal, setStockTotal] = useState(0)

  const [received, setReceived] = useState([])
  const [receivedTotal, setReceivedTotal] = useState(0)

  const [transferred, setTransferred] = useState([])

  const [adjustment, setAdjustment] = useState([])
  const [adjustmentTotalIncoming, setAdjustmentTotalIncoming] = useState(0)
  const [adjustmentTotalOutgoing, setAdjustmentTotalOutgoing] = useState(0)

  const [togglePanel, setTogglePanel] = useState({
    receivedVisibility: false,
    transferredVisibility: false,
    stockVisibility: false,
    adjustmentVisibility: false
  })

  function roundNumberToThreeDecimals(number) {
    return Math.round(parseFloat(number) * 1e3) / 1e3
  }

  function convertToLocaleString(number) {
    return number.toLocaleString('en-IN')
  }

  function handleAccordionClick(event) {
    const expanded = event.currentTarget.attributes.getNamedItem('aria-expanded')
    const key = `${event.currentTarget.id}Visibility`
    if (expanded.value === 'false') {
      setTogglePanel({
        ...togglePanel,
        [key]: true
      })
    } else if (expanded.value === 'true') {
      setTogglePanel({
        ...togglePanel,
        [key]: false
      })
    }
  }

  useEffect(() => {
    const fetchFabricLocationInfo = async (itemCode) => {
      try {
        setIsLoading(true)
        const { ordered, mirInfo, dcInfo, locationStock, adjustmentInfo } = await networkRequest('GET', '/analytics/fabric/itemCode', { itemCode }, token)
        setIsLoading(false)
        setOrdered(ordered)
        setStock(locationStock)
        setAdjustment(adjustmentInfo)
        const dateFormattedMirInfo = mirInfo.map((mirObject) => {
          const regex = /(?<date>\d{2})-(?<month>\d{2})-(?<year>\d{4})/
          const { groups: { date, month, year } } = mirObject.date.toString().match(regex)
          return Object.assign({}, { ...mirObject }, { date: format(new Date(`${year}-${month}-${date}`), 'do MMM yy') })
        })
        setReceived(dateFormattedMirInfo)
        const dateFormattedDCInfo = dcInfo.map((dcObject) => {
          const regex = /(?<date>\d{2})-(?<month>\d{2})-(?<year>\d{4})/
          const { groups: { date, month, year } } = dcObject.date.toString().match(regex)
          return Object.assign({}, { ...dcObject }, { date: format(new Date(`${year}-${month}-${date}`), 'do MMM yy') })
        })
        setTransferred(dateFormattedDCInfo)
      } catch (err) {
        setError(() => {
          const { data } = err.response
          const errorMessage = data.message || 'Oops! Something went wrong with your request!'
          throw Error(errorMessage)
        })
      }
    }
    fetchFabricLocationInfo(itemCode)
  }, [itemCode, token])

  useEffect(() => {
    const stockTotal = stock.reduce((acc, curr) => { return acc + curr.quantity }, 0)
    setStockTotal(stockTotal)

    const receivedTotal = received.reduce((acc, curr) => { return acc + curr.quantity }, 0)
    setReceivedTotal(receivedTotal)

    const adjustmentTotalIncoming = adjustment.reduce((acc, { quantity, adjustmentType }) => {
      if (adjustmentType === 'increase') {
        return acc + quantity
      }
      return acc
    }, 0)
    setAdjustmentTotalIncoming(adjustmentTotalIncoming)

    const adjustmentTotalOutgoing = adjustment.reduce((acc, { quantity, adjustmentType }) => {
      if (adjustmentType === 'decrease') {
        return acc + quantity
      }
      return acc
    }, 0)
    setAdjustmentTotalOutgoing(adjustmentTotalOutgoing)
    
  }, [stock, received, transferred, adjustment])

  if (isLoading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <div className='item-code-header'>
        <h3>Item Code: {itemCode}</h3>
      </div>
      <hr className='header-hr-border'></hr>
      <div className='ordered-received-stock-header'>
        <div className='ordered-received-stock-subheader'>
          <h3>{convertToLocaleString(roundNumberToThreeDecimals(ordered.quantityOrdered))}</h3>
          <p>Ordered</p>
        </div>
        <div className='ordered-received-stock-subheader'>
          <h3>{convertToLocaleString(roundNumberToThreeDecimals(receivedTotal))}</h3>
          <p>Received</p>
        </div>
        <div className='ordered-received-stock-subheader'>
          <h3>{convertToLocaleString(roundNumberToThreeDecimals(stockTotal))}</h3>
          <p>Stock</p>
        </div>
      </div>
      <hr className='header-hr-border'></hr>
      <AccordionButton className='accordion-trigger'
        id='received'
        aria-expanded={togglePanel.receivedVisibility ? true : false}
        onClick={handleAccordionClick}>
        <p>Received</p>
        <div className='accordion-dropdown-number'>
          <img src="/tick-mark.svg" alt='tick-mark' />
          <p style={{ color: '#19D268' }}>{convertToLocaleString(roundNumberToThreeDecimals(receivedTotal))}</p>
          <img src="/down-chevron.svg" alt='down-arrow' />
        </div>
      </AccordionButton>
      <Grid className='accordion-panel'
        columns={'25% 25% 25% 25%'}
        width={'65%'}
        textAlign='center'
        aria-labelledby='received'
        hidden={`${togglePanel.receivedVisibility ? '' : 'hidden'}`}>
        <div className='accordion-header'><p>Date</p></div>
        <div className='accordion-header'><p>MIR Number</p></div>
        <div className='accordion-header'><p>Location</p></div>
        <div className='accordion-header'><p>Quantity</p></div>
        {
          received.map(({ number, date, location, quantity }) => {
            return (
              <React.Fragment key={number}>
                <div className='accordion-item'><p>{date}</p></div>
                <div className='accordion-item'><p>{number}</p></div>
                <div className='accordion-item'><p>{location}</p></div>
                <div className='accordion-item'><p>{convertToLocaleString(roundNumberToThreeDecimals(quantity))}m</p></div>
              </React.Fragment>
            )
          })
        }
      </Grid>
      <AccordionButton className='accordion-trigger'
        id='transferred'
        aria-expanded={togglePanel.transferredVisibility ? true : false}
        onClick={handleAccordionClick}>
        <p>Transferred</p>
        <div className='accordion-dropdown-number'>
          <img src="/down-chevron.svg" alt='down-arrow' />
        </div>
      </AccordionButton>
      <Grid className='accordion-panel'
        columns={'25% 25% 25% 25%'}
        width={'65%'}
        textAlign='center'
        aria-labelledby='transferred'
        hidden={`${togglePanel.transferredVisibility ? '' : 'hidden'}`}>
        <div className='accordion-header'><p>Date</p></div>
        <div className='accordion-header'><p>From</p></div>
        <div className='accordion-header'><p>To</p></div>
        <div className='accordion-header'><p>Quantity</p></div>
        {
          transferred.map(({ date, fromLocation, toLocation, quantity }) => {
            return (
              <React.Fragment key={`${date}-${fromLocation}-${toLocation}-${quantity}`}>
                <div className='accordion-item'><p>{date}</p></div>
                <div className='accordion-item'><p>{fromLocation}</p></div>
                <div className='accordion-item'><p>{toLocation}</p></div>
                <div className='accordion-item'><p>{convertToLocaleString(roundNumberToThreeDecimals(quantity))}m</p></div>
              </React.Fragment>
            )
          })
        }
      </Grid>
      <AccordionButton className='accordion-trigger'
        id='stock'
        aria-expanded={togglePanel.stockVisibility ? true : false}
        onClick={handleAccordionClick}>
        <p>Stock</p>
        <div className='accordion-dropdown-number'>
          <p>{convertToLocaleString(roundNumberToThreeDecimals(stockTotal))}</p>
          <img src="/down-chevron.svg" alt='down-arrow' />
        </div>
      </AccordionButton>
      <Grid className='accordion-panel'
        columns={'50% 50%'}
        width={'65%'}
        textAlign='center'
        aria-labelledby='stock'
        hidden={`${togglePanel.stockVisibility ? '' : 'hidden'}`}>
        <div className='accordion-header'><p>Location</p></div>
        <div className='accordion-header'><p>Quantity</p></div>
        {
          stock.map(({ location, quantity }) => {
            return (
              <React.Fragment key={`${location}-${quantity}`}>
                <div className='accordion-item'><p>{location}</p></div>
                <div className='accordion-item'><p>{convertToLocaleString(roundNumberToThreeDecimals(quantity))}m</p></div>
              </React.Fragment>
            )
          })
        }
      </Grid>
      <AccordionButton className='accordion-trigger'
        id='adjustment'
        aria-expanded={togglePanel.adjustmentVisibility ? true : false}
        onClick={handleAccordionClick}>
        <p>Adjustment</p>
        <div className='accordion-dropdown-number'>
          <div className='accordion-adjustment-container'>
            <img src="/orange-down-arrow.svg" alt='adjustment-incoming-indicator' />
            <p style={{ color: '#FFAD33' }}>{convertToLocaleString(roundNumberToThreeDecimals(adjustmentTotalIncoming))}</p>
          </div>
          <hr className='adjustment-divider' />
          <div className='accordion-adjustment-container'>
            <img src="/red-up-arrow.svg" alt='adjustment-outgoing-indicator' />
            <p style={{ color: '#FF333E' }}>{convertToLocaleString(roundNumberToThreeDecimals(adjustmentTotalOutgoing))}</p>
          </div>
          <img src='/down-chevron.svg' alt='down-arrow' />
        </div>
      </AccordionButton>
      <Grid className='accordion-panel'
        columns={'33% 33% 33%'}
        width={'65%'}
        textAlign='center'
        aria-labelledby='adjustment'
        hidden={`${togglePanel.adjustmentVisibility ? '' : 'hidden'}`}>
        <div className='accordion-header'><p>Adjustment Number</p></div>
        <div className='accordion-header'><p>Item Code</p></div>
        <div className='accordion-header'><p>Quantity</p></div>
        {
          adjustment.map(({ itemCode, quantity, adjustmentType, documentNumber }) => {
            return (
              <React.Fragment key={`${itemCode}-${quantity}-${adjustmentType}`}>
                <div className='accordion-item'><p>{documentNumber}</p></div>
                <div className='accordion-item'><p>{itemCode}</p></div>
                <div className='accordion-item'>
                  <p style={{ color: `${adjustmentType === 'increase' ? 'green' : 'red'}` }}>
                    <span>{adjustmentType === 'increase' ? '+' : '-'}</span>{convertToLocaleString(roundNumberToThreeDecimals(quantity))}m
                  </p>
                </div>
              </React.Fragment>
            )
          })
        }
      </Grid>
    </React.Fragment>
  )
}

export default FabricLocationInfo