import React from 'react'

import { As, Button, ButtonProps, OmitCommonProps } from '@chakra-ui/react'

const BorderedButton = (props: JSX.IntrinsicAttributes & OmitCommonProps<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, keyof ButtonProps> & ButtonProps & OmitCommonProps<any, keyof ButtonProps> & { as?: As<any> | undefined }) => {
  return (
    <Button bg="#ffffff" color="brand.100" variant="outline" size="lg" {...props}>
      {props.children}
    </Button>
  )
}

export default BorderedButton