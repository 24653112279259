import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import './SearchBar.css'
import { Input } from '../UI/Components'
import SearchResults from './SearchResults'
import MinusingRegister from './MinusingRegister'
import FabricLocationInfo from './FabricLocationInfo'
import SearchForEnum from './SearchForEnum'

function SearchBar(props) {
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()

  const { searchFor, token } = props

  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermValid, setSearchTermValid] = useState(false)
  const [searchFieldTouched, setSearchFieldTouched] = useState(false)
  const [searchInputError, setSearchInputError] = useState(false)
  const [searchInputErrorMessage, setSearchInputErrorMessage] = useState('')

  // Defining regexes used for warehouse input
  const articleNumberRegex = /\d{3}.\d{3}.\d{2}$/
  const dateOfManufactureRegex = /\d{4}$/
  const rackNumberRegex = /[a-zA-Z]{1}\d{1}-\d{1,2}$/

  //  This effect is to reset the search field touched state variable
  //  every time the URL changes. This is done so that every time the 
  //  search term changes, the second effect runs because that depends
  //  on searchFieldTouched
  useEffect(() => {
    setSearchTermValid(false)
    setSearchFieldTouched(false)
  }, [location.search, location.pathname])

  useEffect(() => {
    //  do not run this effect if a search term has never been entered
    if (searchFieldTouched === false && searchTermValid === false) {
      return
    }

    //  do not run this effect if a search term entered is invalid
    if (searchFieldTouched === true && searchTermValid === false) {
      setSearchInputError(true)
      return
    }

    let params = new URLSearchParams()

    if (searchFor === SearchForEnum.FABRIC) {
      const poRegex = /(?<company>(ID|NJ)(?<PO>PO)(?<number>\d{7}))/i
      const ocRegex = /(?<company>(ID|NJ)(?<number>\d{5}))/i
      const bankOcRegex = /(?<company>(ID|NJ)(?<fabricAccount>([A-Za-z]{3}))(?<number>\d{2}))/i
      if (poRegex.test(searchTerm) === true) {
        params.append('poNum', searchTerm)
      } else if (ocRegex.test(searchTerm) === true || bankOcRegex.test(searchTerm) === true) {
        params.append('ocNum', searchTerm)
      } else {
        params.append('styleDescription', searchTerm)
      }
    }

    if (searchFor === SearchForEnum.CUTTING) {
      params.append('ocNum', searchTerm)
    }

    if (searchFor === SearchForEnum.WAREHOUSE) {
      if (articleNumberRegex.test(searchTerm) === true) {
        params.append('articleNumber', searchTerm)
      }

      if (dateOfManufactureRegex.test(searchTerm) === true) {
        params.append('dateOfManufacture', searchTerm)
      }

      if (rackNumberRegex.test(searchTerm) === true) {
        params.append('rackNumber', searchTerm)
      }
    }

    if (searchFor === SearchForEnum.SEWING) {
      params.append('location', searchTerm)
    }
    history.push({
      pathname: `${path}/results`,
      search: params.toString()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermValid, searchFieldTouched, history, searchTerm, path, searchFor])

  const checkSearchInput = () => {
    if (searchFor === SearchForEnum.FABRIC) {
      checkSearchInputFabric()
    } else if (searchFor === SearchForEnum.CUTTING) {
      checkSearchInputCutting()
    } else if (searchFor === SearchForEnum.WAREHOUSE) {
      checkSearchInputWarehouse()
    } else if (searchFor === SearchForEnum.SEWING) {
      checkSearchInputSewing()
    }
  }

  const checkSearchInputFabric = () => {
    const poRegex = /((?<company>(ID|NJ)?)(?<PO>PO)(?<number>\d*))/i
    const ocRegex = /((?<company>(ID|NJ))(?<number>\d*))/i
    const bankOcRegex = /(?<company>(ID|NJ)(?<fabricAccount>([A-Za-z]{3}))(?<number>\d*))/i

    setSearchFieldTouched(true)
    if (poRegex.test(searchTerm) === true) {
      const { groups: { number } } = searchTerm.match(poRegex)
      if (number.length !== 7) {
        setSearchTermValid(false)
        setSearchInputErrorMessage('PO numbers have the following format: IDPO2000524. You need to have 7 digits')
      } else {
        setSearchTermValid(true)
      }
      return
    } else if (ocRegex.test(searchTerm) === true || bankOcRegex.test(searchTerm) === true) {
      let groupObject = {}
      groupObject = searchTerm.match(ocRegex).groups
      if (bankOcRegex.test(searchTerm) === true) {
        groupObject = searchTerm.match(bankOcRegex).groups
      }
      const { number } = groupObject

      if (number.length === 5 || number.length === 2) {
        setSearchTermValid(true)
      } else {
        setSearchTermValid(false)
        setSearchInputErrorMessage('OC numbers have the following format: ID13759. You need to have 5 digits')
      }
      return
    }   // probably trying to search by style code.
    setSearchTermValid(true)
    return

  }

  const checkSearchInputCutting = () => {
    setSearchFieldTouched(true)
    setSearchTermValid(true)
  }

  const checkSearchInputSewing = () => {
    setSearchFieldTouched(true)
    setSearchTermValid(true)
  }

  const checkSearchInputWarehouse = () => {
    setSearchFieldTouched(true)

    if (articleNumberRegex.test(searchTerm) === true) {
      setSearchTermValid(true)
      return
    }

    if (dateOfManufactureRegex.test(searchTerm) === true) {
      setSearchTermValid(true)
      return
    }

    if (rackNumberRegex.test(searchTerm) === true) {
      setSearchTermValid(true)
      return
    }

    //  if the article number is not valid
    setSearchTermValid(false)
    setSearchInputErrorMessage('Please enter a valid article number, date of manufacturing or rack number')
  }

  const handleKeyUpEvent = (e) => {
    if (e.key === 'Enter') {
      checkSearchInput()
    }
  }

  let dynamicPath = null
  let dynamicComponent = null
  if (searchFor === SearchForEnum.FABRIC) {
    dynamicPath = `${path}/results/:itemCode`
    dynamicComponent = <FabricLocationInfo token={token} />
  } else if (searchFor === SearchForEnum.CUTTING) {
    dynamicPath = `${path}/results/oc/:ocNum/item/:itemCode/location/:location/fitType/:fitType`
    dynamicComponent = <MinusingRegister token={token} />
  }

  return (
    <React.Fragment>
      <div className='search-bar'>
        <Input placeholder='Please enter your search term'
          name='search-input'
          onChange={e => setSearchTerm(e.target.value)}
          onKeyUp={handleKeyUpEvent}
          isInvalid={searchInputError}
          border={'0.5px solid #334bff'}
          height={'auto'} />
        <button className='search-btn' onClick={checkSearchInput}>Search</button>
      </div>
      <label htmlFor='search-input'>
        <span className={`error ${searchInputError ? 'show' : 'hide'}`}>
          <p>{searchInputErrorMessage}</p>
        </span>
      </label>
      <Switch>
        <Route exact path={`${path}/results`}>
          <SearchResults searchFor={searchFor} token={token} />
        </Route>
        <Route exact path={dynamicPath}>
          {dynamicComponent}
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default SearchBar