import { ScannedCartonDetails } from "Rfid/types"
import { apiClient } from "Services/Networking/apiClient"


export const deleteCartonId = async (cartonId: string) => {
  const response = await apiClient({
    method: 'DELETE',
    url: `/fabship/product/rfid/carton`,
    params: { cartonId }

  })
  return response as unknown as Boolean
}