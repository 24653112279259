import { OcSamDetail } from 'Dashboard/types/OcSamDetail'

export type EfficiencyDataDTO = {
  ocNumber: string,
  batchNumber: string,
  location: string,
  numberOfMachines: number,
  numberOfHelpers: number,
  outputQuantity: number,
  sam: number,
  globalSam: number,
  sewingEfficiency: number,
  globalEfficiency: number,
  OCSamDetails: Array<OcSamDetail>
}

export enum SewingDashboardFilter {
  CHART = 'chart',
  TABLE = 'table'
}

export type DefectData = {
  defect: string,
  quantity: number
}

export type HourlyDefectData = {
  startTime: string,
  endTime: string,
  hourEfficiency: number,
  passedQuantity: number,
  rejectedQuantity: number,
  reworkedQuantity: number,
  defectDetails: DefectData[]
}

export type DHUDataDTO = {
  date: string,
  batchNumber: string,
  ocNumber: string,
  totalPassedQuantity: number,
  totalReworkedQuantity: number,
  totalRejectedQuantity: number,
  hourlyDetails: HourlyDefectData[]
}

export type HourlySewingData = {
  startTime: string,
  endTime: string,
  hourEfficiency: number,
  outputQuantity: number,
  sewingSamProduced: number,
  sewingMachineMinutes:number
}

export type SewingDataDTO = {
  date: string,
  batchNumber: string,
  efficiency: number,
  ocNumber: string,
  totalOutputQuantity: number,
  hourlyDetails: HourlySewingData[]
}

export type SingleDateSewingChartDTO = {
  ocNumber: string,
  batchNumber: string,
  efficiency: number,
  totalOutputQuantity: number,
  samProducedMinutes: number,
  machineMinutes: number
}