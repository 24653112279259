import { Box } from '@chakra-ui/react'
import { FabricDataDTO, WarehouseFabricOverallDataOrganized } from 'Dashboard/types'
import React, { useState, useEffect } from 'react'
import { Loader } from 'UI/Components'
import { getOverallWarehouseFabricInformation } from '../api/getWarehouseData'
import WarehouseDataTable from './WarehouseDataTable'

export const WarehouseOverallFabricResults = () => {

  const [_, setError] = useState<Error>(new Error())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<FabricDataDTO[]>([])

  // convert data array to array of (itemCode, noOfRolls)
  const organizeDataForViewing = (data:FabricDataDTO[]) => {
    // make an array of distinct item codes in our dataset 
    let result : string[] = data.map(a => a.itemCode)
    let unique : string[] = result.filter((value, index, self) => self.indexOf(value) === index)

    // declare an empty array which will contain unique item 
    // codes as well their respective numberOfRolls
    let fdata:WarehouseFabricOverallDataOrganized[] = []

    unique.forEach((itemCode) => {
      const count = data.reduce((acc, cur) => cur.itemCode === itemCode ? ++acc : acc, 0)
      fdata.push({ "itemCode": itemCode, "numberOfRolls": count })
    })

    return fdata
  }

  // calculate total number of rolls
  const calculateTotalNumberOfRolls = (data: WarehouseFabricOverallDataOrganized[]) => {
    return data.reduce((total, item) => item.numberOfRolls + total, 0)
  }

  // run on first render to fetch data from the api 
  useEffect(() => {
    const makeRequestToGetOverallWarehouseFabricData = async () => {
      setIsLoading(true)
      try {
        const response: FabricDataDTO[] = await getOverallWarehouseFabricInformation()
        setIsLoading(false)
        setData(response)
      } catch (err) {
        setIsLoading(false)
        setError(() => {
          throw err
        })
      }
    }

    makeRequestToGetOverallWarehouseFabricData()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  const tableData:WarehouseFabricOverallDataOrganized[] = organizeDataForViewing(data)

  const columns = [
    {
      Header: "",
      id: "row",
      Cell: (props: any) => {
        return <div style={{textAlign: 'right'}} >{props.row.index + 1}.</div>
      },
      disableSortBy: true,
      width: 30
    },
    {
      Header: "Item Code",
      accessor: "itemCode"
    },
    {
      Header: "Number Of Rolls",
      accessor: "numberOfRolls"
    }
  ]

  return (
    <>
      <Box alignSelf='flex-end' marginTop='5em' marginRight={'8em'} color='brand.100'>
        <span style={{ fontWeight: 'bold' }}>Total: </span>
        {calculateTotalNumberOfRolls(tableData)}
      </Box>
      <div style={{ width: '50%' }}>
        <WarehouseDataTable data={tableData} columns={columns} />
      </div>
    </>
  )
}

