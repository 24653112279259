import React, { useState, useEffect } from 'react'

import { SewingDataDTO } from 'Dashboard/types/sewing'
import { Box, Table, Th, Thead, Tr, Tbody, Td, Center } from '@chakra-ui/react'

type SewingDateRangeTableProps = {
  data: SewingDataDTO[]
}

type AggregateDataType = {
  [date: string]: {
    batchQuantities: {
      batchNumber: string,
      quantity: number,
      efficiency: number
    }[],
    hourlyDetails: {
      startTime: string,
      endTime: string,
      quantity: number,
      sewingSamProduced: number,
      sewingMachineMinutes: number
    }[]
  }
}

type GroupedDataType = {
  [date: string]: {
    batchQuantities: {
      batchNumber: string,
      quantity: number,
      efficiencies: number[]
    }[],
    hourlyDetails: {
      timeInterval: string,
      quantity: number,
      sewingSamProduced: number,
      sewingMachineMinutes: number
    }[]
  }
}

type GroupedDataWithAverageEfficiencyType = {
  [date: string]: {
    batchQuantities: {
      batchNumber: string,
      quantity: number,
      efficiency: number
    }[],
    hourlyDetails: {
      timeInterval: string,
      quantity: number,
      sewingSamProduced: number,
      sewingMachineMinutes: number
    }[]
  }
}

type TableDataTimeIntervalType = {
  [timeInterval: string]: number
}

type TableDataType = {
  [date: string]: {
    totalQuantity: number,
    efficiency: number,
    timeIntervalQuantities: TableDataTimeIntervalType
  }
}


const SewingDateRangeTable = (props: SewingDateRangeTableProps) => {
  const { data } = props

  const [tableData, setTableData] = useState<TableDataType>({})
  const [uniqueListOfTimeIntervals, setUniqueListOfTimeIntervals] = useState<string[]>()

  useEffect(() => {
    const uniqueTimeIntervals = getUniqueTimeIntervals(data)
    setUniqueListOfTimeIntervals(uniqueTimeIntervals)
    const aggregatedData = aggregateData(data)
    const groupedData = groupDataInAggregateObject(aggregatedData)
    const dataWithAverageEfficiency = calculateAverageBatchEfficiency(groupedData)
    const preparedTableData = prepareDataForDisplay(dataWithAverageEfficiency)
    setTableData(preparedTableData)
  }, [data])


  const getUniqueTimeIntervals = (data: SewingDataDTO[]) => {
    return data.reduce((acc: string[], curr) => {
      const { hourlyDetails } = curr
      hourlyDetails.map((hourlyDetail) => {
        const { startTime, endTime } = hourlyDetail
        const timeIntervalString = `${startTime}-${endTime}`
        if (acc.includes(timeIntervalString) === false) {
          acc.push(timeIntervalString)
        }
        return null
      })
      return acc
    }, [])
  }

  const aggregateData = (data: SewingDataDTO[]) => {
    const aggregatedData: AggregateDataType = {}
    data.map(({ date, batchNumber, hourlyDetails, efficiency, totalOutputQuantity }) => {
      if (aggregatedData.hasOwnProperty(date) === false) {
        aggregatedData[date] = {
          batchQuantities: [{ batchNumber, quantity: totalOutputQuantity, efficiency }],
          hourlyDetails: []
        }
      } else {
        aggregatedData[date] = Object.assign(
          {},
          { ...aggregatedData[date] },
          {
            batchQuantities: [...aggregatedData[date].batchQuantities, { batchNumber, quantity: totalOutputQuantity, efficiency }]
          }
        )
      }

      hourlyDetails.map(({ startTime, endTime, outputQuantity, sewingSamProduced, sewingMachineMinutes }) => {
        if (aggregatedData.hasOwnProperty(date)) {
          aggregatedData[date] = Object.assign(
            {},
            { ...aggregatedData[date] },
            {
              hourlyDetails: [...aggregatedData[date].hourlyDetails, { startTime, endTime, quantity: outputQuantity, sewingSamProduced, sewingMachineMinutes }]
            }
          )
          return null
        }
        return null
      })
    })
    return aggregatedData
  }

  const groupDataInAggregateObject = (aggregatedData: AggregateDataType) => {

    const updatedObject: GroupedDataType = {}
    for (const [date] of Object.entries(aggregatedData)) {
     
      const presentDateTime = new Date(new Date().toLocaleString('en-Us', { hour12: false, timeZone: 'Asia/Kolkata' }))
      const workPresentDateTimeToCalculate = new Date(Date.UTC(presentDateTime.getFullYear(), presentDateTime.getMonth(), presentDateTime.getDate(), presentDateTime.getHours(), presentDateTime.getMinutes(), 0)).toISOString()
      const newDate = new Date(date)

      updatedObject[date] = Object.assign(
        {},
        { ...aggregatedData[date] },
        {
          batchQuantities: aggregatedData[date].batchQuantities.reduce((acc: { batchNumber: string, quantity: number, efficiencies: number[] }[], { batchNumber, quantity, efficiency }) => {
            const batchNumberIndex = acc.findIndex((item: any) => item.batchNumber === batchNumber)
            if (batchNumberIndex === -1) {
              acc.push({
                batchNumber,
                quantity,
                efficiencies: [efficiency]
              })
              return acc
            }
            const currentBatchQuantity = acc[batchNumberIndex].quantity
            const currentBatchEfficiency = acc[batchNumberIndex].efficiencies
            acc[batchNumberIndex] = Object.assign(
              {},
              { ...acc[batchNumberIndex] },
              {
                quantity: currentBatchQuantity + quantity,
                efficiencies: [...currentBatchEfficiency, efficiency]
              }
            )
            return acc
          }, [])
        },
        {
          hourlyDetails: aggregatedData[date].hourlyDetails.reduce((acc: { timeInterval: string, quantity: number, sewingSamProduced: number, sewingMachineMinutes: number }[], { startTime, endTime, quantity, sewingSamProduced, sewingMachineMinutes }) => {
            const workEndTimeSplit = endTime.split(':')
            const workEndDateTimeToCalculate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), workEndTimeSplit[0] as unknown as number, workEndTimeSplit[1] as unknown as number, 0)).toISOString()

            let actualMachineMinutes = 0
            let actualSamProducedMinutes = 0
            if (sewingSamProduced > 0 || workEndDateTimeToCalculate <= workPresentDateTimeToCalculate){
              actualMachineMinutes = sewingMachineMinutes
              actualSamProducedMinutes = sewingSamProduced
            }

            const timeInterval = `${startTime}-${endTime}`
            const timeIntervalIndex = acc.findIndex((item: any) => item.timeInterval === timeInterval)
            if (timeIntervalIndex === -1) {
              acc.push({
                timeInterval,
                quantity,
                sewingSamProduced: actualSamProducedMinutes,
                sewingMachineMinutes: actualMachineMinutes
              })
              return acc
            }
            const currentTimeIntervalQuantity = acc[timeIntervalIndex].quantity
            const currentTimeIntervalSamProduced = acc[timeIntervalIndex].sewingSamProduced
            const currentTimeIntervalMachineMinutes = acc[timeIntervalIndex].sewingMachineMinutes
            acc[timeIntervalIndex] = Object.assign(
              {},
              { ...acc[timeIntervalIndex] },
              {
                quantity: currentTimeIntervalQuantity + quantity,
                sewingSamProduced: currentTimeIntervalSamProduced + actualSamProducedMinutes,
                sewingMachineMinutes: currentTimeIntervalMachineMinutes + actualMachineMinutes
              }
            )
            return acc
          }, [])
        }
      )
    }
    return updatedObject
  }

  const calculateAverageBatchEfficiency = (aggregatedData: GroupedDataType) => {
    const updatedObject: GroupedDataWithAverageEfficiencyType = {}
    for (const [date] of Object.entries(aggregatedData)) {
      updatedObject[date] = Object.assign(
        {},
        { ...aggregatedData[date] },
        {
          batchQuantities: aggregatedData[date].batchQuantities.map(({ batchNumber, quantity, efficiencies }) => {
            const averageEfficiency : number = efficiencies.reduce((acc, curr) => acc + curr, 0) / efficiencies.length
            return {
              batchNumber,
              quantity,
              efficiency: averageEfficiency
            }
          })
        }
      )
    }
    return updatedObject
  }

  const roundNumberToSignificantDigits = (number: number, numberOfDigits: number) => {
    return Math.round(number * Math.pow(10, numberOfDigits)) / Math.pow(10, numberOfDigits)
  }

  const prepareDataForDisplay = (data: GroupedDataWithAverageEfficiencyType) => {
    const updatedObject: TableDataType = {}
    for (const [date, { batchQuantities, hourlyDetails }] of Object.entries(data)) {
      
      const overAllSamProduced = hourlyDetails.reduce((acc, curr) => {
        const { sewingSamProduced } = curr
        return acc + sewingSamProduced
      }, 0)

      const overAllMachineMinutes = hourlyDetails.reduce((acc, curr) => {
        const { sewingMachineMinutes } = curr
        return acc + sewingMachineMinutes
      }, 0)

      let averageEfficiency = 0
      if (overAllSamProduced > 0){
        averageEfficiency = roundNumberToSignificantDigits(((overAllSamProduced / overAllMachineMinutes) * 100), 2)
      }

      
      updatedObject[date] = Object.assign(
        {},
        {
          totalQuantity: batchQuantities.reduce((acc, curr) => acc + curr.quantity, 0),
          efficiency: averageEfficiency,
          timeIntervalQuantities: hourlyDetails.reduce((acc: { [timeInterval: string]: number }, curr) => {
            const { timeInterval, quantity } = curr
            return Object.assign(
              {},
              { ...acc },
              { [timeInterval]: quantity }
            )
          }, {})
        }
      )
    }
    return updatedObject
  }

  if (data.length === 0) {
    return (
      <div className="sewing-dashboard-error">
        No results found
      </div>
    )
  }


  return (
    <div>
      <h2><Center color={'brand.100'} fontWeight={'bold'}>Date Range Efficiencies</Center></h2>
      <div>
        <Box overflowX={'auto'} maxWidth='80vw' marginLeft={'3%'}>
          <Table>
            <Thead>
              <Tr>
                <Th color={'brand.100'}><Center>Date</Center></Th>
                {
                  uniqueListOfTimeIntervals?.map((timeInterval) => {
                    return <Th whiteSpace={'nowrap'} color={'brand.100'}>{timeInterval}</Th>
                  })
                }
                <Th color={'brand.100'}>Total</Th>
                <Th color={'brand.100'}>Efficiency</Th>
              </Tr>
            </Thead>
            <Tbody color='brand.100'>
              {
                Object.keys(tableData).map((date) => {
                  const { totalQuantity, efficiency, timeIntervalQuantities } = tableData[date]
                  return (
                    <Tr>
                      <Td whiteSpace={'nowrap'}>{date}</Td>
                      {
                        uniqueListOfTimeIntervals?.map((timeInterval) => {
                          return <Td><Center>{timeIntervalQuantities[timeInterval] || 0}</Center></Td>
                        })
                      }
                      <Td>{totalQuantity}</Td>
                      <Td>{efficiency}%</Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Box>
      </div>
    </div>
  )
}

export default SewingDateRangeTable