import React, { useState, useEffect } from 'react'
import { LineChart, XAxis, YAxis, Line, Tooltip, ResponsiveContainer } from 'recharts'

import { SewingDataDTO, HourlySewingData } from 'Dashboard/types/sewing'
import { Box, Center } from '@chakra-ui/react'

type SewingDateRangeProps = {
  data: SewingDataDTO[]
}

type AggregatedDataType = {
  date: string,
  efficiencies: number[],
  totalQuantities: number[],
  hourlyDetails: HourlySewingData[]
}

type ChartDataType = {
  date: string,
  efficiency: number,
  quantity: number
}

const SewingDateRangeChart = (props: SewingDateRangeProps) => {
  const { data } = props

  const [chartData, setChartData] = useState<ChartDataType[]>([])

  useEffect(() => {
    const aggregatedData = aggregateData(data)
    const averageEfficiencyData = calculateAverageEfficiencyAndTotalQuantityForDate(aggregatedData)
    setChartData(averageEfficiencyData)
  }, [data])

  const aggregateData = (data: SewingDataDTO[]): AggregatedDataType[] => {
    const aggregatedData: AggregatedDataType[] = []
    data.map(({ date, efficiency, totalOutputQuantity, hourlyDetails }) => {
      const formattedDataDateIndex = aggregatedData.findIndex((item) => item.date === date)
      if (formattedDataDateIndex === -1) {
        aggregatedData.push({
          date,
          efficiencies: [efficiency],
          totalQuantities: [totalOutputQuantity],
          hourlyDetails: [...hourlyDetails]
        })
        return null
      }

      const efficienciesArray = aggregatedData[formattedDataDateIndex].efficiencies
      const totalQuantitiesArray = aggregatedData[formattedDataDateIndex].totalQuantities
      const hourlyDetailsList = aggregatedData[formattedDataDateIndex].hourlyDetails
      aggregatedData[formattedDataDateIndex] = Object.assign(
        {},
        { ...aggregatedData[formattedDataDateIndex] },
        {
          efficiencies: [...efficienciesArray, efficiency],
          totalQuantities: [...totalQuantitiesArray, totalOutputQuantity],
          hourlyDetails: [...hourlyDetailsList, ...hourlyDetails]
        }
      )
    })
    return aggregatedData
  }

  const roundNumberToSignificantDigits = (number: number, numberOfDigits: number) => {
    return Math.round(number * Math.pow(10, numberOfDigits)) / Math.pow(10, numberOfDigits)
  }

  const calculateAverageEfficiencyAndTotalQuantityForDate = (aggregatedData: AggregatedDataType[]) => {
    const presentDateTime = new Date(new Date().toLocaleString('en-Us', { hour12: false, timeZone: 'Asia/Kolkata' }))
    const workPresentDateTimeToCalculate = new Date(Date.UTC(presentDateTime.getFullYear(), presentDateTime.getMonth(), presentDateTime.getDate(), presentDateTime.getHours(), presentDateTime.getMinutes(), 0)).toISOString()
    
    const chartData = aggregatedData.map(({ date, totalQuantities, hourlyDetails }) => {
      const newDate = new Date(date)

      const overAllSamProduced = hourlyDetails.reduce((acc, curr) => {
        const { sewingSamProduced } = curr
        return acc + sewingSamProduced
      }, 0)

      const overAllMachineMinutes = hourlyDetails.reduce((acc, curr) => {
        const { sewingMachineMinutes, sewingSamProduced, endTime } = curr
        const workEndTimeSplit = endTime.split(':')
        const workEndDateTimeToCalculate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), workEndTimeSplit[0] as unknown as number, workEndTimeSplit[1] as unknown as number, 0)).toISOString()
       
        let actualMachineMinutes = 0
        if (sewingSamProduced > 0 || workEndDateTimeToCalculate <= workPresentDateTimeToCalculate){
          actualMachineMinutes = sewingMachineMinutes
        }

        return acc + actualMachineMinutes
      }, 0)
      
      let averageEfficiency = 0
      if (overAllSamProduced > 0){
        averageEfficiency = roundNumberToSignificantDigits(((overAllSamProduced / overAllMachineMinutes) * 100), 2)
      }

      const totalQuantity = totalQuantities.reduce((acc, curr) => { return acc + curr }, 0) / totalQuantities.length
      return {
        date,
        efficiency: averageEfficiency,
        quantity: Math.round(totalQuantity * 1e2) / 1e2
      }
    })
    return chartData
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      const activeDateIndex = chartData.findIndex((item) => item.date === label)
      if (activeDateIndex === -1) {
        return null
      }
      const { date, efficiency, quantity } = chartData[activeDateIndex]
      return (
        <Box backgroundColor={'white'} border={'1px solid #334BFF'} whiteSpace={'nowrap'} padding={'0.5em'} color={'brand.100'}>
          <p>Date: {date}</p>
          <p>Efficiency: {efficiency}</p>
          <p>Quantity: {quantity}</p>
        </Box>
      )
    }
    return null
  }

  if (data.length === 0) {
    return (
      <div className="sewing-dashboard-error">
        No results found
      </div>
    )
  }


  return (
    <div style={{ marginTop: '3%' }}>
      <Center><div className='sewing-date-range-chart-header' style={{ fontWeight: 'bold', color: '#334BFF' }}>Location Efficiency Chart</div></Center>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "80vh", margin: "0 0 0 7%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={400}
            height={800}
            data={chartData}
            margin={{
              top: 5,
              right: 110,
              left: 0,
              bottom: 5,
            }}
          >
            <XAxis stroke='#335BFF' dataKey="date" />
            <YAxis stroke='#334BFF' />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="efficiency" stroke="#334BFF" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default SewingDateRangeChart