import React, { ChangeEvent } from 'react'
import { Box } from '@chakra-ui/layout'

import SearchInput from 'UI/Components/SearchInput'
import SearchButton from 'UI/Components/Button/SearchButton'
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'

const SearchBarContainer = (props: { children: React.ReactFragment }) => {
  return (
    <Box display='flex' flexDirection='row' width='75%'>
      {props.children}
    </Box>
  )
}

const SearchBar = (props: { value: string, error: string, isInvalid: boolean, handleSubmit: Function, onChange: Function }) => {
  return (
    <SearchBarContainer>
      <FormControl id="search-input" isInvalid={props.isInvalid}>
        <SearchInput value={props.value} onChange={(event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)} />
        <FormErrorMessage justifyContent='center' >{props.error}</FormErrorMessage>
      </FormControl>
      <SearchButton marginLeft='1em' onSubmit={() => props.handleSubmit()} onClick={() => props.handleSubmit()} />
    </SearchBarContainer>
  )
}

export default SearchBar