import React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    brand: {
      100: '#334bff'
    }
  },
  fonts: {
    heading: "Poppins",
    body: "Poppins"
  }
})

const StyleProvider = (props: { children: React.ReactChild | React.ReactFragment }) => {
  return (
    <ChakraProvider theme={theme}>
      {props.children}
    </ChakraProvider>
  )
}

export default StyleProvider