import React from 'react'
import styled from 'styled-components'

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns};
  grid-gap: ${props => props.gap};
  row-gap: ${props => props.rowGap};
  width: ${props => props.width ? props.width : '100%'};
  text-align: ${props => props.textAlign || 'center'};
`

const GridComponent = (props) => {
  return (
    <Grid {...props}>
      {props.children}
    </Grid>
  )
}

export default GridComponent