import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Grid, Loader } from '../UI/Components'
import { networkRequest } from '../Services/NetworkRequest'

import './MinusingRegister.css'

function MinusingRegister(props) {
  const { ocNum, itemCode, location, fitType } = useParams()

  const { token } = props

  const [isLoading, setIsLoading] = useState(false)

  //  Data returned from the server
  const [itemDescription, setItemDescription] = useState('')
  const [styleNumber, setStyleNumber] = useState('')
  const [jobs, setJobs] = useState([])
  const [sizeQuantities, setSizeQuantities] = useState([])
  const [customerName, setCustomerName] = useState('')
  const [approvedConsumption, setApprovedConsumption] = useState(0)

  //  Data computed locally
  const [groupedJobs, setGroupedJobs] = useState([])
  const [remainingQuantities, setRemainingQuantities] = useState([])
  const [totalRemainingQuantity, setTotalRemainingQuantity] = useState(0)
  const [totalCutQuantityBySize, setTotalCutQuantityBySize] = useState([])
  const [pipingJobs, setPipingJobs] = useState([])
  const [_, setError] = useState('')  //  eslint-disable-line no-unused-vars

  // data for setting table
  const [columnWidth, setColumnWidth] = useState('')
  const [fixedColumns, setFixedColumns] = useState(6)

  useEffect(() => {
    const getRegisterDetails = async (URL) => {
      try {
        const paramsObject = { location: location, fitType: fitType }
        setIsLoading(true)
        const {
          itemDescription,
          styleNumber,
          customerName,
          jobs,
          sizeQuantities,
          approvedConsumption
        } = await networkRequest('GET', URL, paramsObject, token)
        setApprovedConsumption(approvedConsumption)
        setCustomerName(customerName)
        setItemDescription(itemDescription)
        setStyleNumber(styleNumber)
        setJobs(jobs)
        setSizeQuantities(sizeQuantities)
        const groupedJobs = (pipe(groupJobsByUniqueId, getTotalPerJob, getConsumptionPerJob)(jobs))
        setGroupedJobs(groupedJobs)
        setJobs(reducePipingJobsToGetCutQuantity (approvedConsumption, groupedJobs, jobs)) //overWrite jobs by removing piping jobs from array list if any
        const { clonedActualGroupedJobs, actualPipingJobs } = segregatePipingFromGroupedJobs(approvedConsumption, groupedJobs)
        setGroupedJobs(clonedActualGroupedJobs) //overWrite GroupedJobs by removing piping jobs from object if any
        setPipingJobs(actualPipingJobs) //Set piping jobs
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        setError(() => {
          const { data } = err.response
          const isMessagePropertyExist = data.hasOwnProperty('message')
          if (isMessagePropertyExist){
            const errorMessage = data.message
            throw Error(errorMessage)
          }
        })
      }
    }
    getRegisterDetails(`/analytics/cutting-register/oc/${ocNum}/item/${itemCode}`)
    handleWindowResize()
  }, [itemCode, location, fitType])  //  eslint-disable-line react-hooks/exhaustive-deps
  /**
   * This effect will run when the first effect has updated the state after making a network call
   */
  useEffect(() => {
    setRemainingQuantities(getRemainingQuantities(sizeQuantities, groupedJobs))
    setTotalRemainingQuantity(getTotalRemainingQuantity(sizeQuantities, groupedJobs))
    setTotalCutQuantityBySize(getTotalCutQuantityBySize(jobs))
    setColumnWidth(getGridColumnWidth(fixedColumns, sizeQuantities))
  }, [sizeQuantities, groupedJobs, jobs, fixedColumns])

  /**
   * This effect is used exclusively to handle window resize
   */
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return _ => {
      // cleanup listener
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  /**
   * Function handler to handle window resize - shows table number column only on desktop
   */
  const handleWindowResize = () => {
    if (window.innerWidth <= 900) {
      setFixedColumns(5)
    } else {
      setFixedColumns(6)
    }
  }

  /**
   * Utility function to convert a number to the locale string version
   * @param {Number} number 
   */
  const convertToLocaleString = (number) => {
    return number.toLocaleString('en-IN')
  }


  /**
   * Function to seperate ikea piping jobs and regular jobs From groupJobs
   * @param {number} approvedConsumption
   * @return {object}
   */
  const segregatePipingFromGroupedJobs = (approvedConsumption, groupedJobs) => { 
    const consumptionToValidatePiping = approvedConsumption / 2
    const cloneGroupedJobs = {...groupedJobs}
    const pipingDataObject = {}
    Object.entries(cloneGroupedJobs).map(([key, obj]) => {
      const { consumption } = obj
      if (consumption <= consumptionToValidatePiping){
        pipingDataObject[key] = obj
        delete cloneGroupedJobs[key]
      }
      return null
    })
    return {clonedActualGroupedJobs: cloneGroupedJobs, actualPipingJobs: pipingDataObject}
  }

  /**
   * Function to seperate ikea piping jobs to get total quantity
   * @param {number} approvedConsumption
   * @return {Array}
   */
  const reducePipingJobsToGetCutQuantity = (approvedConsumption, groupedJobs, jobs) => { 
    const consumptionToValidatePiping = approvedConsumption / 2

    //From below function get list of actual job unique list and ignore piping jobs unique from groupedJobs
    const actualJobsKeysListFromGroupedJobs =  Object.entries(groupedJobs).map(([key, obj]) => {
      const { consumption } = obj
      if (consumption <= consumptionToValidatePiping) {return key}
      return null
    }).filter((element) =>{return element != null})
   
    //From below function get array object [{}] of actual jobs from jobs list
    return jobs.map(jobObject => {
      const { unique } = jobObject
      if (!actualJobsKeysListFromGroupedJobs.includes(unique.toString())) {return jobObject}
      return null
    }).filter(jobObject => {return jobObject != null})
    
  }

  /**
   * Function that will take an array of objects which is a set of jobs
   * and group them based on the unique id of each job. All the jobs with the same
   * id will be put into one group
   * @param {Array} jobs
   * @return {Object}
   */
  const groupJobsByUniqueId = (jobs) => {
    return jobs.reduce((acc, { date, tableNum, layLength, size, quantity, unique, numOfPiecesPerPly, layNumber }) => {
      if (!acc[unique]) {
        return Object.assign({}, { ...acc }, { [unique]: { date, tableNum, layLength, sizeQuantities: [{ size, quantity }], numOfPiecesPerPly } })
      }
      return Object.assign(
        {},
        { ...acc },
        {
          [unique]:
          {
            date,
            tableNum,
            layLength,
            sizeQuantities: [...acc[unique].sizeQuantities || [], { size, quantity }],
            numOfPiecesPerPly,
            layNumber
          }
        })
    }, {})
  }

  /**
   * Function to calculate the total quantity cut per job
   * @param {Object} groupedJobs
   * @return {Object}
   */
  const getTotalPerJob = (groupedJobs) => {
    let updatedObject = {}
    Object.keys(groupedJobs).map(unique => {
      const { sizeQuantities } = groupedJobs[unique]
      const total = sizeQuantities.reduce((acc, { quantity }) => acc + quantity, 0)
      updatedObject = Object.assign(
        {},
        { ...updatedObject },
        { [unique]: { ...groupedJobs[unique], total } }
      )
      return unique
    })
    return updatedObject
  }

  /**
   * Function to calculate the consumption per job
   * @param {Object} groupedJobs
   * @return {Object}
   */
  const getConsumptionPerJob = (groupedJobs) => {
    let updatedObject = {}
    Object.keys(groupedJobs).map(unique => {
      const { numOfPiecesPerPly, total, layLength } = groupedJobs[unique]
      const numOfPlies = total / numOfPiecesPerPly
      const consumption = (numOfPlies * layLength) / total
      updatedObject = Object.assign({}, { ...updatedObject }, { [unique]: { ...groupedJobs[unique], consumption } })
      return unique
    })
    return updatedObject
  }

  /**
   * Function that will compute the remaining quantity for each size
   * @param {Array} sizeQuantitiesTotal 
   * @param {Object} groupedJobs 
   * @return {Object}
   */
  const getRemainingQuantities = (sizeQuantitiesTotal, groupedJobs) => {
    const remainingQuantities = [...sizeQuantitiesTotal]
    Object.entries(groupedJobs).map(([key, { sizeQuantities }]) => {
      //  iterate over sizeQuantities and find the matching key in sizeQuantitiesTotal and subtract values
      sizeQuantities.map(({ size, quantity }) => {
        let value = remainingQuantities.find(obj => obj.size === size)
        let index = remainingQuantities.map(el => el.size).indexOf(size)
        remainingQuantities[index] = Object.assign({}, { ...remainingQuantities[index] }, { quantity: value.quantity - quantity })
        return { size, quantity }
      })
      return null
    })
    return remainingQuantities
  }

  /**
   * Function that will compute the total quantity left for a specific item code
   * It will add up the total of all the different grouped jobs and subtract that from
   * the total of sizeQuantities
   * @param {Array} sizeQuantities 
   * @param {Object} groupedJobs 
   * @return {Number}
   */
  const getTotalRemainingQuantity = (sizeQuantities, groupedJobs) => {
    const sizeQuantitiesTotal = sizeQuantities.reduce((acc, curr) => { return acc + curr.quantity }, 0)
    const groupedJobsTotal = Object.entries(groupedJobs).reduce((acc, [key, { total }]) => { return acc + total }, 0)
    return (sizeQuantitiesTotal - groupedJobsTotal)
  }

  /**
   * Function that will run through the array of jobs and compute the total quantity
   * cut per size
   * @param {Object[]} jobs 
   */
  const getTotalCutQuantityBySize = (jobs) => {
    return jobs.reduce((acc, { size, quantity }) => {
      const sizeQuantityObject = acc.filter(object => object.size === size)
      if (sizeQuantityObject.length === 0) {
        acc.push({ size, quantity })
        return acc
      }
      return acc.map(object => {
        if (object.size === size) {
          return Object.assign({}, { size, quantity: object.quantity + quantity })
        }
        return object
      })
    }, [])
  }

  /**
   * Function to compute the overall cut quantity for this job
   * @param {Object[]} totalCutQuantityBySize 
   */
  const getTotalCutQuantity = (totalCutQuantityBySize) => {
    return totalCutQuantityBySize.reduce((acc, { quantity }) => {
      return acc + quantity
    }, 0)
  }

  /**
   * Function to calculate the total consumption for all jobs in one OC
   * @param {Object} groupedJobs 
   */
  const getTotalConsumption = (groupedJobs) => {
    let overallTotal = 0
    let overallFabricUtilized = 0
    Object.entries(groupedJobs).map(([unique, { numOfPiecesPerPly, total, layLength }]) => {
      const numOfPlies = total / numOfPiecesPerPly
      overallTotal += total
      overallFabricUtilized += numOfPlies * layLength
      return null
    })
    return (overallFabricUtilized / overallTotal)
  }

  /**
   * This function will compute the grid-template-columns string property required by
   * the Grid component.
   * Since the grid-template-columns property requires something of the form
   * '25% 25% 25% 25%', it is a little tricky to compute
   * @param {Array} sizeQtys
   */
  const getGridColumnWidth = (fixedColumns, sizeQtys) => {
    let numOfCols = fixedColumns + sizeQtys.length
    let colWidth = 100 / numOfCols
    let colWidthString = Array(numOfCols).fill(0).map(el => `${colWidth}%`).join(' ')
    return colWidthString
  }

  /**
   * This function will round the received number to three significant digits
   * @function
   * @param {Number} number
   * @return {Number}
   */
  const roundSignificantDigits = (number) => {
    return Math.round(number * 1e3) / 1e3
  }

  // let columnWidths = getGridColumnWidth(sizeQuantities)

  if (isLoading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <div className='minusing-register-header-block'>
        <p>Item Code: {itemCode}</p>
        <p>Item Description: {itemDescription}</p>
        <p>Buyer Name: {customerName}</p>
        <p>Style Number: {styleNumber}</p>
        <p>Fit Type: {fitType}</p>
        <p>Approved Consumption: {approvedConsumption}</p>
      </div>
      <div className='minusing-register-container'>
        <Grid columns={columnWidth}>
          {/** Start header row */}
          <div className='minusing-register-header'><h3>Cut No.</h3></div>
          <div className='minusing-register-header'><h3>Date</h3></div>
          <div className='minusing-register-header table-number'><h3>Table</h3></div>
          <div className='minusing-register-header'><h3>Lay Length</h3></div>
          {
            sizeQuantities.map(({ size }) => {
              return <div className='minusing-register-header' key={size}><h3>{size}</h3></div>
            })
          }
          <div className='minusing-register-header'><h3>Total</h3></div>
          <div className='minusing-register-header'><h3>Cons.</h3></div>
          {/** End header row */}

          {/** Start total quantity to be cut row */}
          <div></div>
          <div></div>
          <div></div>
          <div className='table-number'></div>
          {
            sizeQuantities.map(({ quantity }, index) => {
              return <div className='minusing-register-row first-row' key={`${quantity}+${index}`}><p>{convertToLocaleString(quantity)}</p></div>
            })
          }
          <div className='minusing-register-row first-row'><p>{convertToLocaleString(sizeQuantities.reduce((acc, curr) => { return acc + curr.quantity }, 0))}</p></div>
          <div></div>
          {/** End total quantity to be cut row */}

          {/** Start remaining rows */}
          {
            
            Object.entries(groupedJobs).map(([key, { date, tableNum, layLength, layNumber, sizeQuantities, total, consumption }], index) => {
              return (
                <React.Fragment key={`${date}+${total}+${layLength}+${index}`}>
                  <div className='minusing-register-row'><p>{layNumber}</p></div>
                  <div className='minusing-register-row'><p>{date}</p></div>
                  <div className='minusing-register-row table-number'><p>{tableNum}</p></div>
                  <div className='minusing-register-row'><p>{layLength}m</p></div>
                  {
                    sizeQuantities.map(({ size, quantity }, index) => {
                      return <div className='minusing-register-row minusing-quantity' key={`${size}+${quantity}+${date}+${layLength}+${index}`}>
                        <p>{convertToLocaleString(quantity)}</p>
                      </div>
                    })
                  }
                  <div className='minusing-register-row minusing-quantity'><p>{convertToLocaleString(total)}</p></div>
                  <div><p>{roundSignificantDigits(consumption)}m</p></div>
                </React.Fragment>
              )
            })
          }
          {/** End remaining rows */}
          {/** Start the total cut quantity row */}
          <div></div>
          <div></div>
          <div></div>
          <div className='table-number'></div>
          {
            totalCutQuantityBySize.map(({ size, quantity }, index) => {
              return (
                <div key={`${size}+${quantity}+${index}`} className='minusing-register-row size-total-cut'><p>{convertToLocaleString(quantity)}</p></div>
              )
            })
          }
          <div className='minusing-register-row size-total-cut'>
            <p>{convertToLocaleString(getTotalCutQuantity(totalCutQuantityBySize))}</p>
          </div>
        </Grid>
        <hr style={{ border: '1px solid gainsboro', width: '98%' }} />
        <Grid columns={columnWidth}>
          {/** Show remaining quantity row */}
          <div></div>
          <div></div>
          <div></div>
          <div className='table-number'></div>
          {
            remainingQuantities.map(({ quantity }, index) => {
              return <div key={index} className='minusing-register-remaining-row'><p>{convertToLocaleString(quantity)}</p></div>
            })
          }
          <div className='minusing-register-remaining-row'><p>{convertToLocaleString(totalRemainingQuantity)}</p></div>
          <div>
            <p>{convertToLocaleString(roundSignificantDigits(getTotalConsumption(groupedJobs)))}m</p>
          </div>
        </Grid>
        <hr style={{ border: '1px solid gainsboro', width: '98%' }} />
        <Grid columns={columnWidth}>
          {/** Start ikea Pipeing rows */}
          {
            Object.entries(pipingJobs).map(([key, { date, tableNum, layLength, sizeQuantities, total, consumption }], index) => {
              return (
                <React.Fragment key={`${date}+${total}+${layLength}+${index}`}>
                  <div className='minusing-register-row'><p>{'piping'}</p></div>
                  <div className='minusing-register-row'><p>{date}</p></div>
                  <div className='minusing-register-row table-number'><p>{tableNum}</p></div>
                  <div className='minusing-register-row'><p>{layLength}m</p></div>
                  {
                    sizeQuantities.map(({ size, quantity }, index) => {
                      return <div className='minusing-register-row minusing-quantity' key={`${size}+${quantity}+${date}+${layLength}+${index}`}>
                        <p>{convertToLocaleString(quantity)}</p>
                      </div>
                    })
                  }
                  <div className='minusing-register-row minusing-quantity'><p>{convertToLocaleString(total)}</p></div>
                  <div><p>{roundSignificantDigits(consumption)}m</p></div>
                </React.Fragment>
              )
            })
          }
          {/** End Pipeing rows */}
        </Grid>
      </div>
    </React.Fragment>
  )
}

const pipe = (...fns) => (result) => {
  const list = [...fns]

  
  while (list.length > 0) {
    result = list.shift()(result)
  }
  return result
}

export default MinusingRegister