import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '100%'};
  background-color: #334bff;
  color: #ffffff;

  :hover {
    cursor: pointer;
  }
`

const ButtonComponent = (props) => {
  return (
    <Button {...props}>
      {props.children}
    </Button>
  )
}

export default ButtonComponent