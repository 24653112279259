import React from 'react'

import BarcodeFormContainer from './BarcodeFormContainer'
import BarcodeTableContainer from './BarcodeTableContainer'
import DisplayBarcodes from './DisplayBarcodes'
import DisplayBarcodesErrorBoundary from './DisplayBarcodesErrorBoundary'

/**
 * This class is used as a wrapper class to decide which component to render
 * based on user interactions
 */
class GenerateBarcodes extends React.Component {
  constructor(props) {
    super(props)
    this.initialState = {
      PO: '',
      currentlySelectedItem: '',
      currQty: 0.0,
      ordQty: 0.0,
      formSubmitted: false,
      tableData: [],
      tableSubmitted: false
    }
    this.state = this.initialState
  }
	
  render () {
    const { formSubmitted, tableSubmitted, PO, currentlySelectedItem, currQty, ordQty, tableData } = this.state
    const { token, name, userRoles } = this.props
    if (formSubmitted === false) {
      return <BarcodeFormContainer barcodeFormValidatedShowTable={this.barcodeFormValidatedShowTable} />
    }
    if (formSubmitted === true && tableSubmitted === false) {
      return <BarcodeTableContainer storedTableData={tableData} 
        resetBarcodeForm={this.resetBarcodeForm} 
        tableValidatedDisplayBarcodes={this.tableValidatedDisplayBarcodes}
        currQty={currQty}
        ordQty={ordQty} />
    }
    return (<DisplayBarcodesErrorBoundary onClick={this.showBarcodeTableWithDataAgain}>
      <DisplayBarcodes 
        PO={PO} 
        currentlySelectedItem={currentlySelectedItem} 
        tableData={tableData} 
        resetBarcodeForm={this.resetBarcodeForm} 
        token={token} 
        name={name}
        userRoles={userRoles} />
    </DisplayBarcodesErrorBoundary>)
  }

	/**
	 * Function that is called when the BarcodeForm is validated and BarcodeTable needs to be shown
	 * @param {String} PO
	 * @param {String} currentlySelectedItem
	 * @param {Number} currQty
	 * @param {Number} ordQty
	 */
	barcodeFormValidatedShowTable = (PO, currentlySelectedItem, currQty, ordQty) => {
	  this.setState({
	    PO, 
	    currentlySelectedItem,
	    currQty,
	    ordQty,
	    formSubmitted: true
	  })
	}

	/**
	 * Function that is called by the back button when the entire tree generated by GenerateBarcodes needs to be reset
	 */
	resetBarcodeForm = () => {
	  this.setState(this.initialState)
	}

	/**
	 * Function that is called by a back arrow that is part of the error boundary around DisplayBarcodes
	 * This function is called when DisplayBarcodes throws an error but to prevent the user having to enter
	 * the entire data into BarcodeTable again
	 */
	showBarcodeTableWithDataAgain = (e) => {
	  e.preventDefault()
	  this.setState({
	    formSubmitted: true,
	    tableSubmitted: false
	  })
	}

	/**
	 * Function that is called when BarcodeTable is valid and DisplayBarcodes needs to be called
	 */
	tableValidatedDisplayBarcodes = (tableData) => {
	  this.setState({
	    tableData,
	    tableSubmitted: true
	  })
	}
}

export default GenerateBarcodes