import React from 'react'
import Input from 'UI/Components/Input'

const SearchInput = (props: { value: string, onChange: React.ChangeEventHandler<HTMLInputElement> | undefined }) => {
  return (
    <Input placeholder={'Please enter your search term'}
      onChange={props.onChange} value={props.value} />
  )
}

export default SearchInput