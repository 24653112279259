import React from 'react'
import styled from 'styled-components'

const BackArrow = styled.img`
    cursor: pointer;
    align-self: flex-start;
`

const BackArrowComponent = (props) => {
  return <BackArrow src="back-arrow.svg" {...props} />
}

export default BackArrowComponent