import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../Services/auth0-wrapper'
import Can from './Can'

const PrivateRoute = ({ component: Component, path, perform, ...rest }) => {
  const [token, setToken] = useState()
  const [name, setName] = useState()
  const [userRoles, setUserRoles] = useState()
  const { loading, isAuthenticated, loginWithRedirect, getTokenSilently, user } = useAuth0()
  useEffect(() => {
    //  Wait for auth0 client to be initialized
    if (loading) {
      return
    }

    const getTokenFn = async () => {
      const token = await getTokenSilently()
      setToken(token)
      setName(user.nickname)
      if (user) {
        setUserRoles(user[`${process.env.REACT_APP_ROLE_NAMESPACE}/user_authorization`]['roles'])
      }
    }
    getTokenFn()

    //  Don't run the redirect if user is already authenticated
    if (isAuthenticated) {
      return
    }

    const redirectFn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname }
      })
    }
    redirectFn()
  }, [loading, isAuthenticated, loginWithRedirect, path, user, getTokenSilently])

  const render = props => isAuthenticated === true ? (<Can roles={userRoles} action={perform} yes={() => (
    <Component token={token} name={name} userRoles={userRoles} {...props} />
  )} no={() => <div>You cannot access this page</div>} />) : null

  return <Route path={path} render={render} {...rest} />
}

export default PrivateRoute