import React, {useState, useEffect} from 'react'
import { Box } from '@chakra-ui/layout'
import { Column } from 'react-table'
import {GroupedDataByArticleNumber, PalletDataDTO, WarehouseOverallDataOrganized } from 'Dashboard/types'
import WarehouseDataTable from './WarehouseDataTable'
import { getOverallWarehouseInformation } from 'Dashboard/api/getWarehouseData'
import { Loader } from 'UI/Components'

function WarehouseOverallPalletsResults() {

  const [_, setError] = useState<Error>(new Error())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<PalletDataDTO[]>([])

  // run on first render to fetch overall pallet data from the api 
  useEffect(() => {
    const makeRequestToGetOverallWarehouseData = async () => {
      setIsLoading(true)
      try {
        const response = await getOverallWarehouseInformation()
        setIsLoading(false)
        setData(response)
      } catch (err) {
        setIsLoading(false)
        setError(() => {
          throw err
        })
      }
    }

    makeRequestToGetOverallWarehouseData()
    
  }, [])

  // function to group the fetched data by their article number
  const groupDataByArticleNumber = (data: PalletDataDTO[]) => {
    return data.reduce((acc, curr) => {
      const { articleNumber } = curr
      //  if the key is not defined on the acc object
      if (acc[articleNumber] === null || acc[articleNumber] === undefined) {
        acc[articleNumber] = [Object.assign({}, { ...curr })]
        return acc
      }
      //  if the key is defined on the acc object
      acc[articleNumber].push(Object.assign({}, { ...curr }))
      return acc
    }, {} as GroupedDataByArticleNumber)
  }

  // function to convert grouped data to required format i.e. articleNumber, numberofPallets
  const organizeDataForViewing = (dataToOrganize: GroupedDataByArticleNumber): WarehouseOverallDataOrganized[] => {
    return Object.entries(dataToOrganize).map(([articleNumber, value]) => {
      const numberOfPallets = value.reduce((acc, curr) => {
        return acc + 1
      }, 0)
      return { articleNumber, numberOfPallets }
    })
  }

  const calculateTotalNumberOfPallets = (dataToAdd: WarehouseOverallDataOrganized[]) => {
    return dataToAdd.reduce((acc, curr) => {
      return acc + curr.numberOfPallets
    }, 0)
  }

  const tableData:WarehouseOverallDataOrganized[] = organizeDataForViewing(groupDataByArticleNumber(data))

  const columns = [
    {
      Header: "",
      id: "row",
      Cell: (props: any) => {
        return <div style={{textAlign: 'right'}} >{props.row.index + 1}.</div>
      },
      disableSortBy: true,
      width: 30
    },
    {
      Header: "Article Number",
      accessor: "articleNumber"
    },
    {
      Header: "Number Of Pallets",
      accessor: "numberOfPallets"
    }
  ] as Column<WarehouseOverallDataOrganized>[]

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box alignSelf='flex-end' marginTop='5em' marginRight={'8em'} color='brand.100'>
        <span style={{ fontWeight: 'bold' }}>Total: </span>
        {calculateTotalNumberOfPallets(tableData)}
      </Box>
      <div style={{ width: '50%' }}>
        <WarehouseDataTable data={tableData} columns={columns} />
      </div>
    </>
  )
}

export default WarehouseOverallPalletsResults