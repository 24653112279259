import React from 'react'

import ErrorDisplay from '../UI/Components/ErrorDisplay'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      hasError: false
    }
  }

  componentDidCatch(error, info) {
    this.setState({ error, hasError: true })
  }

  render() {
    const { error, hasError } = this.state
    if (hasError) {
      return (
        <ErrorDisplay>
          <h2 style={{ textAlign: 'center' }}>
            {error.message ? error.message : 'Oops! Something went wrong'}
          </h2>
        </ErrorDisplay>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary