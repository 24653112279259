import rules from '../rbac-rules'

const check = (rules, roles, action) => {
  if (!roles || !action) {
    return false
  }

  const canAccess = roles.map(role => {
    const permissions = rules[role.name]

    //  If permissions do not exist, return false
    if (!permissions) {
      return false
    }

    if (permissions.includes(action)) {
      return true
    }

    return false
  }).reduce((acc, curr) => {
    return acc || curr
  }, false)
  return canAccess
}

const Can = props => check(rules, props.roles, props.action) ? props.yes() : props.no()

Can.defaultProps = {
  yes: () => null, 
  no: () => null
}

export default Can