import React from 'react'
import { useHistory } from 'react-router-dom'

import { DashboardButton } from '../UI/Components'

function DashboardBase() {
  const history = useHistory()

  /**
   * Function to handle the button click
   * @param {Object} event 
   * @returns {null}
   */
  const handleButtonClick = (/** @type {{ target: { textContent: string; }; }} */ event) => {
    const text = event.target?.textContent?.toLowerCase()
    switch (text) {
      case 'fabric':
        history.push({
          pathname: '/dashboard/fabric'
        })
        return
      case 'cutting':
        history.push({
          pathname: '/dashboard/cutting'
        })
        return
      case 'sewing':
        history.push({
          pathname: '/dashboard/sewing'
        })
        return
      case 'warehouse':
        history.push({
          pathname: '/dashboard/warehouse'
        })
        return
      default:
        throw new Error('Something went wrong')
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', marginTop: '15%' }}>
      <DashboardButton onClick={handleButtonClick}>Fabric</DashboardButton>
      <DashboardButton onClick={handleButtonClick}>Cutting</DashboardButton>
      <DashboardButton onClick={handleButtonClick}>Sewing</DashboardButton>
      <DashboardButton onClick={handleButtonClick}>Warehouse</DashboardButton>
    </div>
  )
}

export default DashboardBase