import { apiClient } from "../../Services/Networking/apiClient"

export const isPONumberValid = async (PO: string): Promise<Boolean> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabscan/roll/barcodes/${PO}/validate`
  })
  return response as unknown as Boolean
}

type itemNumbersData = {
  ITEMNO: string,
  ITEMDESC: string
}

export const getItemNumbers = async (PO: string): Promise<itemNumbersData[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabscan/roll/barcodes/${PO}/items`
  })
  return response as unknown as itemNumbersData[]
}

type checkPOQuantityResultType = {
  ordQty: number,
  currQty: number
}
export const checkPOQuantity = async (PO: string, itemNumber: string): Promise<checkPOQuantityResultType> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabscan/roll/barcodes/${PO}/quantity`,
    params: { ItemNo: itemNumber }
  })
  return response as unknown as checkPOQuantityResultType
}

type barcodeDetails = {
  ocNumber: string,
  customer: string,
  styleNumber: string,
  styleDescription: string,
  itemDescription: string
}
export const getBarcodeDetails = async (PO: string, itemNo: string) => {
  const response = await apiClient({
    method: 'get',
    url: `fabscan/roll/barcodes/${PO}/details`,
    params: { itemNo }
  })
  return response as unknown as barcodeDetails
}

type tableData = {
  roll: string,
  length: number
}

export const postBarcodeDetails = async (PO: string, OC: string, custName: string, styleNo: string, styleDesc: string, itemNo: string, itemDesc: string, tableData: tableData[], name: string): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabscan/roll/barcodes/${PO}/details`,
    data: {
      PO,
      OC,
      custName,
      styleNo,
      styleDesc,
      itemNo,
      itemDesc,
      tableData,
      name
    }
  })
  return response as unknown as Boolean
}