import React from 'react'
import styled from 'styled-components'

const ErrorLabel = styled.label`
    color: red;
    align-self: center;
`

const ErrorLabelComponent = (props) => {
  return <ErrorLabel {...props}>{props.text}</ErrorLabel>
}

export default ErrorLabelComponent