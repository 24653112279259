import React from 'react'
import { Select } from 'UI/Components'

type SelectItemProps = {
  items: string[],
  item: string,
  defaultOption: string,
  onChange: (item: string) => void
}

export const SelectItem = (props: SelectItemProps) => {
  const { items, item, defaultOption, onChange } = props

  return (
    <Select
      defaultOption={defaultOption}
      value={item}
      onChange={(event: Event) => {
        onChange((event.target as HTMLInputElement).value)
      }}
      valuesToMap={items}
      mapperFn={(item: string) => {
        return (
          <option key={item} value={item}>{`${item}`}</option>
        )
      }} />
  )
}