import { useEffect, useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td, TableCaption, Center } from "@chakra-ui/react"
import { OcNumberConsolidatedData } from "Dashboard/types/cutting"

type ConsolidatedDataProps = {
  data: OcNumberConsolidatedData[]
}

type QuantityPerSizeObject = {
  [size: string]: number
}

type GroupedConsolidatedData = {
  [date: string]: {
    averageConsumption: number,
    [size: string]: number
  }
}

const CuttingConsolidatedResults = (props: ConsolidatedDataProps) => {
  const { data } = props

  const [uniqueSizes, setUniqueSizes] = useState<string[]>([])
  const [groupedData, setGroupedData] = useState<GroupedConsolidatedData>({})
  const [totalQuantitiesPerSize, setTotalQuantitiesPerSize] = useState<QuantityPerSizeObject>({})
  const [totalAverageConsumption, setTotalAverageConsumption] = useState<number>(0)
  const [totalCutQuantity, setTotalCutQuantity] = useState<number>(0)


  const getUniqueSizeList = (data: OcNumberConsolidatedData[]) => {
    const list: string[] = []
    data.forEach((each: OcNumberConsolidatedData) => {
      const { size } = each
      if (list.indexOf(size) < 0) {
        list.push(size)
      }
    })
    setUniqueSizes(list)
  }

  const groupDataByDateAndGetTotalAvgConsumption = (data: OcNumberConsolidatedData[]) => {
    const groupedObject: GroupedConsolidatedData = {}
    let sumAverageConsumption = 0
    let count = 0
    data.forEach((each: OcNumberConsolidatedData) => {
      const { date, size, quantity, averageConsumption } = each
      if (groupedObject[date] === undefined) {
        groupedObject[date] = { averageConsumption }
        sumAverageConsumption += averageConsumption
        count += 1
      }
      groupedObject[date][size] = quantity
    })
    setGroupedData(groupedObject)
    const totalAvgConsumption = Math.round(sumAverageConsumption / count * 1e3) / 1e3 || 0
    setTotalAverageConsumption(totalAvgConsumption)

  }

  const getTotalQuantityPerSizeAndTotalCutQuantity = (data: OcNumberConsolidatedData[]) => {
    const quantitPerSizeObject: QuantityPerSizeObject = {}

    let totalCut = 0
    data.forEach((each: OcNumberConsolidatedData) => {
      const { size, quantity } = each
      if (quantitPerSizeObject[size] === undefined) {
        quantitPerSizeObject[size] = 0
      }
      quantitPerSizeObject[size] += quantity
      totalCut += quantity
    })
    setTotalQuantitiesPerSize(quantitPerSizeObject)
    setTotalCutQuantity(totalCut)
  }

  useEffect(() => {
    getUniqueSizeList(data)
    getTotalQuantityPerSizeAndTotalCutQuantity(data)
    groupDataByDateAndGetTotalAvgConsumption(data)
  }, [data])


  return (
    <div style={{ marginTop: "1%" }}>
      <Table>
        <TableCaption>Cutting Overview</TableCaption>
        <Thead style={{ position: "sticky", top: "0" }} backgroundColor="lightblue">
          <Tr>
            <Th><Center>Date</Center></Th>
            {
              // Setting the header with the unique sizes
              uniqueSizes.map(each => { return <Th key={each}><Center>{each}</Center></Th> })
            }
            <Th>Avg. Cons.</Th>
          </Tr>
        </Thead>
        <Tbody color="brand.100">
          {
            // Row entry per date
            Object.keys(groupedData).map((date) => {
              const { averageConsumption } = groupedData[date]
              const correspondingQuantities = uniqueSizes.map(uniqueSize => {
                const quantity = groupedData[date][uniqueSize] || 0
                return quantity
              })
              return (
                <Tr key={date}>
                  <Td>{date}</Td>
                  {
                    // Date wise cut quantity per size
                    correspondingQuantities.map((qty, index) => {
                      return <Td key={`${index}-${date}-${qty}`}><Center>{qty}</Center></Td>
                    })
                  }
                  <Td><Center>{averageConsumption}m</Center></Td>
                </Tr>
              )
            })
          }
          <Tr color="darkorange">
            <Td></Td>
            {
              // total cut quantites per size
              uniqueSizes.map((uniqueSize, index) => {
                return (
                  <Td key={`${index}-${uniqueSize}`}><Center>{totalQuantitiesPerSize[uniqueSize]}</Center></Td>
                )
              })
            }
            <Td><Center>{totalAverageConsumption}m</Center></Td>
          </Tr>
          <Tr color="red">
            {
              // Adding spacing to centralise the total cut quantity
              [...Array(Math.floor(uniqueSizes.length / 2))].map((_, index) => <Td key={index} />)
            }
            <Td>Total</Td>
            <Td><Center>{totalCutQuantity}</Center></Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  )
}

export default CuttingConsolidatedResults