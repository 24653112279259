import { DefectData, DHUDataDTO, HourlyDefectData } from "Dashboard/types/sewing"
import { useEffect, useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td, Center, Box } from "@chakra-ui/react"

type DHUDateRangeTableProps = {
  data: DHUDataDTO[]
}

type DataTypePerDate = {
  totalQuantity: number,
  [defect: string]: number
}

type GroupedDataType = {
  [date: string]: DataTypePerDate
}

type GroupedDataTypeWithTotalDefects = {
  [date: string]: DataTypePerDate & {
    totalDefects: number
  }
}

type GroupedDataTypeWithTotalDefectsAndDHU = {
  [date: string]: DataTypePerDate & {
    totalDefects: number,
    DHU: number
  }
}

export const DHUDateRangeTable = (props: DHUDateRangeTableProps) => {
  const { data } = props
  const [defects, setDefects] = useState<string[]>([])
  const [groupedData, setGroupedData] = useState<GroupedDataType>({})

  useEffect(() => {
    const groupedData = groupData(data)
    const groupedDataWithTotalDefects = calculateTotalDefectsForEachDate(groupedData)
    const groupedDataWithTotalDefectsAndDHU = calculateDHUForEachDate(groupedDataWithTotalDefects)
    setGroupedData(groupedDataWithTotalDefectsAndDHU)
    const uniqueListOfDefects = getUniqueListOfDefects(data)
    setDefects(uniqueListOfDefects)
  }, [data])

  // Returns the date in dd-mmm-yyyy format
  const getDateFormat = (date: string) => {
    return new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
  }

  const groupData = (data: DHUDataDTO[]) => {
    const groupedObject: GroupedDataType = {}

    data.forEach((each: DHUDataDTO) => {
      const { date, totalPassedQuantity, totalRejectedQuantity, totalReworkedQuantity, hourlyDetails } = each
      const totalQuantity = totalPassedQuantity + totalRejectedQuantity + totalReworkedQuantity
      groupedObject[date] = groupedObject[date] || { totalQuantity: 0 }
      groupedObject[date].totalQuantity += totalQuantity
      hourlyDetails.forEach((item: HourlyDefectData) => {
        const { defectDetails } = item
        defectDetails.forEach((defectObject: DefectData) => {
          const { defect, quantity } = defectObject
          groupedObject[date][defect] = groupedObject[date][defect] || 0
          groupedObject[date][defect] += quantity
        })
      })
    })
    return groupedObject
  }

  const calculateTotalDefectsForEachDate = (data: GroupedDataType): GroupedDataTypeWithTotalDefects => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const { totalQuantity, ...defectsObject } = value
      const totalNumberOfDefects = Object.entries(defectsObject).reduce((acc, [defect, value]) => {
        return acc + value
      }, 0)
      return Object.assign(
        {},
        { ...acc },
        {
          [key]: {
            ...value,
            totalDefects: totalNumberOfDefects
          }
        }
      )
    }, {})
  }

  const calculateDHUForEachDate = (data: GroupedDataTypeWithTotalDefects): GroupedDataTypeWithTotalDefectsAndDHU => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const { totalQuantity, totalDefects } = value
      const DHU = totalDefects * 100 / totalQuantity
      return Object.assign(
        {},
        { ...acc },
        {
          [key]: {
            ...value,
            DHU
          }
        }
      )
    }, {})
  }

  const getUniqueListOfDefects = (data: DHUDataDTO[]) => {
    const listOfDefects = data.map(({ hourlyDetails }) => {
      return hourlyDetails.map((item: HourlyDefectData) => {
        const { defectDetails } = item
        return defectDetails.map((defectObject: DefectData) => {
          const { defect, quantity } = defectObject
          return defect
        })
      }).flat()
    }).flat()
    return [...new Set(listOfDefects)]  //  this line will remove all duplicates because a Set by default will remove duplicates
  }

  if (data.length === 0) {
    return (
      <div className="dhu-dashboard-error">
        No results found
      </div>
    )
  }

  return (
    <div>
      <div className="dhu-dashboard-header">Date Range Defects Table</div>
      <div className="dhu-dashboard-content">
        <Box overflowX='auto' maxWidth='80vw' margin={'2% 0 0 2%'} css={{
          '&::-webkit-scrollbar': {
            display: 'block',
            height: '0.4em'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'grey',
            borderRadius: '24px'
          },
        }}>
          <Table>
            <Thead style={{ position: "sticky", top: "0" }}>
              <Tr>
                <Th color={'#334bff'}><Center>Date</Center></Th>
                {
                  // Setting the header with the unique date
                  defects.map(each => { return <Th color={'#334bff'} key={each}><Center>{each}</Center></Th> })
                }
                <Th color={'#334bff'}><Center>Total Defects</Center></Th>
                <Th color={'#334bff'}><Center>DHU</Center></Th>
                <Th color={'#334bff'}><Center>Total Inspected</Center></Th>
              </Tr>
            </Thead>
            <Tbody color="brand.100">
              {
                // Row entry per batch
                Object.keys(groupedData).map((date) => {
                  const { totalQuantity, totalDefects, DHU } = groupedData[date]
                  const correspondingQuantities = defects.map(defect => {
                    const quantity = groupedData[date][defect] || 0
                    return quantity
                  })

                  return (
                    <Tr key={date}>
                      <Td minWidth='12vw'><Center>{getDateFormat(date)}</Center></Td>
                      {
                        // Batch wise cut quantity per defect
                        correspondingQuantities.map((qty, index) => {
                          return <Td key={`${index}-${date}-${qty}`}><Center>{qty}</Center></Td>
                        })
                      }
                      <Td><Center>{totalDefects}</Center></Td>
                      <Td><Center>{DHU.toFixed(2)}%</Center></Td>
                      <Td><Center>{totalQuantity}</Center></Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Box>

      </div>
    </div>
  )

}