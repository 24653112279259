import React, { useEffect, useState } from 'react'
import { SewingDataDTO, SingleDateSewingChartDTO } from 'Dashboard/types/sewing'
import BarChart from './BarChart'

type SewingSingleDateChartProps = {
  data: SewingDataDTO[]
}

export const SewingSingleDateChart = (props: SewingSingleDateChartProps) => {
  const { data } = props

  const [chartData, setChartData] = useState<SingleDateSewingChartDTO[]>([])

  /** To format the efficiency data for the dashboard */
  useEffect(() => {
    const formattedData = formatData(data)
    setChartData(formattedData)
  }, [data])

  const roundNumberToSignificantDigits = (number: number, numberOfDigits: number) => {
    return Math.round(number * Math.pow(10, numberOfDigits)) / Math.pow(10, numberOfDigits)
  }

  const formatData = (data: SewingDataDTO[]) => {
    const formattedData: SingleDateSewingChartDTO[] = []
    const presentDateTime = new Date(new Date().toLocaleString('en-Us', { hour12: false, timeZone: 'Asia/Kolkata' }))
    const workPresentDateTimeToCalculate = new Date(Date.UTC(presentDateTime.getFullYear(), presentDateTime.getMonth(), presentDateTime.getDate(), presentDateTime.getHours(), presentDateTime.getMinutes(), 0)).toISOString()
    

    data.forEach(item => {
      const { date, batchNumber, ocNumber, totalOutputQuantity, hourlyDetails } = item
      const newDate = new Date(date)

      const samProduced = hourlyDetails.reduce((acc, curr) => {
        const { sewingSamProduced } = curr
        return acc + sewingSamProduced
      }, 0)

      const sewingMachineMinutes = hourlyDetails.reduce((acc, curr) => {
        const { sewingSamProduced, sewingMachineMinutes, endTime } = curr
        const workEndTimeSplit = endTime.split(':')
        const workEndDateTimeToCalculate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), workEndTimeSplit[0] as unknown as number, workEndTimeSplit[1] as unknown as number, 0)).toISOString()
        if (sewingSamProduced > 0 || workEndDateTimeToCalculate <= workPresentDateTimeToCalculate){
          return acc + sewingMachineMinutes
        }
        return acc + 0
      }, 0)
      
      const calculatedEfficiency = roundNumberToSignificantDigits(((samProduced / sewingMachineMinutes) * 100), 2)
      // Calculate average sewing efficiency for multiple records under the same batch number
      const batchEntity = formattedData.find(element => element.batchNumber === batchNumber)
      if (batchEntity === undefined || batchEntity === null) {
        return formattedData.push({ batchNumber, ocNumber, totalOutputQuantity, efficiency: calculatedEfficiency, samProducedMinutes: samProduced, machineMinutes: sewingMachineMinutes })
      }
      
      const { ocNumber: batchOcNumber } = batchEntity
      
      batchEntity.ocNumber = `${ocNumber}, ${batchOcNumber}`
      batchEntity.totalOutputQuantity += totalOutputQuantity
      batchEntity.samProducedMinutes += samProduced
      batchEntity.machineMinutes += sewingMachineMinutes
      batchEntity.efficiency = roundNumberToSignificantDigits(((batchEntity.samProducedMinutes / batchEntity.machineMinutes) * 100), 2)
    })
    formattedData.sort((a, b) => a.batchNumber.localeCompare(b.batchNumber))
    return formattedData
  }

  if (data.length === 0) {
    return (
      <div className="sewing-dashboard-error">
        No results found
      </div>
    )
  }


  return (
    <div style={{ margin: "0 0 0 5%" }}>
      <BarChart
        height={600}
        width={1000}
        xMargin={80}
        yMargin={60}
        topMargin={10}
        data={chartData}
      />
    </div>
  )
}