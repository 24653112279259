import React from 'react'
import { Input, Select, SubmitButton, TickIcon, ErrorIcon } from '../UI/Components'

import './BarcodeForm.css'

const BarcodeForm = (props) => {
  const {
    PO,
    PONumberValid,
    POFieldTouched,
    itemNosAndDescription,
    currentlySelectedItem,
    handlePONumChange,
    handleItemChange,
    handleFormSubmit,
    checkIfPONumberValid,
  } = props



  return (
    <form id="form" onSubmit={handleFormSubmit}>
      <div style={{ whiteSpace: 'nowrap' }}>
        <Input
          isInvalid={POFieldTouched && !PONumberValid}
          type="text"
          value={PO}
          placeholder='Enter the PO#'
          isRequired
          onChange={handlePONumChange}
          onBlur={checkIfPONumberValid}
          style={{ width: '90%' }} />
        {
          (PONumberValid) ? (<span className='valid'><TickIcon /></span>) : (
            (POFieldTouched) ? (<span className='invalid'><ErrorIcon /></span>) : (null)
          )
        }
      </div>
      <Select
        value={currentlySelectedItem}
        onChange={handleItemChange}
        valuesToMap={itemNosAndDescription}
        mapperFn={(itemObj) => <option value={`${itemObj.ITEMNO} ${itemObj.ITEMDESC}`} key={itemObj.id}>{`${itemObj.ITEMNO} ${itemObj.ITEMDESC}`}</option>}
        defaultOption={'Please choose an option for item #'}
        style={{ width: '90%' }} />
      <SubmitButton type='submit' style={{ width: '90%' }}>SUBMIT</SubmitButton>
    </form>
  )
}

export default BarcodeForm
