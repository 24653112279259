import React from 'react'
import styled from 'styled-components'

const Select = styled.select`
  width: 100%;
  height: 100%;
  padding: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 4px;
  border: solid 1px #334bff;
  text-align: center;
  text-align-last: center;
  cursor: pointer;
  background-image: url(dropdown-triangle.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 1em;
`

const SelectComponent = (props) => {
  const { value, onChange, valuesToMap, defaultOption, mapperFn } = props
  return (
    <Select required onChange={onChange} value={value} {...props}>
      <option value="" disabled hidden>{defaultOption}</option>
      {
        valuesToMap.map(mapperFn)
      }
    </Select>
  )
}

export default SelectComponent