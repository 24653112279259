import { TriangleDownIcon, Icon } from '@chakra-ui/icons'
import { IoFilterSharp } from 'react-icons/io5'
import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'

import "react-datepicker/dist/react-datepicker.css"

type ButtonProps = React.HTMLProps<HTMLButtonElement>

export const FilterDatePicker = (props: any) => {
  const CustomDisplayForButton = forwardRef<HTMLButtonElement, ButtonProps>(({ value, onClick }, ref) => (
    <button
      style={filterButtonStyle}
      className="example-custom-input"
      onClick={onClick} ref={ref}>
      <Icon as={IoFilterSharp} />
      {`Filter`}
      <TriangleDownIcon />
    </button>
  ))
  return (
    <DatePicker
      customInput={<CustomDisplayForButton />}
      {...props}
    />
  )
}

const filterButtonStyle = {
  backgroundColor: 'white',
  fontWeight: '600',
  color: '#334bff',
  border: '1px solid #334bff',
  borderRadius: '5px',
  padding: '.3em .4em',
  width: '7vw',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '1.1em'
}