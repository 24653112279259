import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import { nanoid } from 'nanoid'

import { Loader, BackArrow, SubmitButton } from '../UI/Components'
import Can from '../Components/Can'
import './DisplayBarcodes.css'
import { getBarcodeDetails, postBarcodeDetails } from './api/apiFunctions'

const JsBarcode = require('jsbarcode')

/**
 * Component that is used to display the barcodes to the user.
 * Used for printing the barcodes as well from either a barcode printer
 * or a regular A4 printer.
 * The user can send commands to a barcode printer using a button
 */

const DisplayBarcodes = (props) => {
  const { PO, currentlySelectedItem, tableData, name, resetBarcodeForm, userRoles } = props
  const noOfRolls = tableData.length

  const [_, setError] = useState('')  //  eslint-disable-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)
  const [barcodesTableData, setBarcodesTableData] = useState([])

  /**
   * This effect will run as soon as the component mounts
   */
  useEffect(() => {
    /**
     * This function will fetch barcode details from SAGE and then update MySQL with them
     */
    const fetchBarcodeDetailsAndUpdateMySQL = async () => {
      const itemNo = currentlySelectedItem.substr(0, currentlySelectedItem.indexOf(" "))
      const barcodesTableData = generateBarcodes()
      setBarcodesTableData(barcodesTableData)
      try {
        const { ocNumber, customer, styleNumber, styleDescription, itemDescription } = await getBarcodeDetails(PO, itemNo)
        await postBarcodeDetails(PO, ocNumber, customer, styleNumber, styleDescription, itemNo, itemDescription, barcodesTableData, name)
        onNextFrame(drawSvgs, barcodesTableData, ocNumber, itemDescription)
        setIsLoading(false)
      } catch (err) {
        setError(() => {
          throw Error(err)
        })
      }
    }
    setIsLoading(true)
    fetchBarcodeDetailsAndUpdateMySQL()
  }, [])  //  eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Function that is used to generate the unique code for each barcode
   */
  const generateBarcodes = () => {
    return tableData.map(({ roll, length }) => {
      return Object.assign({}, { length }, { roll: nanoid(10) }, { supplierRollNo: roll })
    })
  }

  /**
   * This function is called to wait until the browser finishes painting the current frame and is
   * ready to pain the next frame. It will then call the function passed into the callback arg
   * @param {Function} callback 
   * @param  {...any} args 
   */
  const onNextFrame = (callback, ...args) => {
    setTimeout(() => {
      requestAnimationFrame(callback.bind(this, ...args))
    }, 1)
  }

  /**
   * Function that is called when the browser is ready to draw the barcodes on the UI
   * @param {Object[]} barcodesTableData 
   * @param {String} OC 
   * @param {String} itemDesc 
   */
  const drawSvgs = (barcodesTableData, OC, itemDesc) => {
    //  Draw the SVGs for the actual barcodes that will be displayed
    for (let i = 0; i < barcodesTableData.length; i++) {
      JsBarcode(`.barcode_${i}`, barcodesTableData[i].roll, {
        format: 'CODE128',
        width: 2,
        height: 100,
        displayValue: false
      })
    }

    //  Grab the array of elements that will be used to display text
    const elementsArray = [...document.getElementsByClassName('barcode-text-container')]
    elementsArray.map((element, index) => {
      element.innerHTML = `${barcodesTableData[index].roll}, ${OC}, ${barcodesTableData[index].length}, ${itemDesc}, ${barcodesTableData[index].supplierRollNo}`
      return element
    })
  }

  /**
   * Function to check if there is a barcode printer connected 
   */
  const checkForZebraPrinter = () => {
    return new Promise(function isDeviceConnectedResolver(resolve, reject) {
      try {
        window.BrowserPrint.getLocalDevices(function (deviceList) {
          const device = deviceList.printer?.[0]
          if (typeof device === 'undefined') {
            reject('Cannot detect a Zebra printer attached to this system')
          }
          resolve(device)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * Function that is called to allow communication with the Zebra printer attached to the system
   */
  const sendCommandsToBarcodePrinter = async () => {
    let device = null
    try {
      device = await checkForZebraPrinter()
    } catch (err) {
      window.alert(err)
      return
    }
    barcodesTableData.map(({ roll, length, supplierRollNo }) => {
      device.send(`^XA
      ^CF0,25
      ^FO200,80,^BY3,
      ^BCN,100,Y,N,N,N
      ^FD${roll}^FS
      ^FO150,230
      ^FB600,3,,
      ^FD${length}, ${currentlySelectedItem}, ${supplierRollNo}^FS
      ^XZ`)
      return { roll, length, supplierRollNo }
    })
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <Prompt when={true}
        message='Please make sure to print the barcodes first' />
      <BackArrow className='back_arrow' onClick={resetBarcodeForm} alt={'Back Button'} />
      <div className='svg_barcode_container'>
        {
          new Array(noOfRolls).fill(0).map((roll, index) => {
            const svgId = `barcode_${index}`
            return (
              <div className='barcode-container' key={index}>
                <svg className={`${svgId}`}></svg>
                <p className={`barcode-text-container`} id={index}></p>
              </div>
            )
          })
        }
      </div>
      <Can roles={userRoles} action={'view:ZebraPrint'} yes={() => (
        <SubmitButton className='barcode-print-btn' type='button' onClick={sendCommandsToBarcodePrinter}>Print From Barcode Printer</SubmitButton>
      )} no={() => null} />
    </React.Fragment>
  )
}

export default DisplayBarcodes