import { apiClient } from '../../Services/Networking/apiClient'
import SearchForEnum from '../SearchForEnum'
import { EfficiencyDataDTO, DHUDataDTO, SewingDataDTO } from 'Dashboard/types/sewing'

type EfficiencyParams = {
  location: string
}

export const getEfficiencyData = async (params: EfficiencyParams): Promise<EfficiencyDataDTO[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/${SearchForEnum.SEWING}/efficiency/location/${params.location}`
  })
  return response as unknown as EfficiencyDataDTO[]
}

type SewingParams = {
  location: string,
  startDate: string,
  endDate: string | null
}

export const getSewingData = async (params: SewingParams) => {
  const { location, startDate, endDate } = params

  const response = await apiClient({
    method: 'get',
    url: `analytics/${SearchForEnum.SEWING}/dateWise/efficiency/location/${location}`,
    params: { startDate, endDate }
  })
  return response as unknown as SewingDataDTO[]
}

type DHUParams = {
  location: string,
  startDate: string,
  endDate: string | null,
  batchNumber: string | null
}
export const getDHUData = async (params: DHUParams): Promise<DHUDataDTO[]> => {
  const { location, startDate, endDate, batchNumber } = params
  const response = await apiClient({
    method: 'get',
    url: `analytics/${SearchForEnum.SEWING}/defects/location/${location}`,
    params: { startDate, endDate, batchNumber }
  })

  return response as unknown as DHUDataDTO[]
}

