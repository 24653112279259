import { FormEvent, useEffect, useRef, useState } from "react"
import { SelectLocation } from "./SelectLocation"
import { OCInput } from "./OCInput"
import ImageFileUpload from "./ImageFileUpload"
import { getLocations, uploadTechPackDetails } from "TechPack/api/apiFunctions"
import "./UploadTechPack.css"
import { productCodeAndDescription } from "TechPack/types"
import { SelectProduct } from "./SelectProduct"
import SuccessLabelComponent from "UI/Components/SuccessLabel"
import { ErrorLabel, Loader, SubmitButton } from "UI/Components"
import { useHistory, useLocation } from 'react-router'

const UploadTechPack = () => {

  const ref = useRef(false)
  const history = useHistory()
  const loc = useLocation()

  const [OC, setOC] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [locations, setLocations] = useState<string[]>([])
  const [productCodeAndDescriptons, setProductCodeAndDescriptions] = useState<string[]>([])
  const [selectedProduct, setSelectedProduct] = useState<string>('')
  const [techPackFile, setTechPackFile] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState('')
  const [failureMessage, setFailureMessage] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [_, setError] = useState('')

  useEffect(() => {
    if (ref.current === true) {
      return setDisplayMessage()
    }
    ref.current = true
  }, [techPackFile])

  const setProductsList = (productCodeAndDescriptonsList: productCodeAndDescription[]) => {
    const formattedList: string[] = productCodeAndDescriptonsList.map(({ productCode, productDescription }) => `${productCode} | ${productDescription}`)
    setProductCodeAndDescriptions(formattedList)
  }

  const setDisplayMessage = () => {
    if (techPackFile.length === 0) {
      setFailureMessage('Something went wrong while reading the file')
      setSuccessMessage('')
      return
    }
    setSuccessMessage('The file is ready for upload')
    setFailureMessage('')
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (techPackFile.length === 0) {
      window.alert('Upload a valid tech pack image')
      return false
    }
    setIsLoading(true)
    try {
      let [code, desc] = selectedProduct.split('|')
      code = code.replace(" ", "")
      desc = desc.replace(/\s+/, "") // remove first white space
      const params = { ocNumber: OC, productTechPack: techPackFile, location, productCode: code, productDescription: desc }
      await uploadTechPackDetails(params)
      setIsLoading(false)
      window.alert('Techpack uploaded successfully')
      history.push('/')
      history.push(loc.pathname)
    } catch (err: any) {
      setIsLoading(false)
      setError(() => {
        throw Error(err)
      })
    }
  }

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await getLocations()
        if (response.length > 0) {
          setLocations(response)
        }
      } catch (err: any) {
        setError(() => {
          throw Error(err)
        })
      }
    }
    fetchLocations()
  }, [])

  if (isLoading) {
    return <Loader />
  }


  return (
    <form id="upload-tech-pack-form"
      onSubmit={e => handleSubmit(e)}>
      <OCInput
        OC={OC}
        onChange={(OC) => setOC(OC)}
        setProductsList={setProductsList}
      />
      <SelectLocation
        locations={locations}
        defaultOption={'Please select a location'}
        location={location}
        onChange={(location) => setLocation(location)} />
      <SelectProduct
        products={productCodeAndDescriptons}
        defaultOption={'Please select a product'}
        product={selectedProduct}
        onChange={(product) => setSelectedProduct(product)}
      />
      <ImageFileUpload
        onChange={(file) => setTechPackFile(file)}
      />
      <div style={{ textAlign: 'center', fontSize: '1.2vw' }}>
        <SuccessLabelComponent text={successMessage} />
        <ErrorLabel text={failureMessage} />
      </div>
      <SubmitButton style={{ width: '75%' }}>Submit</SubmitButton>
    </form>
  )
}

export default UploadTechPack