import React, { ChangeEvent } from 'react'
import { Input } from 'UI/Components'

type ConsumptionInputProps = {
  isInvalid: boolean,
  onChange: (consumption: number) => void
}

export const ConsumptionInput = (props: ConsumptionInputProps) => {
  const { isInvalid, onChange } = props
  return (
    <Input
      isRequired
      isInvalid={isInvalid}
      type="number"
      step="any"
      placeholder='Enter the consumption'
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const receivedValue = event.target.value
        onChange(+receivedValue)
      }}
    />
  )
}