import React from 'react'
import styled from '@emotion/styled'

const FileUploadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35vw;
  height: 20vh;
  padding: 8% 0 10% 0;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;`

const FileUploadContainerComponet = React.forwardRef((props, ref) => (
  <FileUploadContainer ref={ref} {...props} />
))

export default FileUploadContainerComponet