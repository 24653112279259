import React from 'react'
import styled from 'styled-components'

import Button from './Button'

const SubmitButton = styled(Button)`
  height: 55px;
`

const SubmitButtonComponent = (props) => {
  return (
    <SubmitButton {...props}>
      {props.children}
    </SubmitButton>
  )
}

export default SubmitButtonComponent