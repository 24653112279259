import React from 'react'

import ErrorDisplay from '../UI/Components/ErrorDisplay'
import BackArrow from '../UI/Components/BackArrow'

class DisplayBarcodesErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      hasError: false
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      error,
      hasError: true
    })
  }

  render() {
    const { error, hasError } = this.state
    if (hasError) {
      return (
        <React.Fragment>
          <BackArrow onClick={this.props.onClick} />
          <ErrorDisplay>
            <h2>
              {error.message ? error.message : 'Oops! Something went wrong'}
            </h2>
          </ErrorDisplay>
        </React.Fragment>
      )
    }
    return this.props.children
  }
}

export default DisplayBarcodesErrorBoundary