import React, { useEffect, useState } from "react"
import { ScannedCartonDetails, ScannedEPCDetails, CartonPackTypeEnum } from "Rfid/types"
import CartonDataTable from "./CartonDataTable"
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react"

type CartonDataTableProps = {
  data: ScannedCartonDetails[],
  handleSearchClick: any,
  location: string,
  startDate: string | null,
  endDate: string | null
}

const DownloadRfidResults = (props: CartonDataTableProps) => {
  const { data, handleSearchClick, location, startDate, endDate } = props
  const [uniqueShipmentIds, setUniqueShipmentIds] = useState<string[]>([])
  const [scannedTimeList, setScannedTimeList] = useState<string[]>([])
  const [scannedDateList, setScannedDateList] = useState<string[]>([])

  const formatPackType = (packType: string) => {
    if (packType === CartonPackTypeEnum.ASSORTED) {
      return 'A'
    }
    if (packType === CartonPackTypeEnum.MIXED) {
      return 'M'
    }
    if (packType === CartonPackTypeEnum.SOLID) {
      return 'S'
    }
    return packType
  }

  const getDataForShipmentId = (shipmentId: string): ScannedEPCDetails[] => {
    const groupedData: ScannedEPCDetails[] = []
    data.forEach(each => {
      if (shipmentId === each.shipmentId) {
        const { cartonId, cartonCode, cartonNumber, cartonWeight, packType, epcs, scannedTime } = each
        epcs.forEach(epc => {
          groupedData.push({ cartonId, shipmentId, cartonCode, packType: formatPackType(packType), cartonNumber, scannedTime, cartonWeight, epc })
        })
      }
    })
    return groupedData
  }

  useEffect(() => {
    // Fetch the unique list of shipment id(s) and their corresponding scan time(s)
    const uniqueShipmentList: string[] = []
    const uniqueTimeList: string[] = []
    const uniqueDateList: string[] = []
    data.forEach(each => {
      const { scannedDate, shipmentId, scannedTime } = each
      if (uniqueShipmentList.indexOf(shipmentId) < 0) {
        uniqueShipmentList.push(shipmentId)
        uniqueTimeList.push(scannedTime)
        uniqueDateList.push(scannedDate)
      }
    })

    setUniqueShipmentIds(uniqueShipmentList)
    setScannedTimeList(uniqueTimeList)
    setScannedDateList(uniqueDateList)
  }, [data])


  if (data.length === 0) {
    return (
      <div />
    )
  }

  return (
    <div className="rfid_download_results_container">
      <div className="rfid_download_results_header" style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '1%' }}>
        <h2>Scanned Date</h2>
        <br />
        <h2>Shipment ID</h2>
        <br />
        <h2>Time Of Scan</h2>
      </div>
      <Accordion allowMultiple>

        {uniqueShipmentIds.map((shipmentId, index) => {
          return (
            <AccordionItem key={shipmentId} >
              <h2>
                <AccordionButton>
                  <Box width='1%' className="spacing_box" />
                  <Box flex='1'>
                    {scannedDateList[index]}
                  </Box>
                  <Box width='5%' className="spacing_box" />
                  <Box flex='1'>
                    &nbsp; {shipmentId}
                  </Box>
                  <Box width='4%' className="spacing_box" />
                  <Box flex='1'>
                    &nbsp; {scannedTimeList[index]}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pl={0}>
                <CartonDataTable
                  data={getDataForShipmentId(shipmentId)}
                  handleSearchClick={handleSearchClick}
                  location={location}
                  startDate={startDate}
                  endDate={endDate}
                  shipmentId={shipmentId} />
              </AccordionPanel>
            </AccordionItem>
          )
        })}

      </Accordion>
    </div >
  )
}

export default DownloadRfidResults