import { ScannedCartonDetails } from "Rfid/types"
import { apiClient } from "Services/Networking/apiClient"


export const getScannedCartonDetails = async (location: string, fromScanDate: string | null, toScanDate: string | null): Promise<ScannedCartonDetails[]> => {
  const response = await apiClient({
    method: 'GET',
    url: `/fabship/product/rfid/location/${location}`,
    params: { fromScanDate, toScanDate }

  })
  return response as unknown as ScannedCartonDetails[]
}