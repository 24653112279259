import { Link } from "react-router-dom"
import BorderedButton from "UI/Components/Button/BorderedButton"

const RfidHome = () => {

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', marginTop: '15%' }}>
      <Link to="/products/rfid/data-upload">
        <BorderedButton>Upload Data</BorderedButton>
      </Link>
      <Link to="/products/rfid/data-download">
        <BorderedButton>Download Data</BorderedButton>
      </Link>
    </div >
  )
}

export default RfidHome