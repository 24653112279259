import { itemsLocationsAndFitTypes } from "Orders/types"
import { apiClient } from "../../Services/Networking/apiClient"

export const validateOCNumberAndFetchItemNumbersAndLocations = async (OC: string) => {
  const response = await apiClient({
    method: 'get',
    url: `/fabcut/cutting/orders/${OC}`
  })
  return response as unknown as itemsLocationsAndFitTypes
}

type sizeQuantityObject = {
  size: string,
  quantity: number
}

export const postOrderSizeAndQuantities = async (OC: string, location: string, itemCode: string, itemDescription: string, fitType: string, approvedConsumption: number, sizeQuantities: sizeQuantityObject[]): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `/fabcut/cutting/orders/${OC}`,
    data: {
      location,
      itemCode,
      itemDescription,
      fitType,
      approvedConsumption,
      sizeQuantities
    }
  })
  return response as unknown as Boolean
}