import { apiClient } from '../../Services/Networking/apiClient'
import SearchForEnum from '../SearchForEnum'

type GetFabricDataDTO = {
  itemDesc: String,
  itemCode: String,
  poNumber: String,
  ocNumber: String  
}

type paramsObject = {
  poNum?: string,
  ocNum?: string,
  styleDescription?: string
}

export const getFabricData = async (params: paramsObject): Promise<GetFabricDataDTO[]> => {
  const response: GetFabricDataDTO[] = await apiClient({
    method: 'get',
    url: `/analytics/${SearchForEnum.FABRIC}`,
    params
  }) as unknown as GetFabricDataDTO[]
  return response
}