import React, { useEffect, useState } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"

import { useAuth0 } from "./Services/auth0-wrapper"
import storage from "./Services/Storage/localStorage"

import GenerateBarcodes from "./Barcodes/GenerateBarcodes"
import Dashboard from "./Dashboard"
import PrivateRoute from "./Components/PrivateRoute"
import Can from "./Components/Can"
import Loader from "./UI/Components/Loader"
import Order from "./Orders/routes"
import Rfid from "./Rfid/routes"
import TechPack from "./TechPack/routes"
import DropDownIcon from "UI/Components/Icons/DropDownIcon"

import "./App.css"

const App = () => {
  const [userRoles, setUserRoles] = useState([])
  const { loading, user, logout, getTokenSilently } = useAuth0()
  const history = useHistory()
  const location = useLocation()

  const productsHeaderStyle = location.pathname.includes('products') ? { "color": "#334bff" } : {}

  useEffect(() => {
    if (loading) {
      return
    }
    if (user) {
      setUserRoles(user[`${process.env.REACT_APP_ROLE_NAMESPACE}/user_authorization`]["roles"])
    }

    const getTokenFn = async () => {
      const token = await getTokenSilently()
      storage.setToken(token)
    }
    getTokenFn()
  }, [loading, user])

  if (loading) {
    return <Loader />
  }

  return (
    <div className="container">
      <nav>
        <h2
          className="brand"
          onClick={(e) => {
            e.preventDefault()
            history.push("/")
          }}
        >
          Fabtrakr
        </h2>
        <ul>
          <Can
            roles={userRoles}
            action={"view:Dashboard"}
            yes={() => (
              <li>
                <NavLink activeClassName="selected" to="/dashboard">
                  Dashboard
                </NavLink>
              </li>
            )}
            no={() => null}
          />
          <Can
            roles={userRoles}
            action={"create:Barcodes"}
            yes={() => (
              <li>
                <NavLink activeClassName="selected" exact to="/">
                  Barcodes
                </NavLink>
              </li>
            )}
            no={() => null}
          />

          <li>
            <div className="products-dropdown">
              <div className="products-header" style={productsHeaderStyle}>Products<DropDownIcon w={4} paddingBottom={0.5} /></div>
              <div className="products-dropdown-content">
                <NavLink activeClassName="selected" to="/products/orders">
                  Orders
                </NavLink>
                <NavLink activeClassName="selected" to="/products/rfid">
                  Rfid
                </NavLink>
                <NavLink activeClassName="selected" to="/products/tech-pack">
                  Tech Pack
                </NavLink>
              </div>
            </div>
          </li>

          <li>
            <a href="#" onClick={() => logout({ returnTo: process.env.REACT_APP_HOME_URL })}>
              Logout
            </a>
          </li>
        </ul>
      </nav>
      <div className="App">
        <React.Fragment>
          <PrivateRoute
            exact path="/"
            component={GenerateBarcodes}
            perform={"create:Barcodes"}
          />
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            perform={"view:Dashboard"}
          />
          <PrivateRoute
            path="/products/orders"
            component={Order}
            perform={"update:Orders"}
          />
          <PrivateRoute
            path="/products/rfid"
            component={Rfid}
            perform={"update:Rfid"}
          />
          <PrivateRoute
            path="/products/tech-pack"
            component={TechPack}
            perform={"update:Tech-Pack"}
          />
        </React.Fragment>
      </div>
    </div >
  )
}

export default App
