import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import URLSearchParams from '@ungap/url-search-params'

import { Loader } from '../UI/Components'

import './SearchResults.css'
import SearchForEnum from './SearchForEnum'
import CuttingFilterEnum from './CuttingFilterEnum'
import WarehouseResults from './WarehouseResults'
import { getFabricData } from './api/getFabricData'
import { getCuttingData, getOcNumberConsolidatedData } from './api/getCuttingData'
import { getPalletInformation } from './api/getWarehouseData'
import { getEfficiencyData } from './api/getSewingData'
import { SewingDashboard } from './Components/SewingDashboard'
import CuttingConsolidatedResults from './Components/CuttingConsolidatedResults'
import { Button, ButtonGroup } from '@chakra-ui/button'

function SearchResults(props) {
  const { path } = useRouteMatch()
  const { search } = useLocation()
  const history = useHistory()

  const { searchFor } = props

  const [_, setError] = useState('')  //  eslint-disable-line no-unused-vars
  const [searchResults, setSearchResults] = useState([])
  // const [warehouseInitialData, setWarehouseInitialData] = useState([])
  const [showNoResultsError, setShowNoResultsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [cuttingFilter, setCuttingFilter] = useState(CuttingFilterEnum.ITEMS)
  const [overViewFilterColor, setOverViewFilterColor] = useState('')
  const [itemsFilterColor, setItemsFilterColor] = useState('brand.100')

  useEffect(() => {
    const makeRequest = async (fn, params) => {
      try {
        setIsLoading(true)
        const response = await fn(params)
        setIsLoading(false)
        if (response.length > 0) {
          setShowNoResultsError(false)
          setSearchResults(response)
        } else {
          setShowNoResultsError(true)
        }
      } catch (err) {
        setIsLoading(false)
        setError(() => {
          throw Error(err)
        })
      }
    }

    const params = new URLSearchParams(search)
    const paramsObject = {}
    for (const [key, value] of params.entries()) {
      paramsObject[key] = value
    }

    switch (searchFor) {
      case SearchForEnum.FABRIC:
        makeRequest(getFabricData, paramsObject)
        break
      case SearchForEnum.CUTTING:
        if (cuttingFilter === CuttingFilterEnum.OVERVIEW) {
          makeRequest(getOcNumberConsolidatedData, paramsObject)
          break
        }
        makeRequest(getCuttingData, paramsObject)
        break
      case SearchForEnum.WAREHOUSE:
        makeRequest(getPalletInformation, paramsObject)
        break
      case SearchForEnum.SEWING:
        makeRequest(getEfficiencyData, paramsObject)
        break
      default:
        throw new Error('This is not a recognized option')
    }

    return function cleanup() {
      setSearchResults([])
    }
  }, [search, searchFor, cuttingFilter])

  const handleSearchResultClickFabric = (index) => {
    const searchResult = searchResults[index]
    const { itemCode } = searchResult
    history.push({
      pathname: `${path}/${itemCode}`
    })
  }

  const handleSearchResultClickCutting = (index) => {
    const searchResult = searchResults[index]
    const { ocNum, itemCode, location, fitType } = searchResult
    history.push({
      pathname: `${path}/oc/${ocNum}/item/${itemCode}/location/${location}/fitType/${fitType}`
    })
  }

  const handleCuttingFilterSelect = (filterType) => {
    if (filterType === CuttingFilterEnum.OVERVIEW) {
      setOverViewFilterColor('brand.100')
      setItemsFilterColor('')
      setCuttingFilter(filterType)
    }
    if (filterType === CuttingFilterEnum.ITEMS) {
      setOverViewFilterColor('')
      setItemsFilterColor('brand.100')
      setCuttingFilter(filterType)
    }
  }

  if (isLoading) {
    return <Loader />
  }

  if (showNoResultsError === true) {
    return (
      <div className='search-results'>
        <h2 style={{ color: '#334bff' }}>No results found</h2>
      </div>
    )
  }

  let content = null

  switch (searchFor) {
    case SearchForEnum.FABRIC:
      content = searchResults.map((result, index) => {
        return (
          <div className='search-result-card'
            key={`${result.poNumber}+${result.ocNumber}+${result.itemCode}+${result.itemDesc}`}
            onClick={e => handleSearchResultClickFabric(index)}>
            <p>PO: {result.poNumber.length > 0 ? result.poNumber : 'No PO number'}</p>
            <p>OC: {result.ocNumber}</p>
            <p>Item Code: {result.itemCode}</p>
            <p>Item Desc: {result.itemDesc}</p>
          </div>
        )
      })
      break
    case SearchForEnum.CUTTING:
      content = [(
        <ButtonGroup key='cutting-filters-group' isAttached variant="outline">
          <Button width="3xs" color={overViewFilterColor} onClick={() => handleCuttingFilterSelect(CuttingFilterEnum.OVERVIEW)}>Overview</Button>
          <Button width="3xs" color={itemsFilterColor} onClick={() => handleCuttingFilterSelect(CuttingFilterEnum.ITEMS)}>Items</Button>
        </ButtonGroup >
      )]

      if (cuttingFilter === CuttingFilterEnum.OVERVIEW) {
        content.push(<CuttingConsolidatedResults key='consolidated-result' data={searchResults} />)
        break
      }

      searchResults.map((result, index) => {
        content.push(
          <div className='search-result-card'
            key={`${result.itemCode}+${result.location}+${result.fitType}`}
            onClick={e => handleSearchResultClickCutting(index)}>
            <p>Item Code: {result.itemCode}</p>
            <p>Location: {result.location}</p>
            <p>Item Description: {result.itemDescription}</p>
            <p>Fit Type: {result.fitType}</p>
          </div>
        )
      })
      break
    case SearchForEnum.WAREHOUSE:
      return <WarehouseResults data={searchResults} />
    case SearchForEnum.SEWING:
      return <SewingDashboard data={searchResults} />
    default:
      throw new Error('Oops! Something went wrong while showing the data')
  }

  return (
    <div className='search-results'>
      {content}
    </div>
  )
}

export default SearchResults