import { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import "./BarChart.css"
import { SingleDateSewingChartDTO } from '../types/sewing'

type BarChartProps = {
  height: number,
  width: number,
  xMargin: number,
  yMargin: number,
  topMargin: number,
  data: SingleDateSewingChartDTO[]
}

const BarChart = (props: BarChartProps) => {

  let { height, width, xMargin, yMargin, topMargin, data } = props
  const canvas = useRef(null)
  const [dataset, setDataSet] = useState<SingleDateSewingChartDTO[]>([])

  useEffect(() => {
    setDataSet(data)
  }, [data])

  const xScale = d3.scaleBand()
    .domain(dataset.map((x) => x.batchNumber))
    .range([xMargin, width])
    .padding(0.2)

  const yScale = d3.scaleLinear()
    .domain([0, 100])
    .range([height - yMargin, 0])


  const addAxes = (svg: d3.Selection<null, unknown, null, undefined>) => {

    const xAxis = d3.axisBottom(xScale)
      .tickSize(0)
      .tickPadding(10)

    svg.append("g")
      .call(xAxis)
      .attr("transform", `translate(0,${height - yMargin + 10})`)
      .attr("color", "#334BFF")
      .attr("font-weight", "bold")
      .style("font-size", "14px")

    const yAxis = d3.axisLeft(yScale)
      .tickSize(0)
      .tickPadding(10)
      .tickFormat(function (item) {
        if (item === 0) { return '0' }
        return `${item} %`
      })

    svg.append("g")
      .call(yAxis)
      .attr("transform", `translate(${xMargin},${topMargin})`)
      .attr("color", "#334BFF")
      .attr("font-weight", "bold")
      .style("font-size", "12px")

  }

  // sets the bar dataset
  const setBars = (svg: d3.Selection<null, unknown, null, undefined>) => {
    svg.selectAll("rect").remove()
    svg.selectAll("rect")
      .data(dataset)
      .enter()
      .append("rect")
      .attr("width", xScale.bandwidth())
      .attr("height", (item) => { return height - yMargin - yScale(item.efficiency) })
      .attr("x", (item) => { return xScale(item.batchNumber) || null })
      .attr("y", (item) => { return yScale(item.efficiency) + topMargin })
      .attr("fill", "white")
      .attr("stroke", "#334BFF")
  }

  const addHeaders = (svg: d3.Selection<null, unknown, null, undefined>) => {
    svg.append("text")
      .text("Sewing Efficiency")
      .attr("class", "header")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", yMargin / 4)
      .attr("fill", "#334BFF")
      .attr("font-weight", "bold")

    svg.append("text")
      .text("Efficiency")
      .attr("class", "header")
      .attr("x", -height * 0.35)
      .attr("y", 20)
      .attr("transform", `rotate(-90, ${xMargin / 2}, ${yMargin / 2})`)
      .attr("fill", "#334BFF")
      .attr("font-weight", "bold")

    svg.append("text")
      .text("Batch")
      .attr("class", "header")
      .attr("x", width / 2)
      .attr("y", height - 10)
      .attr("fill", "#334BFF")
      .attr("font-weight", "bold")

  }

  // adds the interactive tool tip - on mouse hover
  const setToolTip = (svg: d3.Selection<null, unknown, null, undefined>) => {
    svg
      .selectAll('rect')
      .data(dataset)
      .on('mouseover', function (actual, item) {
        d3.select(this)
          .attr('opacity', 0.5)
          .attr('fill', '#334BFF')
          .raise()

        svg.append('foreignObject')
          .attr('x', width * 0.8)
          .attr('y', yMargin / 3)
          .attr('width', 250)
          .attr('height', 110)
          .append("xhtml:body")
          .attr("class", "dahsboard-barchart-tooltip")
          .html(`<div><p>OC#:</p>${item.ocNumber}<br><p>Efficiency: ${item.efficiency} %</p>
          <p>Total output: ${item.totalOutputQuantity}<p></div>`)
      })
      .on('mouseleave', function (actual, i) {
        setBarChart(svg)
        svg.select('.dahsboard-barchart-tooltip').remove()
      })

  }

  const svg = d3.select(canvas.current)
  addAxes(svg)
  addHeaders(svg)

  const setBarChart = (svg: d3.Selection<null, unknown, null, undefined>) => {
    setBars(svg)
    setToolTip(svg)
  }

  setBarChart(svg)

  return (
    <div className="dashboard-barchart-canvas">
      <svg
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMinYMin"
        ref={canvas} />
    </div>
  )

}

export default BarChart