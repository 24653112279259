export type rfidProductDetailsDataType = {
  orderNumber: string,
  season: string,
  product: string,
  variant: string,
  gtin: string,
  articleNumber: string,
  sdsCode: string,
  description: string
}

export type ScannedCartonDetails = {
  shipmentId: string,
  cartonId: string,
  cartonNumber: number,
  cartonCode: string,
  cartonWeight: number,
  packType: string,
  epcs: string[],
  scannedDate: string,
  scannedTime: string
}

export type ScannedEPCDetails = {
  shipmentId: string,
  cartonId: string,
  cartonNumber: number,
  cartonCode: string,
  cartonWeight: number,
  packType: string,
  epc: string,
  scannedTime: string
}

export enum CartonPackTypeEnum {
  ASSORTED = 'assorted',
  MIXED = 'mixed',
  SOLID = 'solid'
}