import DatePicker from 'react-datepicker'

import "react-datepicker/dist/react-datepicker.css"
import './DatePicker.css'

const DatePickerComponent = (props: any) => {
  return (
    <DatePicker wrapperClassName='date-picker-wrapper-class' {...props} />
  )
}

export default DatePickerComponent