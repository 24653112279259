import React from 'react'

import { As, Button, ButtonProps, OmitCommonProps } from '@chakra-ui/react'

const SubmitButtonUpdated = (props: JSX.IntrinsicAttributes & OmitCommonProps<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, keyof ButtonProps> & ButtonProps & OmitCommonProps<any, keyof ButtonProps> & { as?: As<any> | undefined }) => {
  return (
    <Button bg="brand.100" color="#ffffff" {...props}>
      {props.children}
    </Button>
  )
}

export default SubmitButtonUpdated