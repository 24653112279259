import { useEffect, useRef, useState } from "react"
import ExcelFileUpload from "Rfid/Components/ExcelFileUpload"
import { rfidProductDetailsDataType } from "Rfid/types"
import { SubmitButtonUpdated } from "UI/Components"
import { Loader } from "UI/Components"
import { postRFIDProductDetails } from "Rfid/api/postRFIDProductDetails"
import { ErrorLabel } from '../../UI/Components'
import SuccessLabelComponent from '../../UI/Components/SuccessLabel'

const UploadRfidData = () => {

  const ref = useRef(false)

  const [extractedData, setExtractedData] = useState<rfidProductDetailsDataType[]>([])
  const [successMessage, setSuccessMessage] = useState('')
  const [failureMessage, setFailureMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [_, setError] = useState('')


  const handleSubmit = async () => {
    if (extractedData.length === 0) {
      window.alert("Please upload a valid file")
      return
    }

    setIsLoading(true)
    const userConfirm = window.confirm("Are you sure you want to upload these RFID product details?")
    if (userConfirm === false) {
      setIsLoading(false)
      return
    }

    try {
      await postRFIDProductDetails(extractedData)
      setIsLoading(false)
      ref.current = false
      setSuccessMessage('')
      setExtractedData([])
      window.alert('Your details were submitted successfully')
    } catch (err: any) {
      setError(() => {
        throw Error(err)
      })
    }
  }

  const setDisplayMessage = () => {
    if (extractedData.length === 0) {
      setFailureMessage('Something went wrong while reading the file')
      setSuccessMessage('')
      return
    }
    setSuccessMessage('The file is ready for upload')
    setFailureMessage('')
  }

  useEffect(() => {
    if (ref.current === true) {
      return setDisplayMessage()
    }
    ref.current = true
  }, [extractedData])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div style={{ marginTop: '5%' }}>
      <ExcelFileUpload onChange={(extractedData: rfidProductDetailsDataType[]) => setExtractedData(extractedData)} />
      <div style={{ textAlign: 'center', fontSize: '1.3vw' }}>
        <SuccessLabelComponent text={successMessage} />
        <ErrorLabel text={failureMessage} />
      </div>
      <SubmitButtonUpdated style={{ width: '75%', margin: '5% 5% 5% 12%' }} onClick={handleSubmit}>
        <p>Upload Data</p>
      </SubmitButtonUpdated>
    </div>

  )
}

export default UploadRfidData