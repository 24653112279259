import React, { useMemo } from 'react'
import { useSortBy, useTable } from 'react-table'

import TableStyles from '../UI/Components/TableStyles'

function WarehouseResults(props) {
  const data = props.data

  const memoizedData = useMemo(() => data, [data])

  /**
   * Utility function to convert a number to the locale string version
   * @param {Number} number 
   */
  const convertToLocaleString = (number) => {
    return number.toLocaleString('en-IN')
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Rack Number',
        accessor: 'rackBarcode'
      },
      {
        Header: 'Article Number',
        accessor: 'articleNumber'
      },
      {
        Header: 'Number Of Pieces',
        accessor: 'numberOfPieces',
        Cell: (props) => {  //  providing commas for this number
          return convertToLocaleString(props?.cell?.value)
        }
      },
      {
        Header: 'Date Of Manufacturing',
        accessor: 'dateOfManufacture'
      }
    ]
  }, [])

  const tableInstance = useTable({ columns, data: memoizedData }, useSortBy)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    toggleSortBy
  } = tableInstance

  return (
    <>
      <TableStyles>
        <table {...getTableProps()}>
          <thead>
            {
              headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        {
                          column.render('Header')
                        }
                        <span style={{ display: 'inline-block', marginLeft: '1vw', cursor: 'pointer' }}
                          // @ts-ignore
                          aria-expanded={column.isSortedDesc ? true : false}
                          onClick={() => {
                            // @ts-ignore
                            if (column.isSortedDesc === true) {
                              toggleSortBy(column.id, false)
                              return
                            }
                            toggleSortBy(column.id, true)
                          }}>
                          <img src="/down-chevron.svg" alt='down-arrow' style={{
                            width: '1.5vw', height: '1.5vw',
                            verticalAlign: 'middle'
                          }} />
                        </span>
                      </th>

                    ))}
                </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {
                      row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              cell.render('Cell')
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </TableStyles>
    </>
  )
}

export default WarehouseResults