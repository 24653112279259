import { Box } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Loader } from "UI/Components"
import SidePanel from "UI/Components/SidePanel"
import { getLocations } from "../api/getLocations"
import { DHUDashboardBase } from "./DHUDashboardBase"
import { SewingDashboard } from "./SewingDashboard"
import "./SewingDashboardBase.css"

export const SewingDashboardBase = () => {
  const [locations, setLocations] = useState<string[]>([])
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [_, setError] = useState('')  //  eslint-disable-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    const fetchLocations = async () => {
      setIsLoading(true)
      try {
        const response = await getLocations()
        setIsLoading(false)
        if (response.length > 0) {
          setLocations(response)
          // Select the first location by default
          setSelectedLocation(response[0])
        }
      } catch (err: any) {
        setIsLoading(false)
        setError(() => {
          throw Error(err)
        })
      }
    }
    fetchLocations()
  }, [])

  if (isLoading) {
    return < Loader />
  }


  return (
    <Box width={'100%'}>
      <div className="locations-side-panel">
        <SidePanel
          itemList={locations}
          selected={selectedLocation}
          onSelect={setSelectedLocation}
        />
      </div>
      <Box marginLeft={'10%'}>
        <div className="efficiency-container">
          <SewingDashboard location={selectedLocation} />
        </div>
        <div className="dhu-dashboard-container-base">
          <DHUDashboardBase location={selectedLocation} />
        </div>
      </Box>
    </Box>
  )
}