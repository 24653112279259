import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import OrderForm from 'Orders/Components/OrderForm'
import OrderSizeAndQuantitiesGrid from 'Orders/Components/OrderSizeAndQuantitiesGrid'

const OrderRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <OrderForm />
        </Route>
        <Route path={`${path}/size-quantities-entry`}>
          <OrderSizeAndQuantitiesGrid />
        </Route>
      </Switch>
    </>
  )
}

export default OrderRoutes