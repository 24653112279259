import { Button, ButtonGroup } from "@chakra-ui/react"
import { getDHUData } from "Dashboard/api/getSewingData"
import { DHUDataDTO, SewingDashboardFilter } from "Dashboard/types/sewing"
import { useEffect, useState } from "react"
import { DatePicker } from 'UI/Components'
// import DatePicker from "react-datepicker"
import { Loader } from "UI/Components"
import "./DHUDashboardBase.css"
import { DHUDateRangeChart } from "./DHUDateRangeChart"
import { DHUDateRangeTable } from "./DHUDateRangeTable"
import { DHUSingleDateChart } from "./DHUSingleDateChart"
import { DHUSingleDateTable } from "./DHUSingleDateTable"

type DHUDashboardProps = {
  location: string
}

export const DHUDashboardBase = (props: DHUDashboardProps) => {
  const { location } = props

  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [data, setData] = useState<DHUDataDTO[]>([])
  const [err, setError] = useState<Error>(new Error())
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [dashboardFilter, setDashboardFilter] = useState<SewingDashboardFilter>(SewingDashboardFilter.CHART)
  const [chartFilterColor, setChartFilterColor] = useState<string>('brand.100')
  const [tableFilterColor, setTableFilterColor] = useState<string>('')

  // This method returns the date in the string format YYYYMMDD
  const convertDateToString = (date: Date) => {
    return date.toLocaleDateString('en-GB').split('/').reverse().join('')
  }

  useEffect(() => {
    const fetchDHUData = async () => {
      setIsLoading(true)
      try {
        const startDateInString = convertDateToString(startDate)
        let endDateInString = 'null'
        if (endDate) {
          endDateInString = convertDateToString(endDate)
        }
        const paramsObject = { startDate: startDateInString, endDate: endDateInString, location, batchNumber: 'null' }
        const response = await getDHUData(paramsObject)
        setIsLoading(false)
        setData(response)
      } catch (err: any) {
        setData([])
        setIsLoading(false)
        setError(err)
      }
    }
    if (location) {
      fetchDHUData()
    }
  }, [location, startDate, endDate])

  const handleFilterChange = (filterType: SewingDashboardFilter) => {
    if (filterType === SewingDashboardFilter.CHART) {
      setChartFilterColor('brand.100')
      setTableFilterColor('')
      setDashboardFilter(filterType)
    }
    if (filterType === SewingDashboardFilter.TABLE) {
      setChartFilterColor('')
      setTableFilterColor('brand.100')
      setDashboardFilter(filterType)
    }
  }

  const handleDateRange = (dateRange: [Date, Date]) => {
    const [start, end] = dateRange
    setStartDate(start)
    setEndDate(end)
    if (start?.getTime() === end?.getTime()) {
      setEndDate(null)
    }
  }

  const handleSingleDate = (date: Date) => {
    if (date.getTime() < startDate.getTime()) {
      setStartDate(date)
      setEndDate(null)
      return
    }
    setEndDate(date)
  }

  let dynamicComponent = null
  switch (dashboardFilter) {
    case SewingDashboardFilter.TABLE:
      if (endDate) {
        dynamicComponent = (<DHUDateRangeTable data={data} />)
        break
      }
      dynamicComponent = (<DHUSingleDateTable data={data} />)
      break
    case SewingDashboardFilter.CHART:
      if (endDate) {
        dynamicComponent = (<DHUDateRangeChart data={data} />)
        break
      }
      dynamicComponent = (<DHUSingleDateChart data={data} />)
      break
    default:
      throw new Error('Oops! Invalid dashboard filter')
  }

  if (isLoading) {
    dynamicComponent = (<Loader />)
  }

  return (
    <div className="dhu-dashboard-container">
      <div className="filter-buttons">
        <ButtonGroup key='dhu-filters-group' isAttached variant="outline">
          <Button color={chartFilterColor} onClick={() => handleFilterChange(SewingDashboardFilter.CHART)}>Chart</Button>
          <Button color={tableFilterColor} onClick={() => handleFilterChange(SewingDashboardFilter.TABLE)}>Table</Button>
        </ButtonGroup >
      </div>
      <div className="dhu-dashboard-date-picker">
        {
          endDate ? (
            <DatePicker
              maxDate={new Date()}
              selectsRange
              selectsStart
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRange}
              placeholderText="Select Date"
              dateFormat='dd MMM yyyy' />
          ) : (
            <DatePicker
              maxDate={new Date()}
              selected={startDate}
              selectsStart
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={handleSingleDate}
              placeholderText="Select Date"
              dateFormat='dd MMM yyyy' />
          )
        }
      </div>
      <div className="dhu-dynamic-dashboard-component">
        {dynamicComponent}
      </div>
    </div>
  )
}