import React, { FormEvent, useState } from "react"
import { useHistory, useRouteMatch } from 'react-router-dom'
import { OCInput } from 'Orders/Components/OCInput'
import { SubmitButton } from "../../UI/Components"

import "./OrderForm.css"
import { SelectLocation } from "./SelectLocation"
import { SelectItem } from "./SelectItem"
import { SelectFitType } from "./SelectFitType"
import { ConsumptionInput } from "./ConsumptionInput"
import { itemsLocationsAndFitTypes } from "Orders/types"

const OrderForm = () => {
  const history = useHistory()
  const { path } = useRouteMatch()

  const [OC, setOC] = useState("")
  const [location, setLocation] = useState("")
  const [item, setItem] = useState("")
  const [fitType, setFitType] = useState("")
  const [consumption, setConsumption] = useState(0.0)

  const [isConsumptionInvalid, setIsConsumptionInvalid] = useState(false)

  const [locations, setLocations] = useState<string[]>([])
  const [items, setItems] = useState<string[]>([])
  const [fitTypes, setFitTypes] = useState<string[]>([])

  /**
   * Function to Submit user input order details to server
   */
  const handleOrderDetailsSelectionFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const userConfirm = window.confirm(
      "Are you sure you want to submit these order details?"
    )
    if (userConfirm === false) {
      return
    }

    if (consumption === 0) {
      setIsConsumptionInvalid(true)
      return
    }

    let [itemCode, itemDescription] = item.split("|")
    itemCode = itemCode.trim()
    itemDescription = itemDescription.trim()

    const params = new URLSearchParams()
    params.append('OC', OC)
    params.append('location', location)
    params.append('fitType', fitType)
    params.append('item', itemCode)
    params.append('itemDescription', itemDescription)
    params.append('consumption', String(consumption))
    history.push({
      pathname: `${path}/size-quantities-entry`,
      search: params.toString()
    })
    return
  }

  const setValueForItemsLocationsAndFitTypes = (itemsLocationsAndFitTypes: itemsLocationsAndFitTypes) => {
    const { locations, items, fitTypes } = itemsLocationsAndFitTypes
    setLocations(locations)
    setItems(items)
    setFitTypes(fitTypes)
  }

  return (
    <form id="order-details-form"
      onSubmit={e => handleOrderDetailsSelectionFormSubmit(e)}>
      <OCInput
        OC={OC}
        onChange={(OC) => setOC(OC)}
        setValueForItemsLocationsAndFitTypes={setValueForItemsLocationsAndFitTypes} />
      <SelectLocation locations={locations}
        defaultOption={'Please select a location'}
        location={location}
        onChange={(location) => setLocation(location)} />
      <SelectItem items={items}
        defaultOption={'Please select an item'}
        item={item}
        onChange={(item) => setItem(item)} />
      <SelectFitType fitTypes={fitTypes}
        defaultOption={'Please select a fit type'}
        fitType={fitType}
        onChange={(fitType) => setFitType(fitType)} />
      <ConsumptionInput 
        isInvalid={isConsumptionInvalid}
        onChange={(consumption) => setConsumption(consumption)} />
      <SubmitButton>Continue</SubmitButton>
    </form>
  )
}

export default OrderForm
