import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import RfidHome from 'Rfid/Components/RfidHome'
import UploadRfidData from 'Rfid/Components/UploadRfidData'
import DownloadRfidData from 'Rfid/Components/DownloadRfidData'

const RfidRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <RfidHome />
        </Route>
        <Route path={`${path}/data-upload`}>
          <UploadRfidData />
        </Route>
        <Route path={`${path}/data-download`}>
          <DownloadRfidData />
        </Route>
      </Switch>
    </>
  )
}

export default RfidRoutes