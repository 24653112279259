import { useEffect, useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td, Center, Box } from "@chakra-ui/react"
import { DefectData, DHUDataDTO, HourlyDefectData } from "Dashboard/types/sewing"

type DHUSingleDateTableProps = {
  data: DHUDataDTO[]
}

type DataTypePerBatch = {
  totalQuantity: number,
  [defect: string]: number
}

type GroupedDataType = {
  [batchNumber: string]: DataTypePerBatch
}

type GroupedDataTypeWithTotalDefects = {
  [batchNumber: string]: DataTypePerBatch & {
    totalDefects: number
  }
}

type GroupedDataTypeWithTotalDefectsAndDHU = {
  [batchNumber: string]: DataTypePerBatch & {
    totalDefects: number,
    DHU: number
  }
}

export const DHUSingleDateTable = (props: DHUSingleDateTableProps) => {
  const { data } = props
  const [defects, setDefects] = useState<string[]>([])
  const [groupedData, setGroupedData] = useState<GroupedDataTypeWithTotalDefectsAndDHU>({})

  useEffect(() => {
    const groupedData = groupData(data)
    const groupedDataWithTotalDefects = calculateTotalDefectsForEachBatch(groupedData)
    const groupedDataWithTotalDefectsAndDHU = calculateDHUForEachBatch(groupedDataWithTotalDefects)
    
    setGroupedData(groupedDataWithTotalDefectsAndDHU)
    const uniqueListOfDefects = createUniqueListOfDefects(data)
    setDefects(uniqueListOfDefects)
  }, [data])

  const groupData = (data: DHUDataDTO[]) => {
    
    const groupedObject: GroupedDataType = {}

    data.forEach((each: DHUDataDTO) => {
      const { batchNumber, totalPassedQuantity, totalRejectedQuantity, totalReworkedQuantity, hourlyDetails } = each
      const totalQuantity = totalPassedQuantity + totalRejectedQuantity + totalReworkedQuantity
      groupedObject[batchNumber] = groupedObject[batchNumber] || { totalQuantity: 0 }
      groupedObject[batchNumber].totalQuantity += totalQuantity
      hourlyDetails.forEach((item: HourlyDefectData) => {
        const { defectDetails } = item
        defectDetails.forEach((defectObject: DefectData) => {
          const { defect, quantity } = defectObject
          groupedObject[batchNumber][defect] = groupedObject[batchNumber][defect] || 0
          groupedObject[batchNumber][defect] += quantity
        })
      })
    })
    return groupedObject
  }

  const calculateTotalDefectsForEachBatch = (data: GroupedDataType): GroupedDataTypeWithTotalDefects => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const { totalQuantity, ...defectsObject } = value
      const totalNumberOfDefects = Object.entries(defectsObject).reduce((acc, [defect, value]) => {
        return acc + value
      }, 0)
      return Object.assign(
        {},
        { ...acc },
        {
          [key]: {
            ...value,
            totalDefects: totalNumberOfDefects
          }
        }
      )
    }, {})
  }

  const calculateDHUForEachBatch = (data: GroupedDataTypeWithTotalDefects): GroupedDataTypeWithTotalDefectsAndDHU => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const { totalQuantity, totalDefects } = value
      const DHU = totalDefects * 100 / totalQuantity
      return Object.assign(
        {},
        { ...acc },
        {
          [key]: {
            ...value,
            DHU
          }
        }
      )
    }, {})
  }

  const createUniqueListOfDefects = (data: DHUDataDTO[]) => {
    const uniqueListOfDefects: string[] = []
    data.forEach((each: DHUDataDTO) => {
      const { hourlyDetails } = each
      hourlyDetails.forEach((hourDetail: HourlyDefectData) => {
        const { defectDetails } = hourDetail
        defectDetails.forEach((defectObject: DefectData) => {
          const { defect, quantity } = defectObject
          if (uniqueListOfDefects.indexOf(defect) < 0) {
            uniqueListOfDefects.push(defect)
          }
        })
      })
    })
    return uniqueListOfDefects
  }

  if (data.length === 0) {
    return (
      <div className="dhu-dashboard-error">
        No results found
      </div>
    )
  }

  return (
    <div>
      <div className="dhu-dashboard-header">Batch DHU Table</div>
      <div className="dhu-dashboard-content">
        <Box overflowX='auto' maxWidth='80vw' margin={'2% 0 0 3%'} css={{
          '&::-webkit-scrollbar': {
            display: 'block',
            height: '0.4em'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'grey',
            borderRadius: '24px'
          },
        }}>
          <Table>
            <Thead style={{ position: "sticky", top: "0" }}>
              <Tr>
                <Th color={'#334bff'}><Center>Batch</Center></Th>
                {
                  // Setting the header with the unique defects
                  defects.map(each => { return <Th color={'#334bff'} key={each}><Center>{each}</Center></Th> })
                }
                <Th color={'#334bff'}><Center>Total Defects</Center></Th>
                <Th color={'#334bff'}><Center>DHU</Center></Th>
                <Th color={'brand.100'}><Center>Total Inspected</Center></Th>
              </Tr>
            </Thead>
            <Tbody color="brand.100">
              {
                // Row entry per batch
                Object.keys(groupedData).map((batchNumber) => {
                  const { totalQuantity, totalDefects, DHU, ...defectsObject } = groupedData[batchNumber]
                  const correspondingDefectQuantities = defects.map(defect => {
                    const quantity = defectsObject[defect] || 0
                    return quantity
                  })
                  return (
                    <Tr key={batchNumber}>
                      <Td><Center>{batchNumber}</Center></Td>
                      {
                        // Batch wise cut quantity per defect
                        correspondingDefectQuantities.map((qty, index) => {
                          return <Td key={`${index}-${batchNumber}-${qty}`}><Center>{qty}</Center></Td>
                        })
                      }
                      <Td><Center>{totalDefects}</Center></Td>
                      <Td><Center>{DHU.toFixed(2)}%</Center></Td>
                      <Td><Center>{totalQuantity}</Center></Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Box>

      </div>
    </div>
  )
}