import React from 'react'
import { Select } from 'UI/Components'

type SelectLocationProps = {
  locations: string[],
  location: string,
  defaultOption: string,
  onChange: (location: string) => void
}

export const SelectLocation = (props: SelectLocationProps) => {
  const { locations, location, defaultOption, onChange } = props

  return (
    <Select
      value={location}
      onChange={(event: Event) => {
        onChange((event.target as HTMLInputElement).value)
      }}
      valuesToMap={locations}
      defaultOption={defaultOption}
      mapperFn={(location: string) => {
        return (
          <option key={location} value={location}>{`${location}`}</option>
        )
      }} />
  )
}