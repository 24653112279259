import React from 'react'
import { Select } from 'UI/Components'

type SelectFitTypeProps = {
  fitType: String,
  fitTypes: String[],
  defaultOption: string,
  onChange: (fitType: string) => void
}

export const SelectFitType = (props: SelectFitTypeProps) => {
  const { fitType, fitTypes, defaultOption, onChange } = props

  return (
    <Select
      value={fitType}
      defaultOption={defaultOption}
      onChange={(event: Event) => {
        onChange((event.target as HTMLInputElement).value)
      }}
      valuesToMap={fitTypes}
      mapperFn={(fitType: string) => {
        return (
          <option key={fitType} value={fitType}>{`${fitType}`}</option>
        )
      }} />
  )
}