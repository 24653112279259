import { IconProps, TriangleDownIcon } from "@chakra-ui/icons"
import { OmitCommonProps, As } from "@chakra-ui/system"
import { SVGProps } from "react"

const DropDownIcon = (props: JSX.IntrinsicAttributes & OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> & IconProps & OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined; }) => {
  return (
    <TriangleDownIcon {...props} />
  )
}

export default DropDownIcon