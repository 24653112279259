import axios, { AxiosRequestConfig } from 'axios'

import storage from "../Storage/localStorage"

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  validateStatus: function (status) {
    return status >= 200 && status < 400
  }
})

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken()
  config.headers.Authorization = `Bearer ${token}`
  config.headers.Accept = 'application/json'
  config.headers.Version = '7.0'
  return config
}

apiClient.interceptors.request.use(authRequestInterceptor)
apiClient.interceptors.response.use(function (response) {
  return response.data
}, function (error) {
  const message = error.response?.data?.message || error.message
  throw new Error(message)
})