import React from 'react'
import { nanoid } from 'nanoid'

import BarcodeForm from './BarcodeForm'
import { checkPOQuantity, getItemNumbers, isPONumberValid } from './api/apiFunctions'

/**
 * Container class for the Barcode Form. It is used to allow the user to
 * enter the PO number and item code in the UI
 */
class BarcodeFormContainer extends React.Component {
  constructor() {
    super()
    this.state = {
      PO: '',
      PONumberValid: false,
      POFieldTouched: false,
      itemNosAndDescription: [],
      currentlySelectedItem: '',
    }
  }

  render() {
    const { PO, PONumberValid, POFieldTouched, itemNosAndDescription, currentlySelectedItem } = this.state
    return (
      <BarcodeForm handlePONumChange={this.handlePONumChange}
        handleItemChange={this.handleItemChange}
        handleColorCodeChange={this.handleColorCodeChange}
        handleFormSubmit={this.handleFormSubmit}
        checkIfPONumberValid={this.checkIfPONumberValid}
        PO={PO}
        PONumberValid={PONumberValid}
        POFieldTouched={POFieldTouched}
        itemNosAndDescription={itemNosAndDescription}
        currentlySelectedItem={currentlySelectedItem}
      />
    )
  }

  handlePONumChange = (e) => {
    const v = e.target.value
    this.setState({
      PO: v
    })
  }

  handleItemChange = (e) => {
    const v = e.target.value
    this.setState({
      currentlySelectedItem: v
    })
  }

  /**
   * Form submit handler for the PO & item code form. Will check if a valid PO number has been entered and if the
   * currentlySelectedItem has been picked.
   * Will also make a request to /checkPOQuantity to check if the current PO item has crossed it's limit
   */
  handleFormSubmit = async (event) => {
    event.preventDefault()
    const { PO, PONumberValid, currentlySelectedItem } = this.state

    if (PONumberValid === false || currentlySelectedItem === "") {
      return
    }
    const itemNumber = currentlySelectedItem.substr(0, currentlySelectedItem.indexOf(" "))
    try {
      const { currQty, ordQty } = await checkPOQuantity(PO, itemNumber)
      this.props.barcodeFormValidatedShowTable(PO, currentlySelectedItem, currQty, ordQty)
    } catch (err) {
      this.setState(() => {
        throw Error(err)
      })
    }
  }

  clearSelectOptions = () => {
    this.setState({
      itemNosAndDescription: []
    })
  }

  /**
   * Make a fetch request to /validatePONumber to check if the PO number the user entered is valid
   */
  checkIfPONumberValid = async (e) => {
    if (e.target.value === '') {
      return
    }

    const PO = e.target.value
    try {
      const response = await isPONumberValid(PO)
      this.setState({
        PONumberValid: response,
        POFieldTouched: true
      }, () => {
        if (response === true) {
          this.fetchItemNos()
        } else {
          this.clearSelectOptions()
        }
      })
    } catch (err) {
      this.setState(() => {
        throw Error(err)
      })
    }
  }

  /**
   * Make a fetch request to /fetchItemNos to get the item codes for a specific PO once the PO number has been validated by server
   */
  fetchItemNos = async () => {
    const { PO } = this.state
    try {
      const response = await getItemNumbers(PO)
      const itemsWithId = response.map(item => {
        return Object.assign({}, { ...item }, { id: nanoid(4) })
      })
      this.setState({
        itemNosAndDescription: itemsWithId
      })
    } catch (err) {
      throw Error(err)
    }
  }
}

export default BarcodeFormContainer