import React from 'react'
import styled from 'styled-components'

import Button from './Button'

const DashboardButton = styled(Button)`
  color: #334bff;
  background-color: #ffffff;
  border: 3px solid gainsboro;
  height: 10vw;
  width: 20%;
  font-size: 1.4em;
`

const DashboardButtonComponent = (props) => {
  return (
    <DashboardButton {...props}>
      {props.children}
    </DashboardButton>
  )
}

export default DashboardButtonComponent