import React from 'react'
import styled from '@emotion/styled'

const SuccessLabel = styled.label`
    color: green;
    align-self: center;
`

const SuccessLabelComponent = (props) => {
  return <SuccessLabel {...props}>{props.text}</SuccessLabel>
}

export default SuccessLabelComponent