import React, { ChangeEvent, FocusEvent, useState } from 'react'

import { ErrorIcon, Input, TickIcon } from 'UI/Components'
import { validateOCNumberAndFetchItemNumbersAndLocations } from 'Orders/api/apiFunctions'
import { itemsLocationsAndFitTypes } from 'Orders/types'

type OCInputProps = {
  OC: string,
  onChange: (OC: string) => void
  setValueForItemsLocationsAndFitTypes: (itemsLocationsAndFitTypes: itemsLocationsAndFitTypes) => void
}

export const OCInput = (props: OCInputProps) => {
  const { setValueForItemsLocationsAndFitTypes, OC, onChange } = props

  const [OCFieldTouched, setOCFieldTouched] = useState(false)
  const [OCNumberValid, setOCNumberValid] = useState(false)

  const isOCNumberValid = async (enteredOCNumber: string) => {
    if (enteredOCNumber === '') {
      return
    }
    try {
      const itemsLocationsAndFitTypes: itemsLocationsAndFitTypes = await validateOCNumberAndFetchItemNumbersAndLocations(enteredOCNumber)
      setOCFieldTouched(true)
      setOCNumberValid(true)
      setValueForItemsLocationsAndFitTypes(itemsLocationsAndFitTypes)
    } catch (err) {
      setOCFieldTouched(true)
      setOCNumberValid(false)
    }
  }

  return (
    <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
      <Input
        autoFocus
        isRequired
        type="text"
        value={OC}
        isInvalid={OCFieldTouched && !OCNumberValid}
        placeholder="Enter the OC number"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const receivedValue = event.target.value
          onChange(receivedValue)
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => isOCNumberValid((e.target as HTMLInputElement).value)}
      />
      {
        (OCNumberValid) ? (<span className='valid' style={{ marginLeft: '1em' }}><TickIcon /></span>) :
          (
            (OCFieldTouched) ? (<span className='invalid' style={{ marginLeft: '1em' }}><ErrorIcon /></span>) : (null)
          )
      }
    </div>
  )
}