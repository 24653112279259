import { apiClient } from "Services/Networking/apiClient"
import { rfidProductDetailsDataType } from "Rfid/types"

export const postRFIDProductDetails = async (productDetails: rfidProductDetailsDataType[]): Promise<Boolean> => {
  const response = await apiClient({
    method: 'POST',
    url: '/fabship/product/rfid/upload-details',
    data: {
      productDetails
    }
  })
  return response as unknown as Boolean
}